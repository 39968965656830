import Service from '../../../services/Service';
import http from '../../../utils/http-common';

class SmsService extends Service {

    basePath = 'sms';

    newObject() {
        return new SmsService();
    }

    async readSms(id) {
        if (this.isNewRecord) {
            await http.post('/sms/read-sms', { id: id }).then(response => {
                this.applyResponse(response)
            }).catch(e => this.catchResponse(e));
        }
    }

    async readAllSms(userId) {
        return await http.get('/sms/read-all-sms/' + userId, { params: this.prepareRequest() }).then(response => {
            return response.data;
        }).catch(e => this.catchResponse(e));
    }

    async sendSms() {
        var request = this.prepareRequest();

        if (this.isNewRecord) {
            await http.post('/sms/', request).then(response => {
                this.applyResponse(response)
            }).catch(e => this.catchResponse(e));
        }
    }

    async getAllSmsByUserId(userId) {
        return await http.get('/sms/get-all-sms-by-user-id/' + userId, { params: this.prepareRequest() }).then(response => {
            return response.data;
        }).catch(e => this.catchResponse(e));
    }

    async getUnreadSmsByUserId(userId) {
        return await http.get('/sms/get-unread-sms-by-user-id/' + userId, { params: this.prepareRequest() }).then(response => {
            return response.data;
        }).catch(e => this.catchResponse(e));
    }

    async getLastThreeUnreadSmsByUserId(userId) {
        return await http.get('/sms/get-last-three-unread-sms-by-user-id/' + userId, { params: this.prepareRequest() }).then(response => {
            return response.data;
        }).catch(e => this.catchResponse(e));
    }

}

export default SmsService;