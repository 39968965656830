<template>
  <li class="nav-item mx-0 mx-xl-2 topbar-dropdown">
    <div class="nav-item">
      <div
        v-tooltip.bottom="tSale"
        class="nav-gold cursor-pointer d-flex flex-lg-column justify-content-center align-items-center"
      >
        <span class="fs-7 me-1 me-md-0"> Продажа </span>
        <div v-if="onSelling == false" class="fs-4 fw-bold text-success" @click="canStore ? onClickSelling() : false">
          {{ dashboardRateGoldService.selling ?? 0 }}
        </div>
        <FormField
          v-if="onSelling"
          class="mt-lg-2 mt-xl-0 mt-2"
          :service="dashboardRateGoldService"
          field="selling"
          type="number"
          @change="onBlurSelling"
        />
      </div>
    </div>
  </li>
  <li class="nav-item mx-0 mx-xl-2 topbar-dropdown">
    <div class="nav-item">
      <div
        v-tooltip.bottom="tReception"
        class="nav-gold cursor-pointer d-flex flex-lg-column justify-content-center align-items-center"
      >
        <span class="fs-7 me-1 me-md-0"> Прием </span>
        <div
          v-if="onAcceptance == false"
          class="fs-4 fw-bold text-success"
          @click="canStore ? onClickAcceptance() : false"
        >
          {{ dashboardRateGoldService.acceptance ?? 0 }}
        </div>
        <FormField
          v-if="onAcceptance"
          class="mt-lg-2 mt-xl-0 mt-2"
          :service="dashboardRateGoldService"
          field="acceptance"
          @change="onBlurAcceptance"
          type="number"
        />
      </div>
    </div>
  </li>
  <li class="nav-item mx-0 mx-xl-2 topbar-dropdown">
    <div class="nav-item">
      <div
        v-tooltip.bottom="tPrice"
        class="nav-gold cursor-pointer d-flex flex-lg-column justify-content-center align-items-center"
      >
        <span class="fs-7 me-1 me-md-0"> Витрина </span>
        <div
          v-if="onPriceInMarket == false"
          class="fs-4 fw-bold text-success"
          @click="canStore ? onClickPriceInMarket() : false"
        >
          {{ dashboardRateGoldService.priceInMarket ?? 0 }}
        </div>
        <FormField
          v-if="onPriceInMarket"
          class="mt-lg-2 mt-xl-0 mt-2"
          :service="dashboardRateGoldService"
          field="priceInMarket"
          @change="onBlurPriceInMarket"
          type="number"
        />
      </div>
    </div>
  </li>
</template>

<script>
import FormField from '../../../components/ui/form/FormField.vue';
import DashboardRateGoldService from '../services/DashboardRateGoldService';

export default {
  components: { FormField },
  data: function () {
    return {
      dashboardRateGoldService: new DashboardRateGoldService(),
      onSelling: false,
      onAcceptance: false,
      onPriceInMarket: false,
      canStore: this.$store.getters.can('dashboard/rate-gold.store'),
      tSale: 'Продажа золота на завод (лом)',
      tReception: 'Прием золота от клиентов (лом)',
      tPrice: 'Цена изделия на витрине за грамм',
    };
  },
  async created() {
    await this.dashboardRateGoldService.showItem();
  },
  methods: {
    onClickSelling() {
      this.onSelling = true;
    },
    onClickAcceptance() {
      this.onAcceptance = true;
    },
    onClickPriceInMarket() {
      this.onPriceInMarket = true;
    },
    async onBlurAcceptance() {
      this.dashboardRateGoldService.isNewRecord = true;
      await this.dashboardRateGoldService.save();
      this.onAcceptance = false;
    },
    async onBlurSelling() {
      this.dashboardRateGoldService.isNewRecord = true;
      await this.dashboardRateGoldService.save();
      this.onSelling = false;
    },
    async onBlurPriceInMarket() {
      this.dashboardRateGoldService.isNewRecord = true;
      await this.dashboardRateGoldService.save();
      this.onPriceInMarket = false;
    },
  },
};
</script>