import Service from '../../../services/Service';
import axios from 'axios';
import http from '../../../utils/http-common';
import { t } from '../../../lang';
import store from '../../../store';

class KkmCommandService extends Service {

    basePath = 'kkm/command';

    newObject() {
        return new KkmCommandService();
    }

    /** @inheritdoc */
    setAttributes(attributes) {
        super.setAttributes(attributes);

        if (this.request && typeof this.request === 'string') {
            this.request = JSON.parse(this.request);
        }

        if (this.response && typeof this.response === 'string') {
            this.response = JSON.parse(this.response);
        }
    }

    /**
     * Operations by kkt session id.
     * @returns {array}
     */
    async operations() {
        return await http.get('/' + this.basePath + '/operations', { params: this.prepareRequest() }).then(response => {
            return response.data.data;
        }).catch(e => this.catchResponse(e));
    }

    /**
     * 
     * @param {int} kktNumber 
     */
    async getDataKktFromList(kktNumber) {
        if (!kktNumber) {
            return;
        }

        await this.getRequestAndExecute('List');
        if (this.response && this.response.ListUnit) {
            for (const unit of this.response.ListUnit) {
                if (unit.KktNumber && (unit.KktNumber == kktNumber)) {
                    return unit;
                }
            }
        }
    }

    /**
     * Prepare request and Execute command
     * @param {string} command
     * @returns {mixed}
     */
    async getRequestAndExecute(command) {
        await this.getRequest(command);
        return await this.execute();
    }

    /**
     * Prepare request for execute
     * @param {string} command
     * @returns {array}
     */
    async getRequest(command) {
        this.request = null;
        this.errors = {};

        return await http
            .get('/' + this.basePath + '/get-request', { params: { command: command } }).then(async response => {
                this.request = response.data.data;

                return this.request;
            }).catch(e => this.catchResponse(e));
    }

    /**
     * Register check on kkm
     * @param {array} request 
     */
    async execute(request) {
        request = request || this.request;
        this.response = null;
        this.errors = {};

        // Проверка на то что наступил новый день и сессию надо переоткрыть
        if (request.Command && !['GetDataKKT', 'GetRezult', 'XReport', 'OpenShift', 'CloseShift'].includes(request.Command) && store.getters['kkm/sessionIsNewDay']) {
            this.errors.sessionIsNewDay = t('Session') + ': ' + t('The day is over');
            return null;
        }

        if (request.isPlugin) {
            if (typeof KkmServer !== 'undefined') {
                // Выполняем команду через расширение
                KkmServer.Execute(data => this.response = data, JSON.parse(JSON.stringify(request)));

                let i = 0;
                while (!this.response && i < 30) {
                    // это нужно, т.к. KkmServer.Execute не работает с await
                    // в итоге получается ждем ответа 30 секунд
                    await new Promise(resolve => setTimeout(resolve, 1000));
                    i++;
                }

                if (this.response) {
                    // Это нужно для дебага. Т.к. мы сохраняем данные ответа, то увидим кол-во попыток
                    this.response.logPluginExecute = i;
                } else {
                    this.errors.execute = '1: ' + t('KKM error');
                }
            } else {
                // по http работает почти также как и через расширение, только почему-то иногда сессия считает не открыта. Как будто отдельным потоком запрашивает
                this.response = await axios
                    .post('http://localhost:5893/Execute', request, {
                        headers: { Authorization: 'Basic VXNlcjoxMjM0NTY=' }
                    })
                    .then(response => response.data)
                    .catch(() => this.errors.execute = '2: ' + t('KKM error'));
            }
        } else {
            this.response = await http
                .post('/' + this.basePath + '/execute', request)
                .then(response => response.data)
                .catch(e => this.catchResponse(e));
        }

        if (this.response && request.Command !== 'GetRezult') {
            // Status === 1 - Run(Запущено на выполнение). При этом статусе ошибки нет, но не все данные долетают, например при открытии смены Info не летит
            // в итоге перезапрашиваем ответ 7 раз с интервалом в 1 секунду
            if (this.response.Status === 1) {
                let i = 0;
                while (this.response && this.response.Status === 1 && i < 7) {
                    await new Promise(resolve => setTimeout(resolve, 1000));
                    i++;

                    let response = await this.execute({ Command: 'GetRezult', IdCommand: this.response.IdCommand });
                    if (response.Rezult) {
                        this.response = response.Rezult;
                    }
                }

                if (this.response) {
                    // Если после всех попыток до сих пор Status === 1, то выдаем ошибку
                    if (this.response.Status === 1) {
                        this.errors.status1 = t('Command run but not completed');
                        return null;
                    }

                    // Это нужно для дебага. Т.к. мы сохраняем данные ответа, то увидим кол-во попыток
                    this.response.logStatus1 = i;
                }
            }

            // Обычно Status = 5 когда команда с этим IdCommand уже была выполнена и летит Error = "Команда уже была выполнена или выполняется в текущий момент"
            // Поэтому перезапрашиваем результат этой команды
            if (this.response.IdCommand && this.response.Error && this.response.Status === 5) {
                let response = await this.execute({ Command: 'GetRezult', IdCommand: this.response.IdCommand });
                if (response.Rezult) {
                    this.response = response.Rezult;
                }
            }

            if (this.response && this.response.Error) {
                this.errors.execute = this.response.Error;
            }
        }

        return this.getError() ? null : this.response;
    }

}

export default KkmCommandService;