<template>
  <div>
    <div class="page-title-box">
      <p class="m-0">{{ $t('Arrival') }}</p>
      <p class="fs-2 fw-bold text-success">
        {{ sum.amount ? $str.money(sum.amount) : 0 }} <span class="fs-4 text-secondary fw-normal">руб.</span>
      </p>
    </div>
    <div class="card dashboard">
      <div class="card-body">
        <GridView
          :service="service"
          :actions="false"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          :columns="[
            {
              field: 'createdAt',
              header: $t('Date'),
              slot: 'created',
            },
            {
              field: 'name',
              header: $t('Title'),
              slot: 'name',
            },
            {
              field: 'desc',
              header: $t('Description'),
              slot: 'desc',
            },
            {
              field: 'amount',
              header: $t('Sum'),
              slot: 'amount',
            },
            {
              field: 'income',
              header: $t('Income'),
              slot: 'income',
            },
          ]"
        >
          <template #created="{ data }">
            <span class="fs-6">
              {{ $str.datetime(data.createdAt) }}
            </span>
          </template>
          <template #name="{ data }">
            <span class="fs-6">
              {{ data.name }}
            </span>
          </template>
          <template #desc="{ data }">
            <span class="fs-6">
              <template v-if="data.product">
                <a :href="'/product/' + data.product.id" target="_blank" rel="noopener noreferrer">
                  {{ data.data ? data.data.name : '' }}
                </a>
              </template>
              <template v-else-if="data.contract">
                <a :href="'/contract/' + data.contract.id" target="_blank" rel="noopener noreferrer">
                  <span>Сделка #{{ data.contract.id }}</span>
                </a>
              </template>
              <template v-else-if="data.deal">
                <router-link :to="'/deal/' + data.deal.deal.id">
                  <span>{{ data.deal.deal.service.name }}</span>
                </router-link>
              </template>
            </span>
          </template>
          <template #amount="{ data }">
            <span class="fs-6">
              {{ data.data ? $str.money(data.data.amount) : '' }}
            </span>
          </template>
          <template #income="{ data }">
            <span class="fs-6">
              {{ data.data && data.product ? $str.money(data.data.amount - data.product.cost) : '' }}
            </span>
          </template>
        </GridView>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from './ui/BreadCrumb.vue';
import GridView from './ui/GridView.vue';
import SpeedDialBtn from './ui/SpeedDialBtn.vue';
import TransactionOperationService from '../modules/transaction/services/TransactionOperationService';

export default {
  components: {
    BreadCrumb,
    GridView,
    SpeedDialBtn,
  },
  data() {
    return {
      service: new TransactionOperationService({
        expand: ['operations', 'name', 'product', 'contract', 'deal'],
      }),
      sum: {},
    };
  },
  async created() {
    this.service.type = 'pko';
    this.service.date = 1;
    this.sum = await this.service.sum();
  },
};
</script>
