<template>
  <!-- Topbar Start -->
  <div class="navbar-custom navbar navbar-expand-lg navbar-light">
    <div class="d-flex align-items-center">
      <button class="button-menu-mobile open-left">
        <i class="mdi mdi-menu"></i>
      </button>
      <div v-if="$store.getters.can('lead.store')" class="nav-item topbar-dropdown d-block d-lg-none">
        <router-link :to="{ name: 'LeadCreate' }" class="nav-link">
          <i class="mdi mdi-plus me-1 fs-4 color-green"></i>
        </router-link>
      </div>
      <div class="nav-item topbar-dropdown d-block d-lg-none">
        <router-link :to="{ name: 'Calculator' }" class="nav-link">
          <i class="mdi mdi-calculator me-1 fs-4 color-grey"></i>
        </router-link>
      </div>
    </div>
    <div class="d-flex align-items-center">
      <SmsList :show="showSmsBar" class="d-block d-lg-none me-1" />
      <div class="d-block d-lg-none dropdown notification-list mx-2 mb-md-0" style="line-height: 0">
        <a
          aria-expanded="false"
          aria-haspopup="false"
          class="dropdown-toggle arrow-none me-0"
          data-bs-toggle="dropdown"
          href="#"
          role="button"
        >
          <Avatar
            v-if="currentUser.avatarFile"
            :image="currentUser.avatarFile.url"
            shape="circle"
            class="avatar icon-block"
          />
          <Avatar v-else :label="name[0]" shape="circle" />
        </a>
        <div class="dropdown-menu dropdown-menu-end dropdown-menu-animated topbar-dropdown-menu profile-dropdown">
          <div>
            <span class="d-block fs-5 text-center account-user-name mt-2">{{ userShortName }}</span>
            <hr />
          </div>
          <a class="dropdown-item notify-item" @click="showUserSidebarProfile(userId)">
            <i class="mdi mdi-account me-1"></i>
            <span>{{ $t('Profile') }}</span>
          </a>
          <a class="dropdown-item notify-item" data-bs-toggle="collapse" @click.prevent="logout()">
            <i class="mdi mdi-logout me-1"></i>
            <span>{{ $t('Exit') }}</span>
          </a>
        </div>
      </div>
      <Button
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
        class="navbar-toggler p-button-rounded p-button-text"
        data-bs-target="#navbarSupportedContent"
        data-bs-toggle="collapse"
        icon="pi pi-ellipsis-v"
        type="button"
      />
    </div>
    <div id="navbarSupportedContent" class="collapse navbar-collapse flex-md-row flex-column justify-content-between">
      <!-- <div class=""> -->
      <ul class="navbar-nav list-unstyled topbar-menu mb-0">
        <li v-if="$store.getters.can('lead.store')" class="nav-item topbar-dropdown d-none d-lg-block">
          <router-link :to="{ name: 'LeadCreate' }" class="nav-link">
            <i class="mdi mdi-plus me-1"></i>
            <span class="d-md-inline d-lg-none d-xxl-inline d-xl-none d-lg-none">{{ $t('Create lead') }}</span>
          </router-link>
        </li>
        <li class="nav-item topbar-dropdown d-none d-lg-block">
          <router-link :to="{ name: 'Calculator' }" class="nav-link">
            <i class="mdi mdi-calculator me-1"></i>
            <span class="d-md-inline d-lg-none d-xxl-inline d-xl-none d-lg-none fs-6">{{ $t('Calculator') }}</span>
          </router-link>
        </li>
        <DashboardRateGold v-if="$store.getters.can('dashboard/rate-gold.showItem')" />
      </ul>
      <ul class="navbar-nav list-unstyled topbar-menu float-lg-end float-none mb-0">
        <KkmStatus v-if="userHasKkt" :hasKkt="userHasKkt" />
        <li
          :class="[getRightOrgType === 'lombard' ? 'org-block-lombdard' : 'org-block-comission']"
          class="dropdown notification-list"
        >
          <a
            aria-expanded="false"
            aria-haspopup="false"
            class="nav-link dropdown-toggle arrow-none"
            href="#"
            role="button"
            @click.prevent="openModal"
          >
            <div class="d-flex flex-row-reverse justify-content-center align-items-center p-2">
              <i
                :class="[getRightOrgType === 'lombard' ? 'org-lombard' : 'org-comission']"
                class="dripicons-chevron-down noti-icon float-end"
              ></i>
              <span class="d-flex me-2 d-flex justify-content-center align-items-center flex-column">
                <span :class="'fs-5 ' + [getRightOrgType === 'lombard' ? 'org-lombard' : 'org-comission']" class="lead"
                  >{{ getRightOrgName }}
                </span>
                <span :class="'fs-6 ' + [getRightOrgType === 'lombard' ? 'org-lombard' : 'org-comission']">{{
                  curDepAddress
                }}</span>
              </span>
            </div>
          </a>

          <!--                    <div class="dropdown-menu dropdown-menu-end dropdown-menu-animated topbar-dropdown-menu">-->
          <!--                      <a-->
          <!--                        @click="changeCurOrg(organization.id)"-->
          <!--                        href="javascript:void(0);"-->
          <!--                        v-for="organization in organizationList"-->
          <!--                        :key="organization.id"-->
          <!--                        class="dropdown-item notify-item"-->
          <!--                      >-->
          <!--                        {{ organization.name }}-->
          <!--                      </a>-->
          <!--                    </div>-->
        </li>
        <li class="d-none">
          <a
            aria-expanded="false"
            aria-haspopup="false"
            class="nav-link dropdown-toggle arrow-none"
            data-bs-toggle="dropdown"
            href="#"
            role="button"
          >
            <i class="dripicons-search noti-icon"></i>
          </a>
          <div class="dropdown-menu dropdown-menu-animated dropdown-lg p-0">
            <form class="p-3">
              <input :placeholder="$t('Search')" aria-label="Recipient's username" class="form-control" type="text" />
            </form>
          </div>
        </li>
        <!-- <li class="dropdown notification-list topbar-dropdown">
        <a
          class="nav-link dropdown-toggle arrow-none"
          data-bs-toggle="dropdown"
          href="#"
          role="button"
          aria-haspopup="false"
          aria-expanded="false"
        >
          <img
            :src="'/hyper/img/flags/' + locale + '.jpg'"
            alt="user-image"
            class="me-0 me-sm-1"
            height="12"
          />
          <span class="align-middle d-none d-sm-inline-block">{{
            AllLangs[locale]
          }}</span>
          <i
            class="mdi mdi-chevron-down d-none d-sm-inline-block align-middle"
          ></i>
        </a>
        <div
          class="
            dropdown-menu dropdown-menu-end dropdown-menu-animated
            topbar-dropdown-menu
          "
        >
          <a
            @click="changeCurLocale(index)"
            href="javascript:void(0);"
            v-for="(lang, index) in langs"
            :key="lang"
            class="dropdown-item notify-item"
          >
            <img
              :src="'/hyper/img/flags/' + index + '.jpg'"
              alt="user-image"
              class="me-1"
              height="12"
            />
            <span class="align-middle">{{ lang }}</span>
          </a>
        </div>
      </li> -->

        <SmsList :show="showSmsBar" class="d-none d-lg-block" />

        <!--      <SmsChat />-->

        <li class="d-none d-lg-block dropdown notification-list mb-2 mb-md-0">
          <a
            aria-expanded="false"
            aria-haspopup="false"
            class="nav-link dropdown-toggle nav-user arrow-none me-0"
            data-bs-toggle="dropdown"
            href="#"
            role="button"
          >
            <!-- <span class="account-user-avatar">
            <img
              src="assets/images/user/avatar-1.jpg"
              alt="user-image"
              class="rounded-circle"
            />
          </span> -->
            <span>
              <span class="d-block fs-6 account-user-name mt-2">{{ userShortName }}</span>
              <!-- <span class="account-position">Founder</span> -->
            </span>
          </a>
          <div class="dropdown-menu dropdown-menu-end dropdown-menu-animated topbar-dropdown-menu profile-dropdown">
            <!-- item-->
            <!-- <div class="dropdown-header noti-title">
            <h6 class="text-overflow m-0">Welcome !</h6>
          </div> -->

            <!-- item-->
            <a class="dropdown-item notify-item" @click="showUserSidebarProfile(userId)">
              <i class="mdi mdi-account me-1"></i>
              <span>{{ $t('Profile') }}</span>
            </a>
            <!-- item-->
            <a class="dropdown-item notify-item" data-bs-toggle="collapse" @click.prevent="logout()">
              <i class="mdi mdi-logout me-1"></i>
              <span>{{ $t('Exit') }}</span>
            </a>
          </div>
        </li>
      </ul>
      <!-- </div> -->
    </div>
  </div>
  <Dialog
    class="departmentDialog"
    style="width: 800px"
    header="Header"
    :modal="true"
    v-model:visible="changeDepartmentModalDisplay"
  >
    <template #header>
      <h3>Сменить офис</h3>
    </template>

    <Tree
      :filter="true"
      filterMode="strict"
      :value="this.groupedDepartments"
      selectionMode="single"
      :expandedKeys="expandedKeys"
      v-model:selectionKeys="selectedKey"
      :metaKeySelection="false"
      @nodeSelect="onNodeSelect"
      @nodeUnselect="onNodeUnselect"
    />
  </Dialog>
  <!-- end Topbar -->
</template>

<script>
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Avatar from 'primevue/avatar';
import Dialog from 'primevue/dialog';
import InputNumber from 'primevue/inputnumber';
import Listbox from 'primevue/listbox';
import Button from 'primevue/button';
import http from '../utils/http-common';
// import SmsChat from './sms/chat/SmsChat'
import SmsList from './sms/SmsList.vue';
import KkmStatus from '../modules/kkm/components/KkmStatus.vue';
import DashboardRateGold from '../modules/dashboard/components/DashboardRateGold.vue';
import DepartmentService from '../modules/organization/services/DepartmentService';
import UserService from '../modules/user/services/UserService';
import Tree from 'primevue/tree';

export default {
  components: {
    Avatar,
    InputNumber,
    Button,
    // SmsChat,
    Tree,
    SmsList,
    KkmStatus,
    DashboardRateGold,
    Dialog,
    Listbox,
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    return {
      logout: () => {
        this.$cookies.remove('app-web'['/'['karman24.loc']]);
        this.$cookies.remove('_identity'['/'['karman24.loc']]);
        store.commit('auth/logout');
        router.push('/auth');
      },
    };
  },
  data: function () {
    return {
      departmentService: new DepartmentService({ expand: ['organizationName'] }),
      userService: new UserService({ expand: ['roles', 'departmentName', 'avatarFile'] }),
      locale: localStorage.locale,
      // language: 'Русский', //Название языка локализации по умолчанию.
      name: localStorage.user,
      userShortName: localStorage.userShortName,
      userId: localStorage.userId,
      curOrgId: localStorage.curOrgId,
      curDepAddress: null,
      showProd: false,
      persons: {},
      selectedPerson: null,
      groupedDepartments: {},
      changeDepartmentModalDisplay: false,
      selectedDepartment: {},
      currentUser: {},
      AllLangs: {
        ru: '',
        us: '',
      },
      langs: {
        ru: 'Русский',
        us: 'English',
      },
      host: window.location.origin,
      showSmsBar: false,
      userHasKkt: false,
      expandedKeys: {},
      selectedKey: null,
    };
  },
  async created() {
    if (this.host === 'https://erpnew.karman24.ru' || this.host === 'http://localhost:8080') {
      this.showSmsBar = true;
    }
    this.groupedDepartments = await this.departmentService.getGroupedDepartmentsListForUser();
    this.currentUser = await this.userService.show(this.userId);

    this.expandAll();
  },
  methods: {
    async onNodeSelect(node) {
      this.expandedKeys[node.key] = !this.expandedKeys[node.key];
      if (node.data.type == 'department') {
        this.userService.departmentId = node.data.id;
        let response = await this.userService.updateDepartmentId();
        this.changeDepartmentModalDisplay = false;

        this.changeCurOrg(response.organizationId);

        let menu = await this.userService.allGroupListForMenu();
        this.$store.commit('auth/setMenuItemsViaRoles', menu);
        if (menu) {
          this.$router.go();
        }
      }
    },
    async onNodeUnselect(node) {
      this.expandedKeys[node.key] = !this.expandedKeys[node.key];
    },
    expandAll() {
      for (let node of this.groupedDepartments) {
        this.expandNode(node);
      }

      this.expandedKeys = { ...this.expandedKeys };
    },
    expandNode(node) {
      this.expandedKeys[node.key] = true;
      if (node.children && node.children.length) {
        for (let child of node.children) {
          this.expandNode(child);
        }
      }
    },
    openModal() {
      this.changeDepartmentModalDisplay = !this.changeDepartmentModalDisplay;
      setTimeout(() => {
        let departmentDialog = document.querySelector('.departmentDialog');
        let departmentDialogContent = departmentDialog.querySelector('.p-dialog-content');
        departmentDialog.style.minHeight = departmentDialog.offsetHeight + 'px';
        departmentDialogContent.style.minHeight = departmentDialogContent.offsetHeight + 'px';
      }, 300);
    },
    checkStorageExpire() {
      if (localStorage.getItem('storageExpire') <= new Date().getTime() / 1000) {
        localStorage.removeItem('jwt-token');
        this.$router.replace({ name: 'Auth' });
      }
    },
    changeCurOrg(orgId) {
      this.curOrgId = orgId;
      this.$store.commit('auth/setCurOrgId', orgId);
      this.changeCurOrgRequest(orgId);
    },
    async logout() {
      this.$store.commit('auth/logout');
      this.$router.push('/auth');
    },
    async getUser() {
      try {
        await http.get('/api/user/info').then((response) => {
          this.curDepAddress = response.data.curDepAddress;
          this.curOrgId = response.data.userInfo.organization_id;
          this.userHasKkt = response.data.hasKkt;
        });
      } catch (e) {
        console.log(e);
      }
    },
    async changeCurOrgRequest(orgId) {
      // updateOrganizationId
      try {
        await http.put('/user/update-organization-id', { orgId: orgId }).then((response) => {
          this.getUser();
        });
      } catch (e) {
        console.log(e);
      }
    },
    curLocale(locale) {
      delete this.langs[localStorage.locale];
    },
    changeCurLocale(newLocale) {
      localStorage.locale = newLocale;
      this.$router.go();
    },
    showUserSidebarProfile(userId) {
      this.$store.commit('setSidebarUserId', { userId: userId });
      this.$store.commit('setVisible', { visible: true });
    },
  },
  mounted: function () {
    this.getUser();
    this.curLocale(localStorage.locale);
    this.checkStorageExpire();
  },
  computed: {
    organizationList() {
      return this.$store.getters['auth/organizationList'];
    },
    getRightOrgName() {
      for (var index in this.organizationList) {
        if (this.curOrgId == this.organizationList[index].id) {
          return this.organizationList[index].name;
        }
      }
    },
    getRightOrgType() {
      for (var index in this.organizationList) {
        if (this.curOrgId == this.organizationList[index].id) {
          return this.organizationList[index].activityType;
        }
      }
    },
  },
};
</script>

<style>
.notification-list .topbar-dropdown-menu .notify-item {
  padding: 17px 20px;
}

@media (max-width: 992px) {
  .topbar-dropdown .nav-link {
    line-height: 52px;
  }
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
  background: none !important;
}
</style>
