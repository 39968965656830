<template>
  <div class="page-title-right">
    <ol class="breadcrumb m-0">
      <li class="breadcrumb-item" v-for="breadItem in this.breadcrumbs" :key="breadItem">
        <router-link
          v-if="breadItem.routeName && breadItem.param != null"
          class="breadcrumbs-link"
          :to="{ name: breadItem.routeName, params: { id: breadItem.param } }"
          >{{ breadItem.label }}</router-link
        >
        <router-link v-else-if="breadItem.routeName" class="breadcrumbs-link" :to="{ name: breadItem.routeName }">{{
          $t(breadItem.label)
        }}</router-link>
        <span v-else-if="breadItem.notTranslate == true" class="breadcrumb-item active">
          {{ breadItem.label }}
        </span>
        <span v-else class="breadcrumb-item active">
          {{ $t(breadItem.label) }}
        </span>
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  props: {
    breadcrumbs: Array,
  },
  created() {
    document.title = this.$t(this.breadcrumbs[0].label);
  },
};
</script>