import { createStore, createLogger } from 'vuex'
import auth from './modules/auth.module'
import kkm from './modules/kkm.module'
import userSidebar from './modules/userSidebar.module'

const plugins = []

if (process.env.NODE_ENV === 'development') {
  plugins.push(createLogger())
}

export default createStore({
  plugins,
  state() {
    return {
      message: null,
      locale: localStorage.getItem('locale')
        ? localStorage.getItem('locale')
        : localStorage.setItem('locale', 'ru'),
      loadingPage: false
    }
  },
  mutations: {
    setLoadingPage(state) {
      state.loadingPage = true
    },
    setMessage(state, message) {
      state.message = message
      state.loadingPage = false
    },
    clearMessage(state) {
      state.message = null
    },
    setLocale(state, locale) {
      state.locale = locale
      localStorage.setItem('locale', locale)
    }
  },
  actions: {
    setLoadingPage({ commit }) {
      commit('setLoadingPage')
    },
    setMessage({ commit }, message) {
      commit('setMessage', message)
      setTimeout(() => {
        commit('clearMessage')
      }, 5000)
    },
    setLocale({ commit }, locale) {
      commit('setLocale', locale.value)
    }
  },
  getters: {
    getLoadingPage(state) {
      return state.loadingPage
    },
    can: (state) => (permissionName) => {
      return state.auth.userIsAdmin || state.auth.userPermissionNames.includes(permissionName);
    }
  },
  modules: {
    auth,
    kkm,
    userSidebar
  }
})
