<template>
  <Sidebar class="userSidebar p-sidebar-sm" :visible="this.visible" position="right">
    <div v-if="userLoading" class="loadingOverlay">
      <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important"></i>
    </div>

    <template v-if="isPersonalSidebar || $store.getters['auth/userIsAdmin']">
      <div class="hovereffect">
        <img
          v-if="service.avatarFile"
          class="img-responsive"
          :src="service.avatarFile.url"
          alt=""
          style="object-fit: cover"
        />
        <Avatar v-else class="avatar" :label="service.name[0]" size="large" />
        <div class="overlay d-flex align-items-center justify-content-center">
          <div>
            <FormField :service="service" field="avatarFile" type="file" />
          </div>
        </div>
      </div>

      <Form
        class="px-2 my-3"
        v-if="isPersonalSidebar || $store.getters['auth/userIsAdmin']"
        :service="service"
        :isSuccessToast="true"
      >
        <div>
          <h3 class="m-0">{{ user.name }}</h3>
        </div>

        <div class="mt-3">
          <p class="propertyTitle">{{ $t('Organization') }}</p>
          <p>{{ service.organizationName }}</p>
        </div>

        <div>
          <p class="propertyTitle">{{ $t('Office') }}</p>
          <p>{{ service.departmentName }}</p>
        </div>

        <div>
          <p class="propertyTitle">{{ $t('Office extension number') }}</p>
          <p>{{ service.departmentInternalPhone }}</p>
        </div>

        <div>
          <p class="propertyTitle">{{ $t('Position') }}</p>
          <p>{{ service.positionName }}</p>
        </div>

        <div>
          <p class="propertyTitle">{{ $t('Phone') }}</p>
          <Inplace :active="phoneInputIsShow" class="mb-2">
            <template #display>
              {{ service.phone ?? $t('Not specified') }}
            </template>
            <template #content>
              <FormField :service="service" field="phone" type="mask" mask="+7(999)999-99-99" />
            </template>
          </Inplace>
        </div>

        <div>
          <p class="propertyTitle">{{ $t('Phone work') }}</p>
          <Inplace class="mb-2">
            <template #display>
              {{ service.phoneWork ?? $t('Not specified') }}
            </template>
            <template #content>
              <FormField :service="service" field="phoneWork" />
            </template>
          </Inplace>
        </div>

        <div>
          <p class="propertyTitle">{{ $t('Employee internal phone') }}</p>
          <Inplace class="mb-2">
            <template #display>
              {{ service.phoneInside ?? $t('Not specified') }}
            </template>
            <template #content>
              <FormField :service="service" field="phoneInside" />
            </template>
          </Inplace>
        </div>
      </Form>
    </template>
    <div v-else>
      <div>
        <img
          v-if="service.avatarFile"
          class="img-responsive"
          style="width: 100%"
          :src="service.avatarFile.url"
          alt=""
        />
        <Avatar v-else :label="service.name[0]" size="large" />
      </div>

      <div class="p-2">
        <h3>{{ user.name }}</h3>

        <div>
          <strong>{{ $t('Organization') }}</strong>
          <p>{{ service.organizationName }}</p>
        </div>

        <div>
          <strong>{{ $t('Office') }}</strong>
          <p>{{ service.departmentName }}</p>
        </div>

        <div>
          <strong>{{ $t('Office extension number') }}</strong>
          <p>{{ service.departmentInternalPhone }}</p>
        </div>

        <div>
          <strong>{{ $t('Role') }}</strong>
          <div v-for="role in service.roles" :key="role">{{ role.name }}</div>
          <p></p>
        </div>

        <div v-if="service.phone">
          <strong>{{ $t('Phone') }}</strong>
          <p>{{ service.phone }}</p>
        </div>

        <div v-if="service.phoneWork">
          <strong>{{ $t('Phone work') }}</strong>
          <p>{{ service.phoneWork }}</p>
        </div>

        <div v-if="service.phoneInside">
          <strong>{{ $t('Phone inside') }}</strong>
          <p>{{ service.phoneInside }}</p>
        </div>
      </div>
    </div>
  </Sidebar>
</template>

<script>
import Sidebar from 'primevue/sidebar';
import UserService from '../../modules/user/services/UserService';
import Form from '../ui/form/Form.vue';
import FormField from '../ui/form/FormField.vue';
import Avatar from 'primevue/avatar';
import Image from 'primevue/image';
import Inplace from 'primevue/inplace';

export default {
  name: 'userSidebarProfile',
  data() {
    return {
      service: new UserService({
        expand: ['roles', 'organizationName', 'departmentName', 'avatarFile', 'departmentInternalPhone'],
      }),
      user: [],
      isPersonalSidebar: true,
      userLoading: false,
      phoneInputIsShow: false,
    };
  },
  components: {
    Avatar,
    Sidebar,
    Form,
    FormField,
    Inplace,
    Image,
  },
  async created() {
    this.userLoading = true;
    this.user = await this.service.show(localStorage.userId);

    this.userLoading = false;
  },
  async updated() {
    this.userLoading = true;

    // Если Id авторизованного пользователя совпадает с передаваемым
    // то показываем сайдбар с возможность изменения данных
    if (localStorage.userId == this.userId) {
      this.isPersonalSidebar = true;
    } else {
      this.isPersonalSidebar = false;
    }

    // Получаем данные пользователя
    this.user = await this.service.show(this.userId);

    if (this.visible) {
      // В обработчике событий нет доступа к глобальному this
      // Поэтому создаем переменную для работы с store
      let store = this.$store;
      // При клике на оверлей закрываем сайдбар
      document.querySelector('.p-sidebar-mask').onclick = function () {
        store.commit('setVisible', { visible: false });
      };
      // При клике на крестик закрываем сайдбар
      document.querySelector('.p-sidebar-close').onclick = function () {
        store.commit('setVisible', { visible: false });
      };
    }

    setTimeout(() => {
      this.userLoading = false;
    }, 1000);
  },
  computed: {
    visible() {
      return this.$store.getters.getSidebarIsVisible;
    },
    userId() {
      return this.$store.getters.getUserId;
    },
  },
};
</script>

<style>
.userSidebar .p-sidebar-content {
  padding: 0 !important;
}

.userSidebar .p-sidebar-header {
  padding: 0;
  position: absolute;
  z-index: 99;
  right: 10px;
}

.propertyTitle {
  color: #a3a9b1;
  font-size: 12px;
  margin-bottom: 0;
}

.p-image > img {
  width: 100%;
}

.p-inplace-display {
  padding: 0 !important;
}

.p-fileupload-buttonbar {
  background-color: unset !important;
  border: none !important;
}

.p-avatar.p-avatar-lg {
  height: 320px !important;
}

.hovereffect {
  width: 100%;
  height: 320px;
  margin-bottom: 25px;
  float: left;
  overflow: hidden;
  position: relative;
  text-align: center;
  cursor: default;
  background: #dee2e6;
}

.hovereffect .overlay {
  width: 100%;
  height: 320px;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  padding: 50px 20px;
}

.hovereffect img {
  display: block;
  position: relative;
  max-width: none;
  width: 320px;
  height: 320px;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.hovereffect:hover img {
  opacity: 0.4;
  filter: alpha(opacity=40);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.hovereffect h2 {
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  position: relative;
  font-size: 17px;
  overflow: hidden;
  padding: 0.5em 0;
  background-color: transparent;
}

.hovereffect div,
.hovereffect div {
  color: #fff;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.hovereffect:hover div,
.hovereffect:hover div {
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
</style>

<style scoped>
.loadingOverlay {
  position: fixed;
  width: 320px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: lightgray;
  z-index: 99999;
}

.p-sidebar .p-sidebar-header {
  padding: 1em;
  position: absolute;
  z-index: 99;
  right: 0;
}

.p-avatar.p-avatar-lg {
  width: 100%;
  height: auto;
}
</style>
