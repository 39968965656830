<template>
  <div>
    <Sidebar v-model:visible="visibleSearch" position="right">
      <FormField
        v-for="s of search"
        :key="s.field"
        :service="service"
        :type="s.type"
        :selectionMode="s.selectionMode"
        :mask="s.mask"
        :field="s.field"
        :label="s.label"
        :options="s.options"
        :optionValue="s.optionValue"
        :optionLabel="s.optionLabel"
        :disabled="s.disabled"
        @change="s.onChange"
        @input="$emit('filter', $event)"
      />
    </Sidebar>

    <SpeedDial showIcon="pi pi-search" class="custom-speeddial-right2" @click="visibleSearch = true"></SpeedDial>
  </div>
</template>

<script>
import Button from 'primevue/button';
import Sidebar from 'primevue/sidebar';
import SpeedDial from 'primevue/speeddial';
import FormField from './form/FormField.vue';

export default {
  components: {
    Button,
    Sidebar,
    SpeedDial,
    FormField,
  },
  props: {
    service: Object,
    search: Object,
  },
  data() {
    return {
      visibleSearch: false,
    };
  },
};
</script>