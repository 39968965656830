export const ru = {
    // Collections
    'January': 'Январь',
    'February': 'Февраль',
    'March': 'Март',
    'April': 'Апрель',
    'May': 'Май',
    'June': 'Июнь',
    'July': 'Июль',
    'August': 'Август',
    'September': 'Сентябрь',
    'October': 'Октябрь',
    'November': 'Ноябрь',
    'December': 'Декабрь',

    'of January': 'Января',
    'of February': 'Февраля',
    'of March': 'Марта',
    'of April': 'Апреля',
    'of May': 'Мая',
    'of June': 'Июня',
    'of July': 'Июля',
    'of August': 'Августа',
    'of September': 'Сентября',
    'of October': 'Октября',
    'of November': 'Ноября',
    'of December': 'Декабря',

    'Mo': 'Пн',
    'Tu': 'Вт',
    'We': 'Ср',
    'Th': 'Чт',
    'Fr': 'Пт',
    'Sa': 'Сб',
    'Su': 'Вс',

    // Other
    '% PSC': '% ПСК',

    '1C Ascort': '1С Аскорт',
    '6x4 bold': '6х4 жирный',
    '8x6 bold': '8х6 жирный',

    'A transaction with these parameters has already been created': 'Транзакция с такими параметрами уже создана',
    'About the beneficiary': 'Сведения о выгодоприобретателе',
    'Accept': 'Принять',
    'Acceptance of gold': 'Прием золота',
    'Acceptance of gold from clients (pawn)': 'Прием золота от клиентов (лом)',
    'Accepted for consideration': 'Принято к рассмотрению',
    'Accepted from': 'Принято от',
    'Access': 'Доступ',
    'Access denied': 'Доступ закрыт',
    'Account card': 'Карточка аккаунта',
    'Account description': 'Описание счета',
    'Account number': 'Номер счёта',
    'Accounts': 'Аккаунты',
    'Accrual for the grace period': 'Начисление по льготному периоду',
    'Accrual under the contract': 'Начисление по договору',
    'Action': 'Действие',
    'Action time': 'Время действия',
    'Actions': 'Действия',
    'Active': 'Активно',
    'Actives': 'Активный',
    'Actives report': 'Отчет по активам',
    'Activity log': 'Лог действий',
    'Activity type': 'Тип деятельности',
    'Active clients': 'Активные клиенты',
    'Acquiring': 'Эквайринг',
    'Add a product to pawn?': 'Добавить продукт в лом?',
    'Add a product to transfer?': 'Добавить продукт в ИП?',
    'Add a product to using?': 'Добавить продукт в пользование?',
    'Add product': 'Добавить товар',
    'Add': 'Добавить',
    'Add an office checkpoint!': 'Добавьте КПП офиса!',
    'Add files': 'Прикрепить файлы',
    'Add group': 'Добавить группу',
    'Add OGRN office!': 'Добавьте ОГРН офиса!',
    'Add office FN!': 'Добавьте ФН офиса!',
    'Adding products': 'Добавление товаров',
    'Address': 'Адрес',
    'Adjusted by compiling accounting entries': 'Отрегулировано за счет составления записей в учете',
    'Administrator': 'Администратор',
    'Advance amount': 'Сумма аванса',
    'Aggregate batch': 'Агрегированная партия',
    'All': 'Все',
    'All chats': 'Все чаты',
    'Another series of Z.B.': 'Очередная серия З.Б.',
    'Amount': 'Сумма',
    'Amount of active contracts at the beginning of the period': 'Сумма действующих договоров займа на начало периода',
    'Amount of active contracts at the end of the period': 'Сумма действующих договоров займа на конец периода',
    'Amount payable': 'Сумма к оплате',
    'Amount to be received for property transferred for sale': 'Сумма, подлежащая к получению за имущество, переданного на реализацию',
    'Amount to be repaid': 'Сумма к погашению',
    'An error occurred while sending! try later': 'Возникла ошибка при отправке! Попробуйте позже',
    'Another number': 'Другой номер',
    'API category id': 'ID категории (API)',
    'API created user id': 'ID постановщика (API)',
    'API responsible id': 'ID ответственного (API)',
    'Api url': 'Адрес API',
    'Application form': 'Анкета',
    'Applications': 'Заявок',
    'Approval': 'Утверждение',
    'Approved': 'Утвердил',
    'Are you sure you want to proceed?': 'Вы уверены, что хотите продолжить?',
    'Arrival': 'Приход',
    'Arrival and expenses': 'Приход и расход',
    'Article': 'Статья',
    'Assessed': 'Начислено',
    'Assessment amount': 'Сумма оценки',
    'At the beginning of the shift': 'В начале смены',
    'Attention': 'Внимание',
    'Authorization': 'Авторизация',
    'Avatar': 'Аватар',
    'Available for import': 'Доступно для импорта',
    'Available charts': 'Доступные графики',
    'Avito': 'Авито',
    'Awaiting shipment': 'Ожидает отправки',

    'Balance at the beginning of period': 'Остаток на начало периода',
    'Bank account': 'Расчетный счет',
    'Bank name': 'Название банка',
    'Balance at the end of the period': 'Остаток на конец периода',
    'Balance at the end of the period not repaid on time': 'Остаток на конец периода, не погашенные в установленный срок',
    'Base': 'Основание',
    'Basic information': 'Основная информация',
    'Batch count': 'Количество партий',
    'Battle': 'Боевой',
    'Beginning of period': 'Начало периода',
    'Beneficial owner of the client': 'Бенефициарный владелец клиента',
    'By days': 'По дням',
    'Build': 'Сборка',
    'BIK': 'Бик',
    'Business relationship with LLC': 'Деловые отношения с ООО',
    'Business reputation': 'Деловая репутация',
    'Bookkeeping debit account': 'Счет дебита',
    'Bookkeeping credit account': 'Счет кредита',
    'Bookkeeping comment': 'Комментарий проводки',
    'Buying a new product': 'Покупка нового товара',
    'Buying': 'Скупка',
    'Buyout': 'Выкуп',
    'Buyer ID': 'Идентификатор покупателя',

    'Calendar month': 'Календарный месяц',
    'Calculation by days': 'Расчет по дням',
    'Calculations': 'Расчеты',
    'Calculation of the number of days': 'Расчет количества дней',
    'Calculator': 'Калькулятор',
    'Cancel': 'Отмена',
    'Canceled': 'Отменено',
    'Cancellation of the contract by the operator': 'Отмена договора оператором',
    'Cancellation of the contract by the client': 'Отмена договора по заявлению клиента ',
    'Cancellation at the request of the client': 'Отмена по заявлению клиента',
    'Cash': 'Наличные',
    'Cash flow': 'Статья затрат',
    'Cash change': 'Сдача',
    'Cash in hand': 'Денег в кассе',
    'Cash payments': 'Наличный расчет',
    'Cashless': 'Безнал',
    'Cashless payments': 'Безналичный расчет',
    'Cashless: By current account': 'Безналичный: По расчетному счету',
    'Category': 'Категория',
    'Category ID': 'ID категории',
    'Category name': 'Название категории',
    'Category of products': 'Категория товаров',
    'Categories of products': 'Категории товаров',
    'Cause': 'Причина',
    'Chart': 'График',
    'Check printout': 'Распечатка чека',
    'Checked': 'Проверенно',
    'Checkout': 'Оформить',
    'Chemical mass': 'Химическая масса',
    'Choose': 'Выбрать',
    'Choose the type of print': 'Выберите вид печати',
    'Citizenship': 'Гражданство',
    'City': 'Город',
    'Clear': 'Очистить',
    'Client': 'Клиент',
    'Client affiliation': 'Принадлежность клиента',
    'Client latest transactions': 'Последние сделки клиента',
    'Client money': 'Деньги клиента',
    'Client position': 'Должность клиента',
    'Client risk': 'Степень риска клиента',
    'Client refusal': 'Отказ клиента',
    'Clients latest contract': 'Последние сделки клиента',
    'Clients latest product': 'Последние товары клиента',
    'Clients report': 'Отчет по клиентам',
    'Clients with active contracts': 'Клиенты с активными договорами',
    'Close shift': 'Закрыть смену',
    'Closed': 'Закрыл',
    'Closing date': 'Дата закрытия',
    'Close the appeal': 'Закрыть обращение',
    'Closed at': 'Дата закрытия',
    'Closed leads without deal': 'Закрыто лидов без сделки',
    'Co-executors': 'Соисполнители',
    'Co-Executors default': 'Соисполнители по умолчанию',
    'collateral portfolio': 'залоговый портфель',
    'Collection start date': 'Дата начала сбора',
    'Collection end date': 'Дата окончания сбора',
    'Command': 'Команда',
    'Command run but not completed': 'Команда запущена, но не выполнена',
    'Common or no': 'Общий или нет',
    'Comment': 'Комментарий',
    'Commission': 'Комисионный магазин',
    'Commission agreement': 'Договор комиссии',
    'Commission Agreement (Purchase)': 'Договор комиссии (Скупка)',
    'Commission payment': 'Оплата комиссионного вознаграждения',
    'Commission per day': 'Комиссионное вознаграждение по дням',
    'Commission/purchase': 'Комиссия/скупка',
    'Commission remuneration': 'Комиссионное вознаграждение',
    'Commission shop': 'Комиссионный магазин',
    'Completed': 'Завершено',
    'Completed by rollback': 'Завершена откатом',
    'Condition': 'Состояние',
    'Confirmation': 'Подтверждение',
    'Confirmation of the authorization of the client`s representative': 'Подтверждение наличия полномочия представителя клиента',
    'Conclude a consumer loan agreement': 'Заключить договор потребительского займа',
    'Concluded transactions': 'Завершенные сделки',
    'Contact': 'Контакт',
    'Contract GIIS': 'Контракт ГИИС',
    'Contact type': 'Тип контакта',
    'Contract type': 'Тип контракта',
    'Contract amount from': 'Сумма по договору от',
    'Contract amount to': 'Сумма по договору до',
    'Contract of sale': 'Договор купли-продажи',
    'Contacts': 'Контакты',
    'Contract': 'Договор',
    'contract': 'договор',
    'Contract already created': 'Сделка уже создана',
    'Contract available for import': 'Сделка доступна для импорта',
    'Contracts already have tariff': 'Сделки с тарифами',
    'Contract card': 'Карточка сделки',
    'Contract dont created': 'Сделка не создана',
    'Contracts can update': 'Доступно сделок для обновления',
    'Contract number': 'Номер договора',
    'Contract types': 'Типы контракта',
    'Contracts': 'Сделки',
    'Converted': 'Сконвертирован',
    'Converted leads': 'Сконвертированных лидов',
    'Conversion': 'Конверсия',
    'Conversion rate': 'Процент конверсии',
    'Consumer loan agreement': 'Договор потребительского займа',
    'Contracts concluded': 'Заключено договоров займа',
    'Contracts closed': 'Закрыто договоров',
    'Consignee': 'Грузополучатель',
    'Copy': 'Копировать',
    'Copy from': 'Копировать из',
    'Copy to new': 'Копировать в новую',
    'Cost': 'Стоимость',
    'Cost contract': 'Сумма по договору',
    'Cost item': 'Статья затрат',
    'Cost price': 'Себестоимость',
    'Cost service': 'Стоимость услуги',
    'Correction check': 'Чек коррекции',
    'Correction check is printed': 'Чек коррекции распечатан',
    'Correction date': 'Дата коррекции',
    'Correspondent account': 'Корреспондентский счет',
    'Count': 'Количество',
    'Count days': 'Количество дней',
    'Count of existing contracts for which a grace period of one month has expired, but the obligations under them have not been paid off or the pledged property has not yet been sold at the end of the reporting period': 'Количество действующих договоров займа, по которым истек льготный месячный срок, но обязательства по ним не погашены или заложенное имущество еще не продано на конец отчетного периода.',
    'Count of existing contracts, obligations for which were not repaid on time and which are on a grace period of one month': 'Количество действующих договоров займа обязательства, по которым не погашены в установленный срок и которые находятся на льготном месячном сроке',
    'Count of Users': 'Кол-во пользователей',
    'Count products': 'Кол-во товаров',
    'Count services': 'Количество услуг',
    'Counteragent': 'Контрагент',
    'Counteragent already created': 'Клиент уже создан',
    'Counteragent available for import': 'Клиент доступен для импорта',
    'Counteragents': 'Контрагенты',
    'Comments is read': 'Есть новый комментарий',
    'Create': 'Добавить',
    'Create a Wiki article': 'Создать Wiki статью',
    'Create a deal': 'Создать сделку',
    'Create a category': 'Создать категорию',
    'Create a ticket': 'Добавить новое обращение в тех. поддержку',
    'Create a tariff': 'Добавить тариф',
    'Create an operation': 'Создать операцию',
    'Create lead': 'Добавить лид',
    'Created lead': 'Создать лид',
    'Created lead and contract': 'Создать лид и сделку',
    'created': 'добавлено',
    'Created': 'Добавлено',
    'Creating': 'Добавление',
    'creator': 'добавил',
    'Creator': 'Добавил',
    'Creator ID': 'ID создателя',
    'Credit account': 'Счет кредита',
    'Credit': 'Кредит',
    'Current amount': 'Текущее количество',
    'Custom value for rounding up the pledge period': 'Свое значение для округления срока залога',
    'Customer data or tariff': 'Данные клиента или тариф',

    'Date': 'Дата',
    'Date expiration': 'Дата окончания',
    'Date deal': 'Дата контракта',
    'Date from': 'Дата от',
    'Date of acceptance': 'Дата принятия',
    'Date of deposit': 'Дата внесения',
    'Date of Birth': 'Дата рождения',
    'Date of cancel': 'Дата отмены',
    'Date of check': 'Дата чека',
    'Date of creation': 'Дата создания',
    'Date of creation from': 'Дата создания от',
    'Date of creation to': 'Дата создания до',
    'Date of conclusion': 'Дата заключения',
    'Date of conclusion (Prologation)': 'Дата заключения (Прологнации)',
    'Date of departure': 'Дата отправки',
    'Date of editing': 'Дата редактирования',
    'Date of issue': 'Дата выдачи',
    'Date of read': 'Дата прочтения',
    'Date of recruitment start': 'Дата начала набора',
    'Date of sale': 'Дата продажи',
    'Date of service': 'Дата оказания услуги',
    'Dates of the reporting period': 'Даты отчетного периода',
    'Date to': 'Дата до',
    'Date send from': 'Дата отправки от',
    'Date send to': 'Дата отправки до',
    'Date check from': 'Дата чека от',
    'Date check to': 'Дата чека до',
    'Date of grace period end': 'Дата окончания льготного периода',
    'Date specification': 'Дата спецификации',
    'Day': 'День',
    'Date of conclusion of the contract': 'Дата заключения договора',
    'Days in the window': 'Дней на витрине',
    'Days in status': 'Дней в статусе',
    'Days on sale': 'Дней в продаже',
    'Debit account': 'Счет дебета',
    'Decided': 'Решено',
    'Default office': 'Офис по-умолчанию',
    'Default user': 'Сотрудник по-умолчанию',
    'Default storage': 'Место хранения по-умолчанию',
    'Days overdue': 'Дней просрочки',
    'Debit': 'Дебит',
    'Deal': 'Сделка',
    'Deal Lead': 'Лид сделки',
    'Delete': 'Удалить',
    'Delete comment': 'Удалить комментарий',
    'Deleted': 'Удален',
    'deleted': 'удалил',
    'Deletion': 'Удаление',
    'Department': 'Департамент',
    'Department recipient': 'Офис получатель',
    'Department sender': 'Офис отправитель',
    'Departments': 'Департаменты',
    'Deposit': 'Залог',
    'Deposit tech': 'Залогов техники',
    'Deposit gold': 'Залогов золота',
    'Department code': 'Код подразделения',
    'Desktop': 'Рабочий стол',
    'Description': 'Описание',
    'Description for price tags': 'Описание для ценников',
    'Desired amount': 'Желаемая сумма',
    'Detail percents': 'Детальные проценты',
    'Detail operational report': 'Детальный оперативный отчет',
    'Detail actives report': 'Детальный отчет по активам',
    'Detail finance report': 'Детальный отчет по финансам',
    'Didnt pass the test': 'Не прошли проверку',
    'Direction': 'Направление',
    'Director': 'Директор',
    'Directory': 'Справочник',
    'Do not ask for UIN': 'Не запрашивать УИН',
    'Do not change the status of the request': 'Не изменять статус обращения',
    'Do you have a UIN?': 'Есть УИН?',
    'Do you want to cancel the transaction?': 'Вы хотите отменить транзакцию?',
    'Do you want to rollback the transaction?': 'Вы хотите откатить транзакцию?',
    'dob': 'д.р.',
    'Document data': 'Данные документов',
    'Document details': 'Реквизиты документа',
    'Document printout': 'Распечатка документа',
    'Document series and number': 'Серия и номер документа',
    'Document number': 'Номер документа',
    'Document type': 'Тип документа',
    'Documents': 'Документы',
    'Dont have photo': 'Нет фото',
    'Double entries': 'Проводки',
    'Double': 'Дубль',
    'Download': 'Загрузить',
    'Download balances at the beginning of the period': 'Скачать остатки на начало периода',
    'Dont have contract': 'Нет сделки',
    'Dynamic cost': 'Динамическая стоимость',

    'Edit': 'Редактировать',
    'Edited': 'Изменил',
    'Editing': 'Редактирование',
    'Edit the article': 'Редактировать статью',
    'Edit the category': 'Редактировать категорию',
    'Edit a Wiki tag': 'Редактировать Wiki тэг',
    'Email': 'Почта',
    'Employee internal phone': 'Внутренний телефон сотрудника',
    'End of period': 'Конец периода',
    'Enter a comment if necessary.': 'При необходимости введите комментарий.',
    'Enter into a contract': 'Заключить договор',
    'Enter the amount of payment for the appraisal of the mortgaged property. The assessment amount is taken from the client one-time at the first payment of interest.': 'Введите сумму оплаты за оценку закладываемого имущества. Сумма оценки берется с клиента разово при первой оплате процентов.',
    'Enter the delay period (grace period) after this period, the state of the pledged property will automatically change and it will be possible to sell it. If you enter 0, no late interest will be calculated and the condition of the pledged property will change immediately after the main loan term. If the term of the pledge exceeds the grace period, then the property can still be redeemed or re-mortgaged, and all days following the grace period will be calculated at the same percentage as the grace period.': 'Введите срок просрочки (льготного периода) по истечении этого срока автоматически поменяется состояние заложенного имущества и его можно будет продавать. Если вы введете 0, то проценты за просрочку рассчитываться не будут и состояние заложенного имущества сменится сразу после основного срока займа. Если срок залога превысит льготный период, то имущество все равно можно будет выкупить или перезаложить, а все последующие за льготным периодом дни будут рассчитываться по тем же процентам, что и льготный период.',
    'Enter the name of the tariff. It is better that the name reflects its purpose.': 'Введите название тарифа. Лучше чтобы название отражало его предназначение.',
    'Enter the minimum number of days of bail. If the property is redeemed or re-mortgaged earlier than the period specified in this field, the interest will be calculated for the period specified in this field. If you do not fill in this field, then rounding to the minimum number of days will not work.': 'Введите минимальное количество дней залога. Если имущество придут выкупать или перезакладывать раньше указанного в этом поле срока, то проценты будут рассчитываться за указанный в этом поле срок. Если не заполнить это поле, то округление до минимального количества дней работать не будет.',
    'Enter the storage charge per day.': 'Введите сумму оплаты хранения за день.',
    'Enter the value of the minimum payment amount. If the total amount calculated for this tariff is less than the value specified in this field, then the program will offer the value from this field. If you do not fill in this field, then rounding to the minimum amount will not work.': 'Введите значение минимальной суммы оплаты. Если итоговая сумма, рассчитанная по данному тарифу будет меньше указанного в этом поле значения, то программой будет предложено значение из этого поля. Если не заполнить это поле, то округление до минимальной суммы работать не будет.',
    'Enter your email': 'Введите email',
    'Enter your email and password': 'Введите свои email и пароль',
    'Enter your password': 'Введите пароль',
    'Entity': 'Юридическое лицо',
    'Error': 'Ошибка',
    'Erroneous': 'Ошибочный',
    'Essence': 'Сущность',
    'Evaluation fee': 'Плата за оценку',
    'Event GIIS DMDK': 'События ГИИС ДМДК',
    'Example: Password 1234, b/y etc.': 'Пример: Пароль 1234, б/у и т.д.',
    'Example: Powerful chip. Dual camera system. Incredibly durable panel': 'Пример: Мощный чип. Система двух камер. Невероятно прочная панель',
    'Exchange': 'Сменить',
    'Exit': 'Выход',
    'Exists': 'Есть',
    'Expense': 'Расход',
    'Expenses': 'Затраты',
    'Export': 'Выгрузить',
    'Export to 1C': 'Выгрузка в 1С',
    'Extensions': 'Продлений',
    'External link': 'Внешняя ссылка',
    'Extra price': 'Наценка',
    'Extradition': 'Выдача',

    'Fare Terms': 'Условия тарифа',
    'Female': 'Женский',
    'file': 'файл',
    'File': 'Файл',
    'Fill in all the properties (characteristics) of the product': 'Заполните все свойства (характеристики) изделия',
    'Fill in the department details before submitting!': 'Заполните данные департамента перед отправкой!',
    'Finance report': 'Финансовый отчет',
    'Financial and economic activities': 'Сведения о целях финансово-хозяйственной деятельности',
    'Financial condition': 'Финансовое состояние',
    'Financial position': 'Финансовое положение',
    'Fiscal check': 'Чек фискальный',
    'Fiscal accumulator': 'Фискальный накопитель',
    'Fixed cost': 'Фиксированная стоимость',
    'For franchise report': 'Отчет для франчайзи',
    'For example: 5000': 'Например: 5000',
    'Form': 'Форма',
    'Form for sending leads to CRM Karman24': 'Форма для отправки лидов в CRM Karman24',
    'Example: 0.5': 'Пример: 0,5',
    'For example: Ivanov Ivan Ivanovich': 'Например: Иванов Иван Иванович',
    'Foundation': 'Основание',
    'from': 'из',
    'from (beginning of day)': 'от (начало дня)',
    'From': 'Откуда',
    'FN': 'ФН',
    'Full': 'Полный',
    'Full label': 'Полное наименование',
    'Full Name': 'ФИО',
    'Full name of GIIS DMDC': 'Полное наименование ГИИС ДМДК',
    'Full name of director': 'ФИО директора',
    'Full name of chief accountant': 'ФИО главного бухгалтера',
    'Funnel': 'Воронка',
    'Funnel card': 'Карточка воронки',
    'Funnel stage': 'Этап воронки',
    'Funnel stages': 'Этапы воронки',
    'Funnels': 'Воронки',

    'Gender': 'Пол',
    'General office': 'Основной офис',
    'get UIN': 'Получить УИН',
    'Get the requested UINs': 'Получить запрошенные УИНы',
    'Getting UIN': 'Получение УИН',
    'GIIS number': 'Номер в ГИИС',
    'Go to': 'Перейти',
    'Go to transaction': 'Перейти в транзакцию',
    'Goods sent': 'Товары отправлены',
    'Gold in The Store': 'Золота в магазине',
    'Gold rate': 'Курс золота',
    'Grace period': 'Льготный период',
    'Grace period interest rate': 'Процентная ставка льготного периода',
    'Group': 'Группа',
    'Group source': 'Группа источника',
    'Groups': 'Группы',

    'Have photo': 'Есть фото',
    'Header': 'Заголовок',
    'hide': 'скрыть',
    'High': 'Высокий',
    'History': 'История',
    'Hidden Message': 'Скрытое сообщение',

    'ID': 'ID',
    'ID product': 'ID товара',
    'If the source is passed, the field is optional': 'Если передан источник - поле необязательное',
    'Images': 'Изображения',
    'Import buying': 'Импортная скупка',
    'Import contract commission': 'Импортный договор комиссии',
    'Import from smart': 'Импорт из СмартЛомбард',
    'Import type': 'Тип импорта',
    'Imported pledge tickets': 'Импортировано залоговых билетов',
    'Individual': 'Физическое лицо',
    'Information about the representative': 'Сведения о представителе',
    'In lead': 'В лид',
    'In progress': 'В процессе',
    'In process of treatment': 'В процессе обработки',
    'In this section, you can request a UIN for pawnshop items purchased at SmartPawnshop.': 'В данном разделе можно запросить УИН для изделий в ломбарде, заведенных в СмартЛомбарде.',
    'In sale': 'В продаже',
    'In stock': 'В наличии',
    'In work': 'В работе',
    'In-store sales (with scrap)': 'Продаж в магазине (без лома)',
    'Inactive': 'Неактивный',
    'Income': 'Доход',
    'Income Pawnshop': 'Доход Ломбард',
    'Income Commission Shop': 'Доход коммиссионка',
    'Incoming funds': 'Поступившие денежные средства',
    'Init': 'Подготовка',
    'Interest amount': 'Сумма процентов',
    'Interest income': 'Доход с процентов',
    'Internal phone': 'Внутренний телефон',
    'INN': 'ИНН',
    'INP': 'ИНП',
    'Installation address': 'Адрес установки',
    'Interest rate': 'Процентная ставка',
    'Interest repaid': 'Погашенные проценты',
    'Inventory': 'Инвентаризация',
    'Inventory result: surplus: count': 'Результат инвентаризации: излишек: количество',
    'Inventory result: surplus: money': 'Результат инвентаризации: излишек: сумма, руб. коп.',
    'Inventory result: shortage: count': 'Результат инвентаризации: недостача: количество.',
    'Inventory result: shortage: money': 'Результат инвентаризации: недостача: сумма, руб. коп.',
    'Inventory number': 'Инвентарный номер',
    'INV': 'ИНВ',
    'Invoice, article, order number': 'Номер счёта, статьи, заказа',
    'Involvement in extremism': 'Причастность к экстремизму',
    'Issued by': 'Кем выдан',
    'Ivan': 'Иван',
    'Ivanov': 'Иванов',
    'Ivanovich': 'Иванович',
    'It is necessary to fill in the section with goods': 'Необходимо заполнить раздел с товарами',
    'Items in the set': 'Товаров в наборе',
    'IT support': 'Техподдержка IT',
    'Is have documents': 'Наличие документов',
    'Is use in product name': 'Используется в названии товара',

    'Jewelry': 'Ювелирное изделие',

    'Kind of activity': 'Вид деятельности',
    'KKM commands': 'ККМ комманды',
    'KKM error': 'Ошибка кассы',
    'KKM register': 'Кассовый аппарат',
    'KKM sessions': 'Кассовые смены',
    'Kkt access': 'Доступ к кассе',
    'KKT ID': 'ID устройства',
    'KKT Session Id': 'ID сессии',
    'KPP': 'КПП',
    'Knowledge Base - CRM': 'База знаний - CRM',

    'Label': 'Наименование',
    'Label of document': 'Наименование документа',
    'Language': 'Язык',
    'Latest editor': 'Последний редактор',
    'Lead': 'Лид',
    'Lead #': 'Лид #',
    'Lead card': 'Карточка лида',
    'Lead name': 'Наименование лида',
    'Leads': 'Лиды',
    'Leads in Work': 'Лидов в работе',
    'List of Kkm': 'Список ККМ',
    'List of products': 'Список товаров',
    'List of wiki articles': 'Список wiki статей',
    'List of wiki categories': 'Список Wiki категорий',
    'List of wiki tags': 'Список Wiki тэгов',
    'Link to Avito': 'Ссылка на Авито',
    'LLC "Pawnshop "Karman 24"': 'ООО "Ломбард "Карман 24"',
    'Loan amount': 'Сумма займа',
    'Loan amount from': 'Сумма займа от',
    'Loan amount to': 'Сумма займа до',
    'Loan term, days': 'Срок займа, дней',
    'Loans': 'Займы',
    'Lombard': 'Ломбард',
    'Log in': 'Войти',
    'Login': 'Логин',
    'Loss from the sale of unclaimed property': 'Убыток от продажи невостребованного имущества',
    'Low': 'Низкий',

    'Male': 'Мужской',
    'Manually specified weight': 'Вес указанный вручную',
    'Matches': 'Совпадения',
    'Maturity date': 'Дата погашения',
    'Message': 'Сообщение',
    'Metal': 'Металл',
    'Metal type': 'Тип металла',
    'Metal weight': 'Вес металла',
    'Middle': 'Средний',
    'Minimum number of days': 'Минимальное количество дней',
    'Minimum number of days for accrual': 'Минимальное кол-во дней для начисления',
    'Minimum commission fee': 'Минимальное комиссионное вознаграждение',
    'Minimum selling price': 'Минимальная цена продажи',
    'Minimum reward amount': 'Минимальная сумма вознаграждения',
    'Missing': 'Отсутствует',
    'Mobile number': 'Мобильный номер',
    'Modtager': 'Получил',
    'Money in office': 'Наличные в кассе',
    'more': 'ещё',
    'More about the source': 'Дополнительно об источнике',
    'Move': 'Перемещение',
    'Moved': 'Перемещено',

    'Name': 'Имя',
    'Name and position of the employee': 'ФИО и должность сотрудника',
    'Name base': 'Имя базы',
    'Navigation': 'Навигация',
    'Net weight': 'Чистый вес',
    'New': 'Новый',
    'New leads': 'Новые лиды',
    'New leads passed in the deal': 'Новых лидов прошло в сделку',
    'New clients': 'Новые клиенты',
    'New password': 'Новый пароль',
    'Never mind': 'Неважно',
    'No': 'Нет',
    'No data': 'Нет данных',
    'No items to ship': 'Отсутствуют товары для отправки',
    'no UIN': 'УИН отсутствует',
    'Normal': 'Нормальный',
    'Not active': 'Неактив',
    'Not available': 'Нет в наличии',
    'Not exists': 'Нет',
    'Not found': 'Не найдено',
    'Not repaid on time...': 'Не погашены в установленный срок...',
    'Not specified': 'Не указано',
    'Note': 'Примечание',
    'Nothing found': 'Ничего не найдено',
    'Number check': 'Номер чека',
    'Number deal': 'Номер контракта',
    'Number in the information system of the participant': 'Номер в информационной системе участника',
    'Number of contract': 'Номер контракта',
    'Number of document': 'Номер документа',
    'Number of grace period days': 'Количество дней льготного периода',
    'Number of passport registration': 'Номер паспорта (документа о регистрации)',
    'Number of session': 'Номер смены',
    'Number of the foundation document': 'Номер документа основания',

    'of': 'из',
    'Office': 'Офис',
    'Office by contract': 'Офис по сделке',
    'Office extension number': 'Внутренний номер офиса',
    'Office renovation request': 'Заявка по ремонту офиса',
    'Offices': 'Офисы',
    'OGRN': 'ОГРН',
    'OKPD2': 'ОКПД2',
    'OKPO': 'ОКПО',
    'OKTMO': 'ОКТМО',
    'OKVED': 'ОКВЕД',
    'On receipt': 'На получении',
    'On registration': 'На оформлении',
    'Only their city': 'Своего города',
    'Only their office': 'Своего офиса',
    'Only their own': 'Только свои',
    'Opened': 'Открыл',
    'Operation date': 'Дата операции',
    'Opened at': 'Дата открытия',
    'Open for collection': 'Открыт для сбора',
    'Open shift': 'Открыть смену',
    'Operational report': 'Оперативный отчет',
    'Operations': 'Операции',
    'Operation Id': 'ID операции',
    'Operation already created': 'Операция уже создана',
    'Operation available for import': 'Операция доступна для импорта',
    'Operational serial number': 'Серийный номер операции',
    'Operations product': 'Операции товара',
    'Operation type': 'Тип операции',
    'Operator cancellation': 'Отмена оператором',
    'Organization': 'Организация',
    'Organization type': 'Тип организации',
    'Organizations': 'Организации',
    'Origin of funds': 'Происхождения денежных средств',
    'Other contact information': 'Иная контактная информация',
    'Other documents': 'Прочие документы',
    'Other information': 'Иные сведения',
    'Outstanding balance at the end of the period after the grace period': 'Непогашенный остаток на конец периода после льготного срока',
    'Outstanding balance at the end of the period in the grace period': 'Непогашенный остаток на конец периода в льготный срок',
    'Owner': 'Ответственный',
    'owner': 'ответственный',

    'Paid': 'Оплачено',
    'Paid out': 'Выплачено',
    'Paid up': 'Оплачено',
    'Params of Categories': 'Характеристики категорий',
    'Parent param': 'Родительская категория',
    'Parent category': 'Родительская категория',
    'Partial payment': 'Частичная оплата',
    'Passed the test': 'Прошли проверку',
    'Password': 'Пароль',
    'Password cannot be less than 6 characters': 'Пароль не может быть короче 6 символов',
    'Patronymic': 'Отчество',
    'Percent': 'Процент',
    'Params': 'Характеристики',
    'Payment method': 'Способ оплаты',
    'Payment schedule': 'График платежей',
    'Payments': 'Платежи',
    'Percent profit': 'Процентный доход',
    'Percents': 'Проценты',
    'Permission': 'Разрешение',
    'Permissions': 'Разрешения',
    'Person': 'Клиент',
    'Persona': 'Сотрудник',
    'Personal': 'Сотрудники',
    'Persons': 'Клиенты',
    'Period': 'Период',
    'Period of validity': 'Период действия',
    'Person card': 'Карточка контакта',
    'Photo': 'Фото',
    'Phone': 'Телефон',
    'Phone work': 'Рабочий телефон',
    'Phone inside': 'Внутренний телефон',
    'Place of Birth': 'Место рождения',
    'Poor client': 'Некачественный клиент',
    'Position': 'Должность',
    'Postpone the appeal': 'Отложить обращение',
    'Post address': 'Почтовый адрес',
    'Postponed': 'Отложено',
    'Price': 'Стоимость',
    'Price for gram': 'Цена за грамм',
    'Price tag': 'Бирка',
    'Print': 'Распечатать',
    'Print documents': 'Распечатать документы',
    'Print out': 'Распечатать',
    'Print forms': 'Печатные формы',
    'Print the form': 'Распечатать анкету',
    'Print selection': 'Выбор печати',
    'Printing price': 'Печать ценников',
    'Printing price tags for equipment': 'Печать ценников для техники',
    'Printing price tags for jewelry': 'Печать ценников для ювелирного изделия',
    'Priority': 'Приоритет',
    'Pre filling text': 'Текст для предзаполнения',
    'Problem ticket in CRM': 'Заявка по проблеме в CRM',
    'Product': 'Товар',
    'Product already created': 'Товар уже создан',
    'Product available for import': 'Товар доступен для импорта',
    'product': 'товар',
    'Product ID': 'ID Товара',
    'Product pack': 'Комплект товаров',
    'Products': 'Товары',
    'Products in The Store': 'Товаров в магазине',
    'Profile': 'Профиль',
    'Profit': 'Прибыль',
    'Profit gold': 'Доход с золота',
    'Profit tech': 'Доход с техники',
    'Properties': 'Свойства',
    'Property': 'Имущество',
    'Provided': 'Оказана',
    'Provider': 'Провайдер',
    'Provided a service': 'Оказал услугу',
    'PSK type': 'Тип ПСК',
    'Public phone': 'Публичный телефон',
    'Purchase tech': 'Скупок техники',
    'Purchase gold': 'Скупок золота',
    'Purchase transactions': 'Транзакции покупки',
    'purchased goods': 'скупленный товар',

    'QR code': 'QR код',
    'Quantity': 'Количество',

    'Ransom amount': 'Сумма выкупа',
    'Reading': 'Чтение',
    'Ready for deal': 'Готов к сделке',
    'Ready for implementation': 'Готов к реализации',
    'Realization profit': 'Доход от реализации',
    'Reason': 'Основание',
    'Reason for error?': 'Причина ошибки?',
    'Reason for write-off?': 'Причина списания?',
    'Recipient ID': 'Идентификатор получателя',
    'Receipt': 'Приход',
    'Receipt of finished products': 'Поступление готовой продукции',
    'Receipt type': 'Тип чека',
    'Receipt types': 'Типы чеков',
    'Received': 'Получен',
    'Receivers contract number': 'Номер контракта получателя',
    'Recommended sale price': 'Рекомендованная цена продажи',
    'Recommended selling price for gold': 'Рекомендованная цена продажи золота',
    'Recommended price for accepting gold': 'Рекомендованная цена приема золота',
    'Redeemed': 'Погашено',
    'Redemption cost': 'Стоимость выкупа',
    'Refresh': 'Обновить',
    'Registration address': 'Адрес регистрации',
    'Registration date': 'Дата регистрации',
    'Regrading': 'Пересортица',
    'Rejection reason': 'Причина отказа',
    'Relation degree': 'Степень родства',
    'Remortgage': 'Перезалог',
    'Rendered': 'Оказана',
    'Renewal': 'Продление',
    'Renewal/Partial payment': 'Продление/Частичная оплата',
    'Repaid borrowed funds': 'Погашенные заемные средства',
    'Reports': 'Отчеты',
    'Reports for the Central Bank of Russia': 'Отчеты для Центрального Банка России',
    'Report for the ros fin monitoring': 'Отчеты для Росфинмониторинга',
    'Responsible': 'Ответственный',
    'Responsible default': 'Ответственный по умолчанию',
    'Response parameters': 'Параметры ответа',
    'Retail': 'Розничная продажа',
    'Retail detail': 'Детализация розничной продажи',
    'Retail sales report': 'Отчет розничная продажа',
    'Return': 'Вернуть',
    'Return from store': 'Вернуть из магазина',
    'Return to store': 'Вернуть в магазин',
    'Result': 'Результат',
    'Results': 'Результаты',
    'Request': 'Запрос',
    'Request for UIN sent': 'Запрос на получения УИН-а отправлен',
    'Request UIN': 'Запросить УИН',
    'Required': 'Обязательный для заполнения',
    'Revenue from sales': 'Доход с реализации',
    'Reward': 'Вознаграждение',
    'Roistat': 'Ройстат',
    'Role': 'Роль',
    'Roles': 'Роли',
    'Rounding percentages as in smart': 'Округление процентов как в Смарте',
    'Rollback': 'Откат',
    'Rounding up the pledge period': 'Округление срока залога',

    'Sale': 'Продажа',
    'Sale by': 'Продал',
    'Sale deal': 'Продажа услуги',
    'Sale date product pack': 'Дата продажи лома',
    'Sale date product to IE': 'Дата продажи в ИП',
    'Sale lombard': 'Продажа ломбард',
    'Sale (GIIS DMDC)': 'Продажа (ГИИС ДМДК)',
    'Sale (Redemption)': 'Продажа (Выкуп)',
    'Sale of goods': 'Продажа товара',
    'Sale of pack': 'Продажа лома',
    'Sale to IE': 'Продажа в ИП',
    'Sales transactions': 'Транзакции продажи',
    'Save': 'Сохранить',
    'Sample': 'Проба',
    'Scrap': 'Лом',
    'Search': 'Поиск',
    'Search by lombard': 'Поиск по ломбарду',
    'Section for sending data on the retail sale of pawnshop jewelry held in SmartPawnshop.': 'Раздел для отправки данных о розничной продажи ювелирных изделий ломбарда, проведенных в СмартЛомбарде.',
    'See all': 'Посмотреть все',
    'Sell': 'Продать',
    'Selling gold to the plant (pawn)': 'Продажа золота на завод (лом)',
    'Selling price': 'Цена продажи',
    'Send': 'Отправить',
    'Sent': 'Отправлен',
    'Sent to DMDC': 'Отправлено в ДМДК',
    'Send a comment': 'Отправить комментарий',
    'Send an aggregated batch?': 'Отправить агрегированную партию?',
    'Send to Ascort': 'Отправить в Аскорт',
    'Sender': 'Отправил',
    'Send a batch of sale': 'Отправить партию продажи',
    'Send to API': 'Отправлять по API',
    'Send body to Telegram': 'Отправлять контент тикета в Телеграм',
    'Sender ID': 'Идентификатор отправителя',
    'Session': 'Кассовая смена',
    'Session is open': 'Кассовая смена открыта',
    'Session is close': 'Кассовая смена закрыта',
    'Serial number': 'Порядковый номер',
    'Server': 'Сервер',
    'Service': 'Услуга',
    'Services': 'Услуги',
    'Services count': 'Кол-во услуг',
    'Set as main document': 'Сделать основным документом',
    'Service cost': 'Стоимость услуги',
    'Service list': 'Перечень услуг',
    'Services profit': 'Доход c услуг',
    'Selling gold': 'Продажа золота',
    'Show': 'Показать',
    'Show wiki tag': 'Просмотр Wiki тэга',
    'Short name': 'Короткое имя',
    'Shortage': 'Недостача',
    'Show transaction': 'Показать транзакцию',
    'Show only acquiring': 'Показать только эквайринг',
    'Showcase price': 'Цена на витрине',
    'Showing': 'Показано',
    'Shift': 'Касса',
    'Shipper': 'Грузоотправитель',
    'Sign of the calculation method': 'Признак способа расчета',
    'Skip passport check': 'Пропустить проверку паспорта',
    'Sold': 'Продан',
    'Source': 'Источник',
    'Source subcategory': 'Подкатегория источника',
    'Sources': 'Источники',
    'Sort index': 'Индекс сортировки',
    'Sorting number': 'Порядок сортировки',
    'Specifications': 'Спецификации',
    'Specification': 'Спецификация',
    'SNILS': 'СНИЛС',
    'Specified in days. For example: 30': 'Указывается в днях. Например: 30',
    'Stage of funnel card': 'Карточка стадии воронки',
    'Standart': 'Проба',
    'Start of period': 'Начало периода',
    'Start transaction': 'Начать транзакцию',
    'Source Statistics': 'Статистика источников',
    'State contract': 'Состояние контракта',
    'Status': 'Статус',
    'Status lead': 'Статус лида',
    'Stay on the page or go to the navigation set?': 'Остаться на странице или перейти в набор перемещения?',
    'Store': 'Магазин',
    'Storage': 'Место хранения',
    'Storages': 'Места хранения',
    'Storage fee per day': 'Плата за хранение в день',
    'Subdivision': 'Подразделение',
    'Subcategories': 'Подкатегории',
    'Subdivisions': 'Подразделения',
    'Subtotal': 'Итого',
    'Success': 'Успешно',
    'Successful bid': 'Успешных заявок',
    'Sum': 'Сумма',
    'Sum, rub. penny': 'Сумма, руб. коп',
    'Surname': 'Фамилия',
    'Surplus': 'Излишек',
    'Sms chat': 'Чат',
    'Sms list': 'Список уведомлений',

    'Table': 'Таблица',
    'Tariff': 'Тариф',
    'Tags': 'Тэги',
    'Tariffs': 'Тарифы',
    'Taxation system': 'Система налогообложения',
    'Technical support': 'Техническая поддержка',
    'Tech support employee': 'Сотрудник тех. поддержки',
    'Telegram group id': 'ID группы Telegram',
    'Telegram bot id': 'ID бота Telegram',
    'Template ID': 'ID Шаблона',
    'Template expiry date': 'Срок действия шаблона от',
    'Template expiry time': 'Срок действия шаблона время от',
    'Template valid until': 'Срок действия шаблона до',
    'Template valid time': 'Срок действия шаблона время до',
    'Term': 'Срок',
    'Term of the contract': 'Срок действия договора',
    'Test': 'Тестовый',
    'Text snippet': 'Текст (cниппет)',
    'The actual address matches the registration address': 'Фактический адрес совпадает с адресом регистрации',
    'The article has not been prepared yet': 'Статья еще не подготовлена',
    'The balance at the start of the session': 'Остаток на начало смены',
    'The balance at the end of the session': 'Остаток на конец смены',
    'The balance of funds from the sale of unclaimed property': 'Остаток денежных средств от продажи невостребованного имущества',
    'The basis for correction': 'Основание для коррекции',
    'The check number is missing. Enter manually': 'Отсутствует номер чека. Впишите вручную',
    'The contract': 'Контракт',
    'The contracts': 'Контракты',
    'The contract is closed': 'Договор закрыт',
    'The contract is concluded': 'Договор заключен',
    'The cost specified manually': 'Стоимость указанная вручную',
    'The current series of Z.B.': 'Текущая серия З.Б.',
    'The day is over': 'День закончился',
    'The documents': 'Документы',
    'The email field is required.': 'Почта - обязательное поле',
    'The email format is invalid.': 'Неверный формат почты',
    'The final shortage': 'Окончательные недостачи',
    'The final surplus': 'Приходуются окончательные излишки',
    'The first stage': 'Первая стадия',
    'The phone field is required.': 'Телефон - обязательное поле',
    'The phone format is invalid.': 'Неверный формат номера',
    'The phone must be at least 11 characters.': 'Минимум 11 символов',
    'The term has expired, but the obligations have not been extinguished...': 'Истёк срок, но обязательства не погашены...',
    'The last issue of Z.B.': 'Последний номер З.Б.',
    'The limit value of the TFR established by the CBR': 'Предельное значение ПСК установленного ЦБР',
    'The sign of calculation': 'Признак расчета',
    'The UIN has not yet been assigned to the product in the GIIS DMKD. try later': 'УИН еще не присвоен для изделия в ГИИС ДМКД. Попробуйте позже',
    'There are no goods to receive UIN': 'Отсутствуют товары для получения УИН-а',
    'This is a required field': 'Это обязательное поле',
    'this must be a valid email': 'Введите корректный email',
    'To': 'Куда',
    'To IE': 'В ИП',
    'to open': 'Открыть',
    'To pawn': 'В лом',
    'To stay': 'Остаться',
    'Ticket type': 'Тип обращения',
    'Title': 'Заголовок',
    'Title of category': 'Название категории',
    'Time offset': 'Время смещения',
    'To the store': 'В магазин',
    'To using': 'В пользование',
    'Today': 'Сегодня',
    'Total': 'Всего',
    'Total actives': 'Общий актив',
    'Total checked': 'Всего проверено',
    'Total clients': 'Всего клиентов',
    'Total operations': 'Всего операций',
    'Total products': 'Всего товаров',
    'Total gold sales': 'Сумма продаж золота',
    'Total income': 'Общий доход',
    'Total weight': 'Общий вес',
    'Total cost': 'Общая себестоимость',
    'Total tech sales': 'Сумма продаж техники',
    'Total contracts': 'Всего сделок',
    'Total errors contracts': 'Сделок с ошибками',
    'Total successful imported contracts': 'Импортировано успешно сделок',
    'Total doubles contracts': 'Дублированные сделки',
    'Total doubles operations': 'Дублированные операции',
    'Token description': 'Описание токенов',
    'Tokens': 'Токены',
    'Transaction': 'Транзакция',
    'Transaction basis': 'Основа транзакции',
    'Transaction start': 'Начало транзакции',
    'Transactions': 'Транзакции',
    'Transfer': 'Переместить',
    'Transfer as give and take raw materials': 'Передать как давальческое сырье',
    'Transfer of products': 'Перенос товаров',
    'Type': 'Тип',
    'Type of metal': 'Вид металла',
    'Type of correction': 'Тип коррекции',
    'Type of percent calculation': 'Тип расчета процентов',

    'UIN': 'УИН',
    'UIN for the product received': 'УИН для изделия получен',
    'UIN received': 'УИН получен',
    'UIN request is not possible': 'Запрос УИН-а не возможен',
    'UIT': 'УИТ',
    'Unclaimed Products': 'Невостребованные товары',
    'Under decision': 'В стадии решения',
    'Units of measurement of the grace period': 'Единицы измерения льготного периода',
    'Unique product identifier': 'Уникальный идентификатор товара',
    'Update': 'Редактирование',
    'Update date': 'Дата обновления',
    'Date of updating the list of terrorists': 'Дата обновления списка террористов',
    'Date the FROM list was updated': 'Дата обновления списка ФРОМУ',
    'Date of updating the list of MVK': 'Дата обновления списка МВК',
    'Update information': 'Уточнение информации',
    'Updated': 'Обновил',
    'updated': 'обновил',
    'Updating': 'Изменение',
    'Upload': 'Загрузить',
    'up to (inclusive)': 'по (включительно)',
    'User': 'Пользователь',
    'Users': 'Пользователи',
    'Username': 'Имя',
    'Users report': 'Отчет по сотрудникам',
    'Detail users report': 'Детальный отчет по сотрудникам',

    'Validity': 'Срок действия',
    'Valid with': 'Действует с',
    'Valid until': 'Действует до',
    'Validate error': 'Ошибка валидации',
    'VAT': 'НДС',
    'Vendor ID': 'Идентификатор поставщика',
    'Via plugin': 'Через плагин',
    'Views': 'Вид',
    'View': 'Просмотр',

    'Waiting acceptance': 'Ожидает принятия',
    'Waiting for UIN': 'Ожидание УИН-а',
    'Weight': 'Вес',
    'Without receipt printing': 'Без печати чека',
    'Will apply to the selected category and subcategories': 'Будет применяться к выбранной категории и подкатегориям',
    'Write off': 'Списать',
    'Write-off (Disposal)': 'Списание (Выбытие)',
    'Write-off (Mistake)': 'Списание (Ошибка)',
    'Wrong UIN': 'Ошибочный УИН',

    'X report': 'Х отчет',
    'X The report is printed out': 'X отчет распечатан',

    'Yes': 'Да',
    'You did not enter a lead contact': 'Вы не указали контакт лида',
}