<template>
  <SpeedDial showIcon="pi pi-file-excel" class="custom-speeddial-right3" @click="exportExcel"></SpeedDial>
</template>

<script>
import SpeedDial from 'primevue/speeddial';

export default {
  props: {
    exportExcel: Function,
  },
  components: {
    SpeedDial,
  },
};
</script>