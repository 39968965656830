<template>
  <li
    v-if="hasKkt === true"
    :class="
      'topbar-kkm-block ' +
      (kkmKktService.getIsSessionOpenGood()
        ? 'topbar-kkm-block-open'
        : kkmKktService.sessionState === null
        ? ''
        : 'topbar-kkm-block-close')
    "
    class="dropdown notification-list"
  >
    <BlockUI class="dropdown-toggle arrow-none" :blocked="isBlocked" data-bs-toggle="dropdown">
      <div aria-expanded="false" aria-haspopup="false" class="nav-link py-2 topbar-kkm-link" role="button">
        <span class="text-center fs-6"
          ><i
            v-if="kkmKktService.isPlugin"
            class="fa fa-puzzle-piece js-kkm-plugin-i me-1"
            title="Установите плагин"
          ></i>
          <span v-if="kkmKktService.session && kkmKktService.session.isNewDay"
            >Кассовая смена открыта<br />но день закончился</span
          >
          <span v-else-if="kkmKktService.sessionState == 2">Кассовая смена<br />открыта</span>
          <span v-else-if="kkmKktService.sessionState == 3">Кассовая смена открыта,<br />но закончилась</span>
          <span v-else-if="kkmKktService.sessionState">Кассовая смена<br />закрыта</span>
          <span v-else>Кассовая<br />смена</span>
        </span>
      </div>
    </BlockUI>
    <div class="dropdown-menu dropdown-menu-end dropdown-menu-animated topbar-dropdown-menu">
      <a class="dropdown-item notify-item text-center" href="#" @click.prevent="onSessionToggle()">
        <span v-if="kkmKktService.getIsSessionOpen()">{{ $t('Close shift') }}</span>
        <span v-else>{{ $t('Open shift') }}</span>
      </a>
      <router-link
        v-if="kkmKktService.getIsSessionOpenGood()"
        class="dropdown-item notify-item text-center"
        :to="{ name: 'KkmPaymentTransactionCreate' }"
      >
        {{ $t('Expense') }}
      </router-link>
      <router-link
        v-if="kkmKktService.getIsSessionOpenGood()"
        class="dropdown-item notify-item text-center"
        :to="{ name: 'KkmDepositingTransactionCreate' }"
      >
        {{ $t('Receipt') }}
      </router-link>
      <a
        v-if="kkmKktService.getIsSessionOpenGood()"
        class="dropdown-item notify-item text-center"
        @click.prevent="correctionModalDisplay = !correctionModalDisplay"
      >
        <span>{{ $t('Correction check') }}</span>
      </a>
      <a class="dropdown-item notify-item text-center" href="#" @click.prevent="printXreport">
        <span>{{ $t('X report') }}</span>
      </a>
    </div>
  </li>

  <Dialog :closable="false" :visible="correctionModalDisplay">
    <template #header>
      <h3>{{ $t('Correction check') }}</h3>
    </template>

    <Form :actions="null" :service="kkmKktService">
      <FormField
        :label="$t('The sign of calculation')"
        :options="[
          { id: 2, name: 'Корректировка прихода' },
          { id: 12, name: 'Корректировка расхода' },
        ]"
        :service="kkmKktService"
        field="TypeCheck"
        type="select"
      />
      <FormField
        :label="$t('Type of correction')"
        :options="[
          { id: 0, name: 'Самостоятельно' },
          { id: 1, name: 'По предписанию' },
        ]"
        :service="kkmKktService"
        field="CorrectionType"
        type="select"
      />
      <FormField :label="$t('The basis for correction')" :service="kkmKktService" field="CorrectionBaseName" />
      <FormField :label="$t('Correction date')" :service="kkmKktService" field="CorrectionBaseDate" type="calendar" />
      <FormField
        :label="$t('Number of the foundation document')"
        :service="kkmKktService"
        field="CorrectionBaseNumber"
      />
      <FormField :label="$t('Amount')" :service="kkmKktService" type="number" field="amount" />
    </Form>

    <template #footer>
      <Button
        class="p-button-text"
        icon="pi pi-times"
        :label="$t('Cancel')"
        @click="correctionModalDisplay = !correctionModalDisplay"
      />
      <Button autofocus icon="pi pi-check" :label="$t('Send')" @click="addCorrectionCheck" />
    </template>
  </Dialog>
</template>

<script>
import BlockUI from 'primevue/blockui';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Form from '../../../components/ui/form/Form.vue';
import FormField from '../../../components/ui/form/FormField.vue';
import KkmKktService from '../../kkm/services/KkmKktService';
import KkmCommandService from '../../kkm/services/KkmCommandService';
import KkmSessionService from '../../kkm/services/KkmSessionService';

export default {
  components: {
    Dialog,
    Button,
    Form,
    FormField,
    BlockUI,
  },
  props: {
    hasKkt: Boolean,
  },
  data() {
    return {
      kkmKktService: new KkmKktService(),
      kkmSessionService: new KkmSessionService({ expand: ['prepareRequest'] }),
      correctionModalDisplay: false,
      isBlocked: false,
    };
  },
  emits: ['change'],
  async mounted() {
    await this.loadKkm();
  },
  methods: {
    async addCorrectionCheck() {
      await this.kkmKktService.addCorrectionCheck().then((response) => {
        if (response.data.error) {
          this.$toast.error({ detail: response.data.error });
        } else {
          this.correctionModalDisplay = !this.correctionModalDisplay;

          this.$toast.success({ detail: this.$t('Correction check is printed') });
        }
      });
    },
    async printXreport() {
      let kkmCommandService = new KkmCommandService();

      if (await kkmCommandService.getRequestAndExecute('XReport')) {
        await kkmCommandService.save();
      }

      if (kkmCommandService.getError()) {
        this.$toast.error({ detail: kkmCommandService.getError() });
      }
    },
    async loadKkm() {
      let kkmCommandService = new KkmCommandService();

      if (await kkmCommandService.getRequestAndExecute('GetDataKKT')) {
        await this.kkmKktService.current(kkmCommandService.response);

        if (this.kkmKktService.getError()) {
          this.$toast.error({ detail: this.kkmKktService.getError() });
        } else {
          // Если номера не совпадают, то это новая или другая касса и нужно перезаписать данные
          let kktNumber =
            kkmCommandService.response && kkmCommandService.response.Info
              ? kkmCommandService.response.Info.KktNumber
              : null;
          if (kktNumber && kktNumber != this.kkmKktService.kktNumber) {
            let data = await kkmCommandService.getDataKktFromList(kktNumber);
            if (data) {
              this.kkmKktService.info = data;
              this.kkmKktService.save();
            }
          }
        }

        this.$store.commit('kkm/setSession', this.kkmKktService.session);
      }

      if (kkmCommandService.getError()) {
        this.$toast.error({ detail: kkmCommandService.getError() });
      }
    },
    async onSessionToggle() {
      this.isBlocked = true;

      if (confirm(this.kkmKktService.getIsSessionOpen() ? 'Закрыть кассовую смену' : 'Открыть кассовую смену')) {
        let kkmCommandService = new KkmCommandService();

        if (this.kkmKktService.getIsSessionOpen()) {
          if (await kkmCommandService.getRequestAndExecute('CloseShift')) {
            if (await kkmCommandService.save()) {
              await this.kkmSessionService.close();

              if (this.kkmSessionService.getError()) {
                this.$toast.error({ detail: this.kkmSessionService.getError() });
              } else {
                this.$toast.success({ detail: this.$t('Session is close') });

                delete localStorage.rkoList;
                delete localStorage.pkoList;

                await this.loadKkm();
              }
            }
          } else {
            // Обновляем данные ккм, даже при ошибке закрытия, т.к. бывает, что касса отвечает, что уже закрыта, тогда надо обновить состояние
            await this.loadKkm();
          }
        } else {
          if (await kkmCommandService.getRequestAndExecute('OpenShift')) {
            if (await kkmCommandService.save()) {
              await this.kkmSessionService.open(kkmCommandService.response);

              if (this.kkmSessionService.getError()) {
                this.$toast.error({ detail: this.kkmSessionService.getError() });
              } else {
                this.$toast.success({ detail: this.$t('Session is open') });

                await this.loadKkm();
              }
            }
          } else {
            // Обновляем данные ккм, даже при ошибке открытия, т.к. бывает, что касса отвечает, что уже открыта, тогда надо обновить состояние
            await this.loadKkm();
          }
        }

        if (kkmCommandService.getError()) {
          this.$toast.error({ detail: kkmCommandService.getError() });
        }
      }

      this.isBlocked = false;
    },
  },
};
</script>

<style>
.p-dialog {
  width: 500px;
}
</style>