import Service from '../../../services/Service';
import http from "../../../utils/http-common";

class UserService extends Service {

    basePath = 'user';

    newObject() {
        return new UserService();
    }

    async getUserKktsGroupedByDepartments(userId) {
        return await http.get('/' + this.basePath + '/get-user-kkts-grouped-by-departments/' + userId, {params: this.prepareRequest()}).then(response => {
            return response.data.data;
        }).catch(e => this.catchResponse(e));
    }

    async updateDepartmentId() {
        return await http.put('/' + this.basePath + '/update-department-id', this.prepareRequest()).then(response => {
            return response.data.data;
        }).catch(e => this.catchResponse(e));
    }

    async allSupportsList() {
        return await http.get('/' + this.basePath + '/supports-list').then(response => {
            return response.data.data;
        }).catch(e => this.catchResponse(e));
    }

    async allUsersList() {
        return await http.get('/' + this.basePath + '/all').then(response => {
            return response.data.data;
        }).catch(e => this.catchResponse(e));
    }

    async allGroupListForMenu() {
        return await http.get('/' + this.basePath + '/menu-item').then(response => {
            return response.data;
        }).catch(e => this.catchResponse(e));
    }

}

export default UserService;