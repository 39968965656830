class Arr {

    /**
     * Get sum of elements
     * @param {Array} arr
     * @param {string} key
     * @returns imt
     */
    sum(arr, key) {
        let sum = 0;

        for (let i in arr) {
            if (key) {
                sum += parseFloat(arr[i][key]);
            } else {
                sum += parseFloat(arr[i]);
            }
        }

        return parseFloat(sum.toFixed(2));
    }
}

export default new Arr();