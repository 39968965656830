import Service from '../../../services/Service';
import http from '../../../utils/http-common';

class DepartmentService extends Service {

    basePath = 'organization/department';

    newObject() {
        return new DepartmentService();
    }

    async getGroupedDepartmentsList() {
        return await http.get('/' + this.basePath + '/get-grouped-departments-list', { params: this.prepareRequest() }).then(response => {
            return response.data.data;
        }).catch(e => this.catchResponse(e));
    }

    async allCommissionList() {
        return await http.get('/' + this.basePath + '/all-commission-list', { params: this.prepareRequest() }).then(response => {
            return response.data.data;
        }).catch(e => this.catchResponse(e));
    }

    async getGroupedDepartmentsListForUser() {
        return await http.get('/' + this.basePath + '/get-grouped-departments-list-for-user', { params: this.prepareRequest() }).then(response => {
            return response.data.data;
        }).catch(e => this.catchResponse(e));
    }

    async getAllDepartments() {
        return await http.get('/' + this.basePath + '/all', { params: this.prepareRequest() }).then(response => {
            return response.data.data;
        }).catch(e => this.catchResponse(e));
    }

    async getAvailable() {
        return await http.get('/' + this.basePath + '/available', { params: this.prepareRequest() }).then(response => {
            return response.data.data;
        }).catch(e => this.catchResponse(e));
    }
    
}

export default DepartmentService;