<template>
  <FormField
    v-if="edit && (edit.type === 'switch' || editMode !== 'show')"
    :service="data"
    :field="edit.field || col.field"
    :type="edit.type"
    :options="edit.options"
    :optionValue="edit.optionValue"
    :optionLabel="edit.optionLabel"
    :trueValue="edit.trueValue"
    :falseValue="edit.falseValue"
    :class="this.class"
    @change="edit.onChange ? edit.onChange(data, edit.field || col.field) : onChangeEdit(data, edit.field || col.field)"
    @click="$emit('click')"
    margin="0"
  />
  <div v-else-if="col.type === 'collection'">
    <Inplace v-if="colContent && colContent.length" :active="colContent.length > 2 ? data._collectionActive : true">
      <template #display>
        <div v-for="(option, index) in colContent" :key="index">
          <template v-if="index < 2">
            <slot v-if="col.slot" :data="option"></slot>
            <template v-else>{{ option[col.optionLabel || 'name'] }}</template>
          </template>
        </div>
        <span class="text-muted">...{{ $t('more') }} {{ colContent.length - 2 }}</span>
      </template>
      <template #content>
        <div v-for="option in colContent" :key="option">
          <slot v-if="col.slot" :data="option"></slot>
          <template v-else>{{ option[col.optionLabel || 'name'] }}</template>
        </div>
        <a href="#" v-if="colContent.length > 2" @click="data._collectionActive = false">
          <i class="pi pi-arrow-up"></i> {{ $t('hide') }}
        </a>
      </template>
    </Inplace>
  </div>
  <slot v-else-if="col.slot" :class="this.class" :data="data" @click="$emit('click')"></slot>
  <ActionBtn
    v-else-if="col.actions"
    :class="this.class"
    :actions="col.actions"
    :service="data"
    @load="$emit('load')"
    @click="$emit('click')"
    @afterClick="$emit('afterClick')"
  />
  <Link v-else-if="col.to" :class="this.class" :to="col.to" :data="data" @click="$emit('click')">
    {{ colContent }}
  </Link>
  <div v-else :class="this.class + ' ' + (edit ? 'custom-underline' : '')" @click="$emit('click')">
    <span v-if="col.format === 'money'">{{ colContent !== undefined ? colContent : emptyText }}</span>
    <span v-else>{{ colContent || emptyText }}</span>
  </div>
</template>

<script>
import Inplace from 'primevue/inplace';
import FormField from './form/FormField.vue';
import Link from './Link.vue';
import ActionBtn from './ActionBtn.vue';

export default {
  components: {
    Inplace,
    FormField,
    Link,
    ActionBtn,
  },
  props: {
    col: Object,
    data: Object,
    editMode: String,
    class: String,
  },
  emits: ['load', 'click', 'afterClick'],
  computed: {
    value() {
      // Берем значение, если оно составное, т.е. передано как массив из вложенных ключек к переменной элемента сервиса
      if (typeof this.col.field === 'object') {
        let value = this.data;

        for (let f of this.col.field) {
          if (value[f] === undefined) {
            return null;
          }
          value = value[f];
        }

        return value;
      }

      return this.data[this.col.field];
    },
    colValue() {
      // Берем форматированное значение
      return this.$str.format(this.col.value ? this.col.value(this.data) : this.value, this.col.format);
    },
    colContent() {
      // Берем указанный контент, который оборачивает форматированное ранее значение
      return this.col.content ? this.col.content(this.data, this.colValue) : this.colValue;
    },
    edit() {
      return typeof this.col.edit === 'function' ? this.col.edit(this.data) : this.col.edit;
    },
    emptyText() {
      return typeof this.col.emptyText === 'function' ? this.col.emptyText(this.data) : this.col.emptyText;
    },
  },
  methods: {
    async onChangeEdit(data, field) {
      if (typeof field === 'object') {
        await data.save([field[0]]);
      } else {
        await data.save([field]);
      }

      if (data.getError()) {
        this.$toast.error({ detail: data.getError() });
      }
    },
  },
};
</script>
