// Этот класс нужен чтобы переопределять некоторые заданные переменные в .env в исключительных случаях
class Env {

    /**
     * Get item from .env
     * @param {string} key 
     * @returns 
     */
    getItem(key) {
        if (key === 'VUE_APP_API_URL' && !process.env[key]) {
            return this.getApiUrl();
        }

        return process.env[key];
    }

    /**
     * Get Api url
     */
    getApiUrl() {
        // @todo: пока по домену с которого заходим, далее переделать на данные кэш-доменов, т.к. они могут быть разные
        let origin = window.location.origin;

        let originA = origin.split('.');
        originA[0] += '-api';

        return originA.join('.');
    }

}

export default new Env();