<template>
  <!-- ========== Left Sidebar Start ========== -->
  <div class="leftside-menu">
    <!-- LOGO -->
    <router-link v-if="!isAccount" class="logo text-center logo-light" :to="{ name: 'Home' }">
      <span :class="'logo-lg' + (isTest ? ' logo-test' : '')">
        <!-- <img src="/assets/logo-test1.png" alt="" height="16" /> -->
      </span>
      <span class="logo-sm"> K24 <small class="text-danger" v-if="isTest">тест</small> </span>
    </router-link>

    <!-- LOGO -->
    <router-link v-if="!isAccount" class="logo text-center logo-dark" :to="{ name: 'Home' }">
      <span class="logo-lg">
        <!-- <img src="assets/images/logo-dark.png" alt="" height="16" /> -->
      </span>
      <span class="logo-sm">
        <!-- <img src="assets/images/logo_sm_dark.png" alt="" height="16" /> -->
      </span>
    </router-link>

    <div class="h-100" id="leftside-menu-container" data-simplebar>
      <!--- Sidemenu -->
      <ul class="side-nav">
        <li class="side-nav-item" v-for="(item, index) in this.menuItems" :key="item">
          <a v-if="!item.items && item.url" class="side-nav-link" :href="item.url">
            <i class="fa text-center" :class="'fa-' + item.icon"></i>
            <span>{{ item.label }}</span>
          </a>
          <a
            data-bs-toggle="collapse"
            v-if="item.items && !item.routeName"
            :href="'#sidebar' + index"
            aria-expanded="false"
            :aria-controls="'sidebar' + index"
            class="side-nav-link collapsed"
          >
            <i class="fa" :class="'fa-' + item.icon"></i>
            <span>{{ item.label }}</span>
            <span class="menu-arrow"></span>
          </a>
          <router-link v-if="!item.items && item.routeName" class="side-nav-link" :to="{ name: item.routeName }">
            <i class="fa" :class="'fa-' + item.icon"></i>
            <span>{{ item.label }}</span>
          </router-link>
          <div v-if="item.items" class="collapse" :id="'sidebar' + index" style="">
            <ul class="side-nav-second-level">
              <li v-for="(subItem, subIndex) in item.items" :key="subItem">
                <a v-if="!subItem.items && !subItem.routeName" :href="subItem.url">
                  <i class="fa" :class="'fa-' + subItem.icon"></i>
                  {{ subItem.label }}
                </a>

                <a
                  data-bs-toggle="collapse"
                  v-if="subItem.items && !subItem.routeName"
                  :href="'#sidebarSub' + subIndex"
                  aria-expanded="false"
                  :aria-controls="'sidebarSub' + subIndex"
                  class="side-nav-third-link collapsed"
                >
                  <i class="fa" :class="'fa-' + subItem.icon"></i>
                  <span>{{ subItem.label }}</span>
                  <span class="menu-arrow"></span>
                </a>
                <router-link
                  v-if="!subItem.items && subItem.routeName"
                  class="side-nav-third-link collapsed"
                  :to="{ name: subItem.routeName }"
                >
                  <i class="fa" :class="'fa-' + subItem.icon"></i>
                  {{ subItem.label }}
                </router-link>
                <div v-if="subItem.items" class="collapse" :id="'sidebarSub' + subIndex" style="">
                  <ul class="side-nav-third-level">
                    <li v-for="subItem in subItem.items" :key="subItem">
                      <a v-if="subItem.url && !subItem.routeName" :href="subItem.url">
                        <i class="fa" :class="'fa-' + subItem.icon"></i>
                        {{ subItem.label }}
                      </a>
                      <router-link
                        v-if="!subItem.items && subItem.routeName"
                        class="side-nav-third-link collapsed"
                        :to="{ name: subItem.routeName }"
                      >
                        <i class="fa" :class="'fa-' + subItem.icon"></i>
                        {{ subItem.label }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </li>
      </ul>

      <!-- Help Box -->
      <!-- <div class="help-box text-white text-center">
        <a href="javascript: void(0);" class="float-end close-btn text-white">
          <i class="mdi mdi-close"></i>
        </a>
        <img
          src="assets/images/help-icon.svg"
          height="90"
          alt="Helper Icon Image"
        />
        <h5 class="mt-3">Unlimited Access</h5>
        <p class="mb-3">Upgrade to plan to get access to unlimited reports</p>
        <a href="javascript: void(0);" class="btn btn-outline-light btn-sm"
          >Upgrade</a
        >
      </div> -->
      <!-- end Help Box -->
      <!-- End Sidebar -->

      <div class="clearfix"></div>
    </div>
    <!-- Sidebar -left -->
  </div>
  <!-- Left Sidebar End -->
</template>

<script>
export default {
  setup() {},
  data() {
    return {
      menuItems: [],
      isTest: process.env.VUE_APP_TEST,
      isAccount: process.env.VUE_APP_IS_ACCOUNT,
    };
  },
  methods: {
    renderMenuItems() {
      this.menuItems = JSON.parse(localStorage.getItem('menuItems'));
      this.checkRoute(this.menuItems);
    },
    checkRoute(items) {
      // Проверяем действительно ли есть все роуты указанные по имени
      // Т.к. если не проверять и его не будет, то выдает ошибку
      // А проверять нужно, т.к. items кэшируются и бывает не совпадают с реальными
      let routes = this.$router.getRoutes();
      for (let i in items) {
        let item = items[i];
        if (item.items) {
          this.checkRoute(item.items);
        } else {
          if (item.routeName) {
            let isDelete = true;
            for (let route of routes) {
              if (item.routeName === route.name) {
                isDelete = false;
                break;
              }
            }

            if (isDelete) {
              // удаляем роут которого нет
              delete items.splice(i, 1);
            }
          }
        }
      }
    },
  },
  mounted() {
    this.renderMenuItems();
  },
};
</script>
