import Service from '../../../services/Service';
import http from '../../../utils/http-common';
import Str from '../../../utils/Str';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import '../fonts/RobotoRegular.js';
import '../fonts/RobotoMedium.js';
import '../fonts/GothamProBold.js';
import '../fonts/GothamProMedium.js';
import '../fonts/GothamProLight.js';
import '../fonts/GothamProRegular.js';
class ProductService extends Service {

    basePath = 'product';

    newObject() {
        return new ProductService();
    }

    async conditionList() {
        return await http.get('/' + this.basePath + '/condition-list', {
            params: this.prepareRequest()
        }).then(response => {
            return response.data.data;
        }).catch(e => this.catchResponse(e));
    }

    async statusList() {
        return await http.get('/' + this.basePath + '/status-list', {
            params: this.prepareRequest()
        }).then(response => {
            return response.data.data;
        }).catch(e => this.catchResponse(e));
    }

    /**
     * Get instanceValue counts
     */
    async instanceValueCounts() {
        return await http.get('/' + this.basePath + '/instance-value-counts', {
            params: this.prepareRequest()
        }).then(response => {
            return response.data.data;
        }).catch(e => this.catchResponse(e));
    }

    /**
     * Data for a widget on the desktop
     * @returns 
     */
    async assetInfo() {
        return await http.get('/' + this.basePath + '/asset-info', {
            params: this.prepareRequest()
        }).then(response => {
            return response.data.data;
        }).catch(e => this.catchResponse(e));
    }

    /**
     * Print price mass
     * @param {array} data
     */
    printPriceMass(data, typeId) {
        let products = [];
        for (let product of data._selectedItems) {
            products.push(product);
        }
        if (typeId !== 3) {
            var doc = new jsPDF('p', 'mm');
        } else {
            var doc = new jsPDF("landscape");
        }
        doc.setFont('Roboto-Regular');

        let nowDate = Date.now();
        nowDate = Math.round(nowDate / 1000);
        let getFullDate = Str.date(nowDate);

        let pageNumber = 0,
            pageI = 0;
        for (let [i, product] of Object.entries(products)) {
            var nestedTableCell = {
                content: '',
            };

            product.instanceName = product.instanceName.trim();

            if (product.sellPrice) {
                if (Number.isInteger(product.sellPrice)) {
                    var penny = '00';
                } else {
                    var penny = product.sellPrice.split(".")[1].substr(0, 2);
                }
            } else {
                var penny = '00';
            }

            if (typeId == 1 && product.dmdkUin) {
                if (!(i % 40)) {
                    pageI = 0;
                }

                autoTable(doc, {
                    pageBreak: i > 0 && !(i % 40) ? 'always' : 'auto',
                    body: [
                        [nestedTableCell]
                    ],
                    theme: 'plain',
                    tableWidth: 48,
                    tableLineColor: '#000000',
                    tableLineWidth: 0.2,
                    margin: {
                        horizontal: (Number(pageI) % 4) * 48 + 10
                    },
                    startY: Math.trunc(Number(pageI) / 4) * 26.5 + 14.1,
                    styles: {
                        font: 'Roboto-Regular',
                        fontStyle: 'normal',
                        fontSize: 4,
                        cellPadding: 0.5,
                    },
                    body: [
                        [{
                            content: getFullDate,
                            colSpan: 2,
                            styles: {
                                cellWidth: 12
                            }
                        },
                        {
                            content: product.id,
                            colSpan: 2,
                            styles: {
                                cellWidth: 12,
                                halign: 'right'
                            }
                        },
                        {
                            content: 'УИН: ' + product.dmdkUin,
                            colSpan: 2,
                            styles: {
                                cellWidth: 12,
                                lineWidth: {
                                    left: 0.1
                                },
                                fillColor: '#ffffff',
                                lineColor: '#000000'
                            },
                        },
                        ],
                        [
                            {
                                content: product.organizationName,
                                colSpan: 4
                            },
                            {
                                content: 'Проверить подлинность изделия по УИН на сайте http://probpalata.ru',
                                rowSpan: 2,
                                styles: {
                                    minCellHeight: 8,
                                    cellWidth: 15,
                                    lineWidth: {
                                        bottom: 0.1,
                                        left: 0.1
                                    },
                                    fillColor: '#ffffff',
                                    lineColor: '#000000',
                                },
                            },
                            {
                                ColumnDef: {
                                    dataKey: 'dataMatrix'
                                },
                                content: '',
                                dataKey: 'photo',
                                rowSpan: 2,
                                styles: {
                                    minCellWidth: 8
                                },
                            },
                        ],
                        [{
                            content: product.fullName,
                            colSpan: 4
                        }],
                        [{
                            content: 'Цена: ' + (product.sellPrice ? Str.money(product.sellPrice) : 0) + ' руб',
                            colSpan: 4,
                            styles: {
                                font: 'Roboto-Medium',
                                fontStyle: 'bold',
                                fontSize: 6
                            },
                        },
                        {
                            content: 'Размер: 0',
                            colSpan: 4,
                            styles: {
                                lineWidth: {
                                    left: 0.1
                                },
                                fillColor: '#ffffff',
                                lineColor: '#000000'
                            },
                        },
                        ],
                        [{
                            content: product.departmentAddress,
                            colSpan: 4
                        },
                        {
                            content: 'Описание: ' + (product.instanceDescription ? product.instanceDescription : ''),
                            colSpan: 4,
                            styles: {
                                minCellHeight: 6,
                                lineWidth: {
                                    left: 0.1
                                },
                                fillColor: '#ffffff',
                                lineColor: '#000000'
                            },
                        },
                        ],
                        [{
                            content: '',
                            colSpan: 4
                        },
                        {
                            content: '',
                            colSpan: 4,
                            styles: {
                                lineWidth: {
                                    left: 0.1
                                },
                                fillColor: '#ffffff',
                                lineColor: '#000000'
                            },
                        },
                        ],
                        [{
                            content: 'Вес вставок: ' + product.insertWeight + 'г',
                            colSpan: 4
                        },
                        {
                            content: '',
                            colSpan: 4,
                            styles: {
                                lineWidth: {
                                    left: 0.1
                                },
                                fillColor: '#ffffff',
                                lineColor: '#000000'
                            },
                        },
                        ],
                    ],
                    didDrawCell: function (data) {
                        if (data.section === 'body' && data.column.dataKey === 5) {
                            doc.addImage(product.dataMatrix, 'PNG', data.cell.x, data.cell.y, 8.5, 8.5);
                        }
                    },
                });
            } else if (typeId == 2) {
                if (!(i % 18)) {
                    pageI = 0;
                }

                var nestedTableHeight = 100
                autoTable(doc, {
                    pageBreak: i > 0 && !(i % 18) ? 'always' : 'auto',
                    body: [
                        [nestedTableCell]
                    ],
                    theme: 'plain',
                    tableWidth: 60,
                    tableLineColor: '#000000',
                    tableLineWidth: 0.2,
                    margin: {
                        horizontal: (Number(pageI) % 3) * 60 + 20
                    },
                    startY: Math.trunc(Number(pageI) / 3) * 39.5 + 14.1,
                    styles: {
                        font: 'GothamPro-Regular',
                        fontStyle: 'normal',
                        fontSize: 4,
                        cellPadding: 0.5,
                    },
                    body: [
                        [
                            {
                                content: product.id,
                                colSpan: 3,
                                styles: {
                                    halign: 'left',
                                    // lineWidth: 0.1,
                                    minCellHeight: 1,
                                }
                            },
                            {
                                content: getFullDate,
                                colSpan: 3,
                                styles: {
                                    halign: 'right',
                                    // lineWidth: 0.1,
                                    minCellHeight: 1,
                                }
                            },
                        ],
                        [{
                            content: product.instanceName.length <= 40 ? product.instanceName : product.instanceName.slice(0, 40),
                            colSpan: 6,
                            styles: {
                                halign: 'center',
                                valign: product.instanceDescription ? 'middle' : 'bottom',
                                font: 'GothamPro-Medium',
                                fontStyle: 'bold',
                                fontSize: product.instanceName.length <= 30 ? 10 : 8,
                                cellPadding: {
                                    left: 1,
                                    right: 1
                                },
                                minCellHeight: 8.2,
                                // lineWidth: 0.1,
                            }
                        },
                        ],
                        [{
                            content: product.instanceDescription ? (product.instanceDescription.length <= 58 ? product.instanceDescription : product.instanceDescription.slice(0, 58)) : '',
                            colSpan: 6,
                            styles: {
                                halign: 'center',
                                valign: 'middle',
                                fontSize: 8,
                                cellPadding: {
                                    bottom: !product.instanceDescription ? 5.38 : 0,
                                    top: 0.5,
                                    left: 1
                                },
                                minCellHeight: 9.2,
                                // lineWidth: 0.1,
                            }
                        },
                        ],
                        [
                            {
                                content: (product.sellPrice ? Str.money(Math.trunc(product.sellPrice)) : 0),
                                colSpan: 4,
                                rowSpan: 2,
                                styles: {
                                    font: 'GothamPro-Bold',
                                    fontStyle: 'bold',
                                    halign: 'right',
                                    valign: 'bottom',
                                    fontSize: 25,
                                    cellPadding: 0,
                                    // lineWidth: 0.1,
                                },
                            },
                            {
                                content: penny,
                                styles: {
                                    font: 'GothamPro-Bold',
                                    fontSize: 12,
                                    valign: 'bottom',
                                    halign: 'center',
                                    cellPadding: {
                                        bottom: 0,
                                    },
                                    lineWidth: {
                                        bottom: 0.1,
                                    },
                                    // lineWidth: 0.1,
                                    fillColor: '#ffffff',
                                    lineColor: '#000000',
                                },
                            },
                            {
                                content: '',
                                styles: {
                                    // lineWidth: 0.1,
                                    cellWidth: 1,
                                },
                            },
                        ],
                        [
                            {
                                content: 'руб',
                                colSpan: 3,
                                styles: {
                                    fontSize: 8,
                                    valign: 'top',
                                    cellWidth: 8,
                                    // cellPadding: 0,
                                    cellPadding: {
                                        left: 1.2
                                    },
                                    // lineWidth: 0.1,
                                },
                            },
                        ],
                        [
                            {
                                content: 'Цена за: 1 шт.',
                                colSpan: 6,
                                styles: {
                                    font: 'GothamPro-Bold',
                                    fontStyle: 'bold',
                                    halign: 'right',
                                    fontSize: 6,
                                    // lineWidth: 0.1,
                                },
                            },
                        ],
                        [
                            {
                                content: product.departmentAddress,
                                colSpan: 6,
                                styles: {
                                    halign: 'right',
                                    fontSize: 5,
                                    // lineWidth: 0.1,
                                },
                            },
                        ],
                        [
                            {
                                content: product.organizationName,
                                colSpan: 6,
                                styles: {
                                    halign: 'right',
                                    fontSize: 5,
                                    // lineWidth: 0.1,
                                },
                            },
                        ],
                    ],
                });
            } else if (typeId == 3) {
                if (!(i % 9)) {
                    pageI = 0;
                }

                var nestedTableHeight = 100
                autoTable(doc, {
                    pageBreak: i > 0 && !(i % 9) ? 'always' : 'auto',
                    body: [
                        [nestedTableCell]
                    ],
                    theme: 'plain',
                    tableWidth: 80,
                    tableLineColor: '#000000',
                    tableLineWidth: 0.2,
                    margin: {
                        horizontal: (Number(pageI) % 3) * 80 + 20
                    },
                    startY: Math.trunc(Number(pageI) / 3) * 59.5 + 14.1,
                    styles: {
                        font: 'GothamPro-Regular',
                        fontStyle: 'normal',
                        fontSize: 4,
                        cellPadding: 0.5,
                    },
                    body: [
                        [
                            {
                                content: product.id,
                                colSpan: 3,
                                styles: {
                                    halign: 'left',
                                    fontSize: 5,
                                    // lineWidth: 0.1,
                                }
                            },
                            {
                                content: getFullDate,
                                colSpan: 3,
                                styles: {
                                    halign: 'right',
                                    fontSize: 5,
                                    // lineWidth: 0.1,
                                }
                            },
                        ],
                        [{
                            content: product.instanceName.length <= 40 ? product.instanceName : product.instanceName.slice(0, 40),
                            colSpan: 6,
                            styles: {
                                halign: 'left',
                                font: 'GothamPro-Medium',
                                fontStyle: 'bold',
                                fontSize: product.instanceName.length <= 30 ? 15 : 12,
                                cellPadding: {
                                    left: 1,
                                    right: 10
                                },
                                minCellHeight: 12.2,
                                // lineWidth: 0.1,
                            }
                        },
                        ],
                        [{
                            content: product.instanceDescription ? (product.instanceDescription.length <= 58 ? product.instanceDescription : product.instanceDescription.slice(0, 58)) : '',
                            colSpan: 6,
                            styles: {
                                halign: 'left',
                                fontSize: 11,
                                cellPadding: {
                                    top: 1,
                                    left: 1,
                                    right: 10
                                },
                                minCellHeight: 19.5,
                                // lineWidth: 0.1,
                            }
                        },
                        ],
                        [{
                            content: (product.sellPrice ? Str.money(Math.trunc(product.sellPrice)) : 0),
                            colSpan: 4,
                            rowSpan: 2,
                            styles: {
                                font: 'GothamPro-Bold',
                                fontStyle: 'bold',
                                halign: 'right',
                                valign: 'bottom',
                                fontSize: 35,
                                cellPadding: 0,
                                // lineWidth: 0.1,
                            },
                        },
                        {
                            content: penny,
                            styles: {
                                font: 'GothamPro-Bold',
                                fontSize: 16,
                                valign: 'bottom',
                                halign: 'center',
                                cellPadding: {
                                    bottom: 0,
                                },
                                lineWidth: {
                                    bottom: 0.1,
                                },
                                // lineWidth: 0.1,
                                fillColor: '#ffffff',
                                lineColor: '#000000',
                            },
                        },
                        {
                            content: '',
                            styles: {
                                // lineWidth: 0.1,
                                cellWidth: 1,
                            },
                        },
                        ],
                        [
                            {
                                content: 'руб',
                                colSpan: 3,
                                styles: {
                                    fontSize: 10,
                                    valign: 'top',
                                    cellWidth: 8,
                                    cellPadding: {
                                        left: 1.2
                                    },
                                    // lineWidth: 0.1,
                                },
                            },
                        ],
                        [{
                            content: 'Цена за: 1 шт.',
                            colSpan: 6,
                            styles: {
                                font: 'GothamPro-Bold',
                                fontStyle: 'bold',
                                halign: 'right',
                                fontSize: 10,
                                minCellHeight: 8,
                                // lineWidth: 0.1,
                            },
                        },],
                        [
                            {
                                content: '',
                                styles: {
                                    // lineWidth: 0.1,
                                }
                            },
                        ],
                    ],
                });
            }

            pageI++;
        }
        // doc.output('datauri'/'dataurl');
        window.open(doc.output('bloburl'))
    }
}

export default ProductService;