import Service from '../../../services/Service';
import http from '../../../utils/http-common';


class KkmSessionService extends Service {

    basePath = 'kkm/session';

    newObject() {
        return new KkmSessionService();
    }

    /**
     * Open session
     * @param {object} commandResponse
     * @returns {array}
     */
    async open(commandResponse) {
        return await http.put('/' + this.basePath + '/open', { commandResponse: commandResponse }).then(response => {
            return response;
        }).catch(e => this.catchResponse(e));
    }

    /**
     * Close session
     * @param {object} commandResponse
     * @returns {array}
     */
    async close() {
        return await http.put('/' + this.basePath + '/close').then(response => {
            return response;
        }).catch(e => this.catchResponse(e));
    }

}

export default KkmSessionService;