<template>
  <SpeedDial
    showIcon="pi pi-sync"
    style="position: fixed !important; right: 15px; bottom: 140px"
    @click="synchronize"
  ></SpeedDial>
</template>

<script>
import SpeedDial from 'primevue/speeddial';

export default {
  name: 'SynchronizationBar',
  components: {
    SpeedDial,
  },
  props: {
    service: Object,
    loadLazyData: Function,
    items: Object,
  },
  methods: {
    async synchronize() {
      await this.service.synchronize();

      this.loadLazyData();

      this.$toast.info({
        summary: 'Новое уведомление',
        detail: 'Сихнронизация прошла успешно',
      });
    },
  },
};
</script>
