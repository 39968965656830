<template>
  <div v-if="!error">
    <DataTable
      :value="items"
      :lazy="true"
      :paginator="paginator ? service && service.metaPerPage !== undefined : paginator"
      :rows="service ? service.metaPerPage : items.length"
      :totalRecords="service ? service.metaTotal : items.length"
      :loading="loading"
      v-model:filters="filters"
      v-model:selection="selectedItems"
      dataKey="id"
      @page="onPage"
      @sort="onSort"
      @filter="onFilter"
      filterDisplay="menu"
      responsiveLayout="scroll"
      :showGridlines="showGridlines ?? false"
      v-model:first="offset"
      :rowClass="rowClass"
      :paginatorTemplate="
        paginatorTemplate ||
        'CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'
      "
      :rowsPerPageOptions="[15, 50, 100, 500]"
      :currentPageReportTemplate="$t('Showing') + ' {first} - {last} ' + $t('of') + ' {totalRecords}'"
      editMode="cell"
      class="editable-cells-table"
      :rowHover="true"
      @row-select-all="onRowSelectAll"
      @update:selection="onUpdateSelection"
      :resizableColumns="true"
      columnResizeMode="fit"
      :rowGroupMode="rowGroupMode"
      sortMode="single"
      :sortOrder="1"
      :groupRowsBy="groupRowsBy"
      :expandableRowGroups="expandableRowGroups"
      v-model:expandedRowGroups="expandedRowGroups"
      :sortField="sortField"
      @rowReorder="onRowReorder"
    >
      <template #empty> {{ $t('No data') }} </template>
      <Column v-if="massActions" selectionMode="multiple" headerStyle="width: 3rem"></Column>
      <Column v-if="reorderableRows" :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
      <Column
        v-for="col of isVisibleCollection(columns)"
        :field="fieldStr(col.field)"
        :header="autoTranslate ? $t(col.header) : col.header"
        :ref="fieldStr(col.field)"
        :sortable="col.sortable"
        :sortField="col.sortField"
        :key="fieldStr(col.field)"
        :style="col.styles"
        :footer="col.footer"
        footerStyle="padding: 8px"
      >
        <template v-if="col.filter" #filter="{ filterCallback }">
          <FormField
            :service="service"
            :field="col.filter.field || col.field"
            :label="col.filter.label"
            :type="col.filter.type"
            :disabled="col.filter.disabled"
            :options="col.filter.options"
            :optionValue="col.filter.optionValue"
            :optionLabel="col.filter.optionLabel"
            :optionGroupLabel="col.filter.optionGroupLabel"
            :optionGroupChildren="col.filter.optionGroupChildren"
            :selectionMode="col.filter.selectionMode"
            @input="filterCallback"
            @change="col.filter.onChange"
            :filter="true"
          />
        </template>
        <template #filterclear></template>
        <template #filterapply></template>
        <template v-if="col.edit" #editor="{ index }">
          <GridViewCell :col="col" :data="items[index]" @load="load">
            <template #default="{ data }">
              <slot v-if="col.slot" :name="col.slot" :data="data"></slot>
            </template>
          </GridViewCell>
        </template>
        <template #body="{ index }">
          <GridViewCell
            v-for="(colItem, colIndex) in col.items || [col]"
            :key="colIndex"
            :col="colItem"
            :data="items[index]"
            @load="load"
            editMode="show"
            :class="typeof colItem.class === 'function' ? colItem.class(items[index]) : colItem.class"
            @click="colItem.onClick ? colItem.onClick(items[index]) : () => {}"
            @afterClick="afterClick"
          >
            <template #default="{ data }">
              <slot v-if="colItem.slot" :name="colItem.slot" :data="data"></slot>
            </template>
          </GridViewCell>
        </template>
      </Column>
      <template v-if="rowGroupMode === 'subheader'" #groupheader="{ data }" class="dataTable__header">
        <strong>{{ data[groupRowsBy] }}</strong>
      </template>
      <template v-if="footer" #footer>
        <div v-if="footerType === 'link'">
          <router-link :to="footerLinkTo">
            {{ footerText }}
          </router-link>
        </div>
        <div v-else>
          {{ footerText }}
        </div>
      </template>
    </DataTable>

    <SearchBar v-if="search" :service="service" :search="search" @filter="onFilter" />

    <ExportBar v-if="this.export" :export-excel="exportExcel" />

    <SynchronizationBar v-if="synchronization" :load-lazy-data="load" :items="items" :service="service" />

    <SpeedDialBtn v-if="actions" :actions="actions" :service="service" />
    <SpeedDialBtn
      v-if="massActions && selectedItems.length"
      :actions="massActions"
      :service="service"
      @load="load"
      type="left"
    />
  </div>
  <div v-else>
    <Message severity="warn" :closable="false">{{ error }}</Message>
  </div>
</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Message from 'primevue/message';
import SearchBar from './SearchBar.vue';
import FormField from './form/FormField.vue';
import Link from './Link.vue';
import SpeedDialBtn from './SpeedDialBtn.vue';
import SynchronizationBar from './SynchronizationBar.vue';
import GridViewCell from './GridViewCell.vue';
import TableToExcel from '@linways/table-to-excel';
import ExportBar from './ExportBar.vue';

export default {
  components: {
    DataTable,
    Column,
    Message,
    SearchBar,
    FormField,
    Link,
    SpeedDialBtn,
    SynchronizationBar,
    GridViewCell,
    ExportBar,
  },
  props: {
    service: Object,
    columns: Array,
    search: Array,
    synchronization: Boolean,
    export: Boolean,
    actions: Array | Boolean,
    massActions: Array,
    paginator: {
      type: Boolean,
      default: true,
    },
    paginatorTemplate: String,
    rowClass: Function,
    footer: Boolean,
    footerType: String,
    footerLinkTo: String,
    footerText: String | Number,
    isAutoLoad: {
      type: Boolean,
      default: true,
    },
    rowGroupMode: String,
    groupRowsBy: String,
    sortField: String,
    modelValue: Array,
    expandableRowGroups: Boolean,
    reorderableRows: Boolean,
    // Если передать true, то $t('Value') не обязательно использовать
    autoTranslate: Boolean,
    showGridlines: Boolean,
  },
  data() {
    return {
      items: this.modelValue,
      filters: {},
      loading: false,
      offset: 0,
      awaitFilter: false,
      visibleSearch: false,
      selectedItems: [],
      error: null,
      expandedRowGroups: null,
    };
  },
  async created() {
    if (!this.isAutoLoad || !this.service) {
      return;
    }

    if (this.service.parseRequest) {
      this.service.parseRequest();
    }

    // for paginator
    if (this.service.page) {
      this.offset = (this.service.page - 1) * this.service.metaPerPage;
    }

    // for filters
    for (let i in this.columns) {
      if (this.columns[i].filter) {
        this.filters[this.columns[i].field] = {};
      }
    }

    await this.load();
  },
  methods: {
    exportExcel() {
      TableToExcel.convert(document.querySelector('.p-datatable-table'), {
        name: 'report.xlsx',
      });
    },
    async load(routerPush) {
      this.loading = true;

      this.selectedItems = [];

      this.items = await this.service.all();
      this.$emit('update:modelValue', this.items);

      this.error = this.service.getError();

      if (routerPush) {
        history.pushState(
          window.location.origin + window.location.pathname,
          null,
          '?' + new URLSearchParams(this.service.prepareRequest(true)).toString()
        );
      }

      this.loading = false;

      this.$emit('afterLoad', event);
    },
    reset() {
      this.items = [];
      this.$emit('update:modelValue', this.items);
    },
    async onFilter(e) {
      var awaitFilter = (this.awaitFilter = Math.random());
      // ставим ожидание, чтобы при введении значения в фильтре на каждую букву не отправлять
      setTimeout(async () => {
        if (awaitFilter === this.awaitFilter) {
          delete this.service.page;
          await this.load(true);
        }
      }, 1000);
    },
    async onPage(e) {
      this.service.page = e.page + 1;
      this.service.metaPerPage = e.rows;

      await this.load(true);
    },
    async onSort(e) {
      delete this.service.page;
      this.service.sort = e.sortField;
      this.service.direction = e.sortOrder === 1 ? 'asc' : 'desc';

      await this.load(true);
    },
    onRowSelectAll(e) {
      // Это нужно, т.к. выбор всех галочек в primeview проверяет общее кол-во элементов, а не те, что на странице
      // поэтому мы добавляем пустые элементы к this.items, а в onUpdateSelection убираем их
      if (this.service.metaPerPage < this.service.metaTotal && e.data.length < this.service.metaTotal) {
        let countDiff = this.service.metaTotal - this.service.metaPerPage;
        for (let i = 0; i < countDiff; i++) {
          e.data.push([]);
        }
      }
    },
    onUpdateSelection(data) {
      this.service._selectedItems = Object.assign([], data);

      // Это нужно, т.к.... см. onRowSelectAll
      if (
        this.service.metaPerPage < this.service.metaTotal &&
        this.service._selectedItems.length >= this.service.metaTotal
      ) {
        let countDiff = this.service.metaTotal - this.service.metaPerPage;
        for (let i = 0; i < countDiff; i++) {
          this.service._selectedItems.pop();
        }
      }
    },
    fieldStr(field) {
      if (typeof field === 'object') {
        return field.join('.');
      }

      return field;
    },
    isVisibleCollection(collection) {
      for (let i = collection.length; i--; ) {
        if (!this.isVisible(collection[i].visible)) {
          collection.splice(i, 1);
        }
      }

      return collection;
    },
    isVisible(modelVisible) {
      let visible = typeof modelVisible === 'function' ? modelVisible(this.service) : modelVisible;

      if (visible || visible === undefined) {
        return true;
      }

      return false;
    },
    afterClick() {
      for (let i = this.items.length; i--; ) {
        let item = this.items[i];
        if (item.isNewRecord) {
          this.items.splice(i, 1);
        }
      }

      this.$emit('update:modelValue', this.items);
    },
    async onRowReorder(event) {
      let fromId = this.items[event.dragIndex]['id'];
      let toId = this.items[event.dropIndex]['id'];

      this.items = event.value;
      this.$emit('update:modelValue', this.items);

      if (!(await this.service.move(fromId, toId))) {
        this.$toast.error({ detail: this.service.getError() });

        this.load();
      }
    },
  },
};
</script>

<style>
.p-rowgroup-header {
  background: lightgoldenrodyellow !important;
  top: auto;
}
.p-column-filter-matchmode-dropdown {
  display: none !important;
}
.p-column-filter-buttonbar {
  padding: 0 !important;
}
</style>