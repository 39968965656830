<template>
  <div class="container-fluid">
    <div class="page-title-box">
      <bread-crumb :breadcrumbs="[{ label: 'Desktop' }]"></bread-crumb>
      <h4 class="page-title">{{ $t('Desktop') }}</h4>
    </div>
    <SearchView />
    <!-- <div class="d-flex">
      <div v-if="session && $store.getters.can('dashboard.cash')" class="col-5 m-2 p-3 bg-white">
        <p class="fs-4 text-black">{{ $t('Cash in hand') }}</p>
        <b class="fs-2 text-black">{{ $str.money(session.balance) }}</b>
        <hr />
        <div class="d-flex justify-content-between">
          <div>
            {{ $t('Arrival') }}:<br />
            <span class="fs-3 text-success bold"
              >{{ $str.money(session.amountPko) }}<i class="ms-1 fs-5 fa fa-long-arrow-up"></i
            ></span>
          </div>
          <div>
            {{ $t('Expense') }}: <br />
            <span class="fs-3 text-danger bold"
              >{{ $str.money(session.amountRko) }}<i class="ms-1 fs-5 fa fa-long-arrow-down"></i
            ></span>
          </div>
          <div>
            {{ $t('At the beginning of the shift') }}: <br />
            <span class="fs-3 text-black bold">{{ $str.money(session.openingBalance) }}</span>
          </div>
        </div>
      </div>
      <div v-if="$store.getters.can('dashboard.finance')" class="col-6 m-2 p-3 bg-white">
        <p class="fs-4 text-black">{{ $t('Financial condition') }}</p>
        <div class="row px-3">
          <div class="col-4 mt-2" style="border-right: 1px solid #f4f4f4">
            <span>{{ $t('Active') }}: <br />{{ $t('collateral portfolio') }}</span> <br />
            <span class="fs-3 text-success bold mt-3 d-block">{{ $str.money(activeSum.commissionSum) }}</span>
          </div>
          <div class="col-4 mt-2" style="border-right: 1px solid #f4f4f4">
            <span>{{ $t('Active') }}: <br />{{ $t('purchased goods') }}</span> <br />
            <span class="fs-3 text-success bold mt-3 d-block">{{ $str.money(activeSum.purchaseSum) }}</span>
          </div>
          <div class="col-4 mt-2">
            <span>{{ $t('Total actives') }}</span> <br /><br />
            <span class="fs-3 text-orange bold mt-3 d-block">{{ $str.money(activeSum.sum) }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div v-if="$store.getters.can('dashboard.income')" class="col-md-6">
        <IncomeGrid />
      </div>
      <div v-if="$store.getters.can('dashboard.expense')" class="col-md-6">
        <ExpenseGrid />
      </div>
    </div> -->
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import ExpenseGrid from '../components/ExpenseGrid.vue';
import IncomeGrid from '../components/IncomeGrid.vue';
import BreadCrumb from '../components/ui/BreadCrumb';
import Form from '../components/ui/form/Form.vue';
import SearchView from '../components/ui/SearchView.vue';
import KkmSessionService from '/src/modules/kkm/services/KkmSessionService';
import ProductService from '/src/modules/product/services/ProductService';

export default {
  components: {
    BreadCrumb,
    IncomeGrid,
    ExpenseGrid,
    KkmSessionService,
    Form,
    SearchView,
  },
  data() {
    return {
      session: {},
      activeSum: {},
      service: new KkmSessionService({
        expand: ['amountPko', 'amountRko', 'balance'],
      }),
      productService: new ProductService(),
    };
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    return {
      logout: () => {
        store.commit('auth/logout');
        router.push('/auth');
      },
    };
  },
  async created() {
    this.activeSum = await this.productService.assetInfo();
  },
};
</script>

<style>
.text-black {
  color: #000;
}
.text-orange {
  color: #d9a12f;
}
.bold {
  font-weight: bold;
}
</style>
