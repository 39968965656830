<template>
  <component :is="layout + '-layout'" />
  <!-- <router-view/> -->
</template>

<script>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import MainLayout from './layout/MainLayout';
import AuthLayout from './layout/AuthLayout';
import PrintLayout from './layout/PrintLayout';

export default {
  setup() {
    const route = useRoute();
    return {
      layout: computed(() => route.meta.layout),
    };
  },
  components: { MainLayout, AuthLayout, PrintLayout },
  mounted() {
    this.$primevue.config.locale.accept = this.$t('Yes');
    this.$primevue.config.locale.reject = this.$t('No');
    this.$primevue.config.locale.today = this.$t('Today');
    this.$primevue.config.locale.clear = this.$t('Clear');
    this.$primevue.config.locale.monthNames = [
      this.$t('January'),
      this.$t('February'),
      this.$t('March'),
      this.$t('April'),
      this.$t('May'),
      this.$t('June'),
      this.$t('July'),
      this.$t('August'),
      this.$t('September'),
      this.$t('October'),
      this.$t('November'),
      this.$t('December'),
    ];
    this.$primevue.config.locale.dayNamesMin = [
      this.$t('Su'),
      this.$t('Mo'),
      this.$t('Tu'),
      this.$t('We'),
      this.$t('Th'),
      this.$t('Fr'),
      this.$t('Sa'),
    ];
    this.$primevue.config.locale.firstDayOfWeek = 1;
    this.$primevue.config.locale.dateFormat = 'dd.mm.yy';
  },
};
</script>
