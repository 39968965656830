<template>
  <div class="p-field">
    <div class="d-flex align-items-stretch w-100">
      <FormField
        :service="searchService"
        field="search"
        type="autocomplete"
        @complete="searchMeilisearch($event)"
        :suggestions="resultsMeilisearch"
        :placeholder="$t('Search by lombard')"
        :forceSelection="true"
        optionLabel="name"
        optionGroupLabel="name"
        optionGroupChildren="items"
        :completeOnFocus="false"
        :autoOptionFocus="false"
        :selectOnFocus="false"
        margin="0"
        class="w-100"
        panelClass="global-search"
      >
        <template #optiongroup="{ data }">
          <div class="fs-4">
            {{ $t(data.item.label) }}
          </div>
        </template>
        <template #item="{ data }">
          <router-link :to="data.item.route + data.item.id">
            <div class="d-flex">
              <div v-html="data.item._formatted.id"></div>
              <div>:&nbsp;</div>
              <div v-html="data.item._formatted.name"></div>
            </div>
            <div v-for="(desc, index) in data.item._formatted.desc" :key="i" class="text-muted fs-7">
              <div v-if="desc" class="d-flex">
                <div>{{ index }}</div>
                <div>:&nbsp;</div>
                <div v-html="desc"></div>
              </div>
            </div>
          </router-link>
        </template>
      </FormField>
      <!-- <Button icon="pi pi-search" /> -->
    </div>
  </div>
</template>

<script>
import Button from 'primevue/button';
import FormField from './form/FormField.vue';
import SearchService from '../../modules/search/services/SearchService';

export default {
  components: { Button, FormField },
  data() {
    return {
      searchService: new SearchService(),
      resultsMeilisearch: null,
    };
  },
  methods: {
    async searchMeilisearch(e) {
      setTimeout(async () => {
        this.searchService.search = e.query;
        let results = await this.searchService.all();
        this.resultsMeilisearch = results.filter((data) => {
          return data;
        });
      }, 250);
    },
  },
};
</script>

<style>
.global-search.p-autocomplete-panel.p-component.p-ripple-disabled {
  max-height: 500px !important;
}
.global-search-higlight {
  background-color: #efefef;
  color: black;
  border-radius: 3px;
}
</style>