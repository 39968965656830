import Service from '../../../services/Service';
import http from '../../../utils/http-common';

class TransactionOperationService extends Service {

    basePath = 'transaction/operation';

    newObject() {
        return new TransactionOperationService();
    }

    async sum() {
        return await http.get('/' + this.basePath + '/sum', { params: this.prepareRequest() }).then(response => {
            return response.data.data;
        }).catch(e => this.catchResponse(e));
    }

    async rkoList() {
        return await http.get('/transaction/operation-cash-flow/rko-list', { params: this.prepareRequest() }).then(response => {
            return response.data.data;
        }).catch(e => this.catchResponse(e));
    }

    async pkoList() {
        return await http.get('/transaction/operation-cash-flow/pko-list', { params: this.prepareRequest() }).then(response => {
            return response.data.data;
        }).catch(e => this.catchResponse(e));
    }
}

export default TransactionOperationService;