import Storage from '../../utils/Storage';

export default {
    namespaced: true,
    state() {
        return {
            session: Storage.getItem('kkm/session'),
        }
    },
    mutations: {
        setSession(state, session) {
            Storage.setItem('kkm/session', session);
            state.session = session;
        },
    },
    actions: {

    },
    getters: {
        sessionIsNewDay(state) {
            return state.session ? state.session.isNewDay : false;
        }
    }
}
