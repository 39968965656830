import axios from "axios"
import { error } from '../../utils/error'
import Storage from '../../utils/Storage';
import Env from '../../utils/Env';
const TOKEN_KEY = 'jwt-token'

export default {
    namespaced: true,
    state() {
        return {
            token: localStorage.getItem(TOKEN_KEY), // Получение токена из localStorage при его наличии. Если его нет, то авторизация.
            access: 0, // Дефолт доступа, который заменяется при посещении страницы.
            userName: null,
            userId: null,
            name: null,
            curOrgId: localStorage.getItem('curOrgId') ?? null,
            curDepId: localStorage.getItem('curDepId') ?? null,
            organizationList: Storage.getItem('organizationList') || [],
            menuItems: [],
            authKey: null,
            userIsSupport: Storage.getItem('userIsSupport'),
            userIsAdmin: Storage.getItem('userIsAdmin'),
            userPermissionNames: Storage.getItem('userPermissionNames'),
        }
    },
    mutations: {
        setToken(state, token) {
            state.token = token
            localStorage.setItem(TOKEN_KEY, token)
        },
        logout(state) {
            state.token = null; // Отчистка токена при выходе из аккаунта.
            localStorage.removeItem(TOKEN_KEY);
            Storage.getItem('organizationList', null);
        },
        setAuthKey(state, authKey) {
            state.authKey = authKey
        },
        setAccess(state, access) {
            state.access = access
        },
        setMenuItemsViaRoles(state, payload) {
            payload.forEach(element => {
                state.menuItems.push(element)
            });
            localStorage.setItem('menuItems', JSON.stringify(payload))
        },
        setUserInfo(state, payload) {
            localStorage.setItem('storageExpire', payload.data.storageExpire)
            state.name = payload.data.userInfo.name
            localStorage.user = [
                payload.data.userInfo.name
            ]
            localStorage.userShortName = [
                payload.data.userShortName
            ]
            localStorage.userId = [
                payload.data.userInfo.id
            ]
        },
        setCurOrgId(state, payload) {
            localStorage.setItem('curOrgId', payload)
            state.curOrgId = payload
            localStorage.curOrgId = payload
        },
        setUserIsSupport(state, payload) {
            Storage.setItem('userIsSupport', payload)
            state.userIsSupport = payload
        },
        setUserIsAdmin(state, payload) {
            Storage.setItem('userIsAdmin', payload)
            state.userIsAdmin = payload
        },
        setUserPermissionNames(state, payload) {
            Storage.setItem('userPermissionNames', payload)
            state.userPermissionNames = payload
        },
        setCurDepId(state, payload) {
            localStorage.setItem('curDepId', payload)
            state.curDepId = payload
            localStorage.curDepId = payload
        },
        setOrganizationList(state, payload) {
            // payload.forEach(element => {
            //     state.organizationList.push(element)
            // });
            state.organizationList = payload;
            Storage.setItem('organizationList', payload)
        }
    },
    actions: {
        // sendAuthKey(authKey) {
        //     document.location.href = `${process.env.VUE_APP_YII_URL}/user/login-key?authKey=` + authKey
        // },
        async login({ commit, dispatch }, payload) {
            payload.grant_type = process.env.VUE_APP_API_GRANT_TYPE
            payload.client_id = process.env.VUE_APP_API_ID
            payload.client_secret = process.env.VUE_APP_API_KEY
            payload.scope = "*"
            payload.username = payload.email
            try {
                const { data } = await axios.post(`${Env.getItem('VUE_APP_API_URL')}/oauth/token`, payload)
                commit('setToken', data.access_token)
                commit('clearMessage', null, { root: true })
                await axios.get(`${Env.getItem('VUE_APP_API_URL')}/user/menu-item`,
                    {
                        headers: {
                            Authorization: `Bearer ${data.access_token}`
                        }
                    }
                ).then((response) => {
                    commit('setMenuItemsViaRoles', response.data)
                })

                await axios.get(`${Env.getItem('VUE_APP_API_URL')}/organization/all-list`,
                    {
                        headers: {
                            Authorization: `Bearer ${data.access_token}`
                        }
                    }
                ).then((response) => {
                    commit('setOrganizationList', response.data.data)
                })

                await axios.post(`${Env.getItem('VUE_APP_API_URL')}/api/user/auth`, {
                    expand: ['isAdmin']
                },
                    {
                        headers: {
                            Authorization: `Bearer ${data.access_token}`
                        }
                    }
                ).then((response) => {
                    commit('setUserInfo', response)
                    commit('setCurOrgId', response.data.userInfo.organization_id)
                    commit('setUserIsSupport', response.data.userInfo.isSupport)
                    commit('setUserIsAdmin', response.data.isAdmin)
                    commit('setUserPermissionNames', response.data.permissionNames)
                    commit('setCurDepId', response.data.curDepId)
                    // localStorage.setItem('YiiKey', response.data.authKey)
                    // document.location.href = `${process.env.VUE_APP_YII_URL}/user/login-key?authKey=` + response.data.authKey
                    document.location.href = '/';
                })
            } catch (e) {
                dispatch('setMessage', {
                    value: error(e.response.data.error),
                    type: 'danger'
                }, { root: true })
                throw new Error()
            }
        },
        async checkCan({ commit }, state) {
            try {
                await axios.get(`${Env.getItem('VUE_APP_API_URL')}/faq`, {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem(TOKEN_KEY)
                    }
                }).then((response) => {
                    if (response.data === "Access Denied") {
                        commit('setAccess', 0);
                    } else {
                        commit('setAccess', 1);
                        // commit('setUserInfo', response)
                    }
                })
            } catch (e) {
                console.log(e);
            }
        },
    },
    getters: {
        token(state) {
            return state.token;
        },
        isAuthenticated(_, getters) {
            return !!getters.token;
        },
        getAccess(state) {
            return state.access;
        },
        getMenuItems(state) {
            return state.menuItems;
        },
        organizationList(state) {
            return state.organizationList;
        },
        getAuthKey(state) {
            return state.authKey;
        },
        currentOrg(state) {
            for (let org of state.organizationList) {
                if (state.curOrgId == org.id) {
                    return org;
                }
            }
        },
        currentDepartmentId(state) {
            return state.curDepId;
        },
        userIsSupport(state) {
            return state.userIsSupport;
        },
        userIsAdmin(state) {
            return state.userIsAdmin;
        },
        userPermissionNames(state) {
            return state.userPermissionNames;
        },
    }
}
