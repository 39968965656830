<template></template>

<script>
localStorage.removeItem('pusherTransportNonTLS');

localStorage.removeItem('pusherTransportNonTLS');

import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import SmsService from '../../modules/sms/services/SmsService';
import Env from '../../utils/Env';

const TOKEN_KEY = 'jwt-token';

window.socketServer = new Echo({
  broadcaster: 'pusher',
  key: '17fa236f9e13b241459f',
  wsHost: '127.0.0.1',
  wsPort: 6001,
  forceTLS: false,
  disableStats: true,
  cluster: 'eu',
  auth: {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem(TOKEN_KEY),
    },
  },
});

window.socketServer.connector.pusher.config.authEndpoint = Env.getItem('VUE_APP_API_URL') + `/broadcasting/auth`;

export default {
  name: 'SmsStack',
  data() {
    return {
      userId: localStorage.getItem('userId'),
      service: new SmsService(),
    };
  },
  methods: {
    showCommonSms: function (message) {
      return this.$toast.info({ summary: 'Новое уведомление', detail: message });
    },
    showPersonalSms: function (message) {
      return this.$toast.info({ summary: 'Персональное уведомление', detail: message });
    },
  },
  async created() {
    await window.socketServer.channel('smsEvents').listen('.CreateCommonSms', (notification) => {
      this.showCommonSms(notification.message);
    });

    await window.socketServer.private('App.Modules.User.Models.User.' + this.userId).notification((notification) => {
      this.showPersonalSms(notification.message);
      this.service.readSms(notification.smsId);
    });
  },
};
</script>
