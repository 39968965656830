<template>
  <left-side-bar></left-side-bar>

  <div class="content-page">
    <div class="content">
      <top-side-bar />
      <router-view />
    </div>
  </div>

  <Toast position="bottom-left" group="bl" />
  <ConfirmDialog></ConfirmDialog>
  <SmsStack></SmsStack>
  <SidebarProfile />
</template>

<script>
import LeftSideBar from '../components/LeftSideBar';
import TopSideBar from '../components/TopSideBar';
import Toast from 'primevue/toast';
import ConfirmDialog from 'primevue/confirmdialog';
import SmsStack from '../components/sms/SmsStack';
import SidebarProfile from '../components/user/SidebarProfile';

export default {
  components: { LeftSideBar, TopSideBar, Toast, ConfirmDialog, SidebarProfile, SmsStack },
};
</script>

<style lang="scss" scoped>
button {
  min-width: 10rem;
  margin-right: 0.5rem;
}

@media screen and (max-width: 960px) {
  button {
    width: 100%;
    margin-bottom: 0.5rem;
  }
}
</style>
