import Service from '../../../services/Service';
import http from '../../../utils/http-common';

class DashboardRateGoldService extends Service {

    basePath = 'dashboard/rate-gold';

    newObject() {
        return new DashboardRateGoldService();
    }

    async showItem() {
        return await http.get('/' + this.basePath + '/show-item', { params: this.prepareRequest() }).then(response => {
            this.applyResponse(response)
        });
    }

}

export default DashboardRateGoldService;