import Service from '../../../services/Service';
import http from '../../../utils/http-common';

class KkmKktService extends Service {

    basePath = 'kkm/kkt';

    newObject() {
        return new KkmKktService();
    }

    /**
     * Get is session open
     *  @returns {bool}
     */
    getIsSessionOpen() {
        return this.sessionState == 2 || this.sessionState == 3;
    }

    /**
     * Get is session open good
     *  @returns {bool}
     */
    getIsSessionOpenGood() {
        return this.sessionState == 2 && (!this.session || !this.session.isNewDay);
    }

    /**
     * Synchronization KKM
     */
    async synchronize() {
        return await http.get('/' + this.basePath + '/synchronize', { params: this.prepareRequest() }).then(response => {
            return response.data;
        }).catch(e => this.catchResponse(e));
    }

    /**
     * Get item by id
     * @param {Object} commandResponse
     * @returns {Service}
     */
    async current(commandResponse) {
        await http.post('/' + this.basePath + '/current', { expand: 'session', commandResponse: commandResponse }).then(async response => {
            this.applyResponse(response);
        }).catch(e => this.catchResponse(e));

        return this;
    }

    /**
     * 
     * @returns {array}
     */
    async allListByOrg() {
        return await http.get('/' + this.basePath + '/all-list-by-org', { params: this.prepareRequest() }).then(response => {
            return response.data.data;
        }).catch(e => this.catchResponse(e));
    }

    /**
     *
     * @returns {array}
     */
    async allListByDepartment() {
        return await http.get('/' + this.basePath + '/all-list-by-department', { params: this.prepareRequest() }).then(response => {
            return response.data.data;
        }).catch(e => this.catchResponse(e));
    }

    /**
     * Add correction check
     */
    async addCorrectionCheck() {
        return await http.get('/' + this.basePath + '/correction-check', { params: this.prepareRequest() }).then(response => {
            return response;
        }).catch(e => this.catchResponse(e));
    }

}

export default KkmKktService;