<template>
  <div>
    <Galleria
      :value="serviceF"
      v-model:activeIndex="activeIndex"
      :fullScreen="true"
      :showItemNavigators="true"
      :showThumbnails="false"
      v-model:visible="displayCustom"
    >
      <template #item="{ item }">
        <img :src="item.url" :alt="item.alt" style="width: 100%; display: block" />
      </template>
      <template #thumbnail="{ item }">
        <img :src="item.urlMin" :alt="item.alt" style="display: block" />
      </template>
    </Galleria>

    <div v-if="serviceF" class="row">
      <div v-for="(item, index) of serviceF" class="col-6 col-sm-4 col-md-2" style="margin-bottom: 20px" :key="index">
        <div class="position-relative img-thumbnail">
          <Button
            v-if="edit"
            @click="onDeleteFile(index)"
            icon="pi pi-trash"
            class="p-button-rounded p-button-danger position-absolute img-thumbnail-hover"
            style="width: 2rem; height: 2rem; top: -10px; right: -10px"
          />
          <!-- Здесь специально не используются flex, т.к. задумано, что изображение ставится по высоте на 100%, центрируется. Но если по ширине не входит, то показывается от левого края -->
          <!-- Если нужно будет использовать полное центрирование, то использовать d-flex align-items-center justify-content-center -->
          <div
            style="height: 100px; overflow-x: hidden; cursor: pointer"
            class="text-center"
            @click="onClickFile(index)"
          >
            <img
              :src="item.urlMin"
              :alt="item.alt"
              style="max-height: 100%; position: relative; top: 50%; transform: translateY(-50%)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from 'primevue/button';
import Galleria from 'primevue/galleria';
import http from '../../utils/http-common';

export default {
  components: { Button, Galleria },
  props: {
    service: Object,
    field: String,
    edit: Boolean,
  },
  data() {
    return {
      activeIndex: 0,
      displayCustom: false,
    };
  },
  computed: {
    fieldStr() {
      if (typeof this.field === 'object') {
        return this.field.join('.');
      }

      return this.field;
    },
    serviceF: {
      get() {
        if (typeof this.field === 'object') {
          let serviceF = this.service;

          for (let f of this.field) {
            if (serviceF[f] === undefined) {
              return null;
            }
            serviceF = serviceF[f];
          }

          return serviceF;
        }

        return this.service[this.field];
      },
      set(v) {
        if (typeof this.field === 'object') {
          // Перебором присвоение не сработало, поэтому сравниваем по кол-ву элементов
          if (this.service[this.field[0]] === undefined) {
            this.service[this.field[0]] = {};
          }
          if (this.field.length === 2) {
            this.service[this.field[0]][this.field[1]] = v;
          } else if (this.field.length === 3) {
            if (this.service[this.field[0]][this.field[1]] === undefined) {
              this.service[this.field[0]][this.field[1]] = {};
            }
            this.service[this.field[0]][this.field[1]][this.field[2]] = v;
          }
        } else {
          this.service[this.field] = v;
        }
      },
    },
  },
  methods: {
    async onDeleteFile(index) {
      await this.$confirm.require({
        message: this.$t('Are you sure you want to proceed?'),
        header: this.$t('Confirmation'),
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          let id = this.serviceF[index].id;

          this.serviceF.splice(index, 1);

          await http.delete('/' + this.service.basePath + '/upload-delete/' + id);
        },
      });
    },
    onClickFile(index) {
      this.activeIndex = index;
      this.displayCustom = true;
    },
  },
};
</script>

<style scoped>
.img-thumbnail-hover {
  display: none;
}
.img-thumbnail:hover .img-thumbnail-hover {
  display: flex;
  z-index: 1;
}
</style>