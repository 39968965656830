import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import i18n from './lang';
import LoadScript from 'vue-plugin-load-script';
import VueCookies from 'vue3-cookies';
import PrimeVue from 'primevue/config';
import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';
import StyleClass from 'primevue/styleclass';
import VueDadata from 'vue-dadata';
import Maska from 'maska';
import CKEditor from '@ckeditor/ckeditor5-vue';

import env from './plugins/env';
import arr from './plugins/arr';
import str from './plugins/str';
import toast from './plugins/toast';
import axios from 'axios';
import Env from './utils/Env';

import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

const app = createApp(App)

app.config.errorHandler = (err, instance, info) => {
  axios.post(Env.getItem('VUE_APP_API_URL') + '/log/web', {
    url: window.location.href,
    error: { name: err.name, message: err.message, stack: err.stack },
  });

  throw err;
}

app.use(store)
  .use(PrimeVue)
  .directive('styleclass', StyleClass)
  .use(ConfirmationService)
  .use(VueDadata)
  .use(Maska)
  .use(ToastService)
  .use(VueCookies)
  .use(router)
  .use(i18n)
  .use(CKEditor)
  .use(LoadScript)
  .use(env)
  .use(arr)
  .use(str)
  .use(toast)
  .directive('tooltip', Tooltip)
  .mount('#app');