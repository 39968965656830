import {
  createRouter,
  createWebHistory
} from 'vue-router';
import store from '../store';
import Home from '../views/Home.vue';

const routes = [{
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/activity-log',
    name: 'ActivityLog',
    component: () => import('../modules/activityLog/views/Index.vue'),
  },
  {
    path: '/auth',
    name: 'Auth',
    component: () => import('../views/Auth.vue'),
    meta: {
      layout: 'auth',
      auth: false,
    },
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import('../views/Logout.vue'),
  },
  // batch
  {
    path: '/batch/counteragent',
    name: 'BatchCounteragent',
    component: () => import('../modules/batch/views/counteragent/Index.vue'),
  },
  {
    path: '/batch/counteragent/update/:id',
    name: 'BatchCounteragentUpdate',
    component: () => import('../modules/batch/views/counteragent/Update.vue'),
  },
  {
    path: '/batch/counteragent/:id',
    name: 'BatchCounteragentShow',
    component: () => import('../modules/batch/views/counteragent/Show.vue'),
  },
  {
    path: '/batch/counteragent/create',
    name: 'BatchCounteragentCreate',
    component: () => import('../modules/batch/views/counteragent/Create.vue'),
  },
  {
    path: '/batch/deal',
    name: 'BatchDeal',
    component: () => import('../modules/batch/views/deal/Index.vue'),
  },
  {
    path: '/batch/deal/update/:id',
    name: 'BatchDealUpdate',
    component: () => import('../modules/batch/views/deal/Update.vue'),
  },
  {
    path: '/batch/deal/:id',
    name: 'BatchDealShow',
    component: () => import('../modules/batch/views/deal/Show.vue'),
  },
  {
    path: '/batch/deal/create',
    name: 'BatchDealCreate',
    component: () => import('../modules/batch/views/deal/Create.vue'),
  },
  {
    path: '/batch/specification',
    name: 'BatchSpecification',
    component: () => import('../modules/batch/views/specification/Index.vue'),
  },
  {
    path: '/batch/specification/update/:id',
    name: 'BatchSpecificationUpdate',
    component: () => import('../modules/batch/views/specification/Update.vue'),
  },
  {
    path: '/batch/specification/:id',
    name: 'BatchSpecificationShow',
    component: () => import('../modules/batch/views/specification/Show.vue'),
  },
  {
    path: '/batch/specification/create',
    name: 'BatchSpecificationCreate',
    component: () => import('../modules/batch/views/specification/Create.vue'),
  },
  {
    path: '/batch/aggregate-product',
    name: 'BatchAggregateProduct',
    component: () => import('../modules/batch/views/aggregateProduct/Index.vue'),
  },
  {
    path: '/batch/organization',
    name: 'BatchOrganization',
    component: () => import('../modules/batch/views/organization/Index.vue'),
  },
  {
    path: '/batch/organization/create',
    name: 'BatchOrganizationCreate',
    component: () => import('../modules/batch/views/organization/Create.vue'),
  },
  {
    path: '/batch/organization/update/:id',
    name: 'BatchOrganizationUpdate',
    component: () => import('../modules/batch/views/organization/Update.vue'),
  },
  // bookkeeping
  {
    path: '/bookkeeping',
    name: 'Bookkeeping',
    component: () => import('../modules/bookkeeping/views/bookkeeping/index.vue'),
  },
  // contract
  {
    path: '/callcenter',
    name: 'Callcenter',
    component: () => import('../modules/callcenter/views/Show.vue'),
  },
  // contract
  {
    path: '/contract',
    name: 'Contract',
    component: () => import('../modules/contract/views/contract/Index.vue'),
  },
  {
    path: '/contract/:id',
    name: 'ContractShow',
    component: () => import('../modules/contract/views/contract/Show.vue'),
  },
  {
    path: '/contract/create',
    name: 'ContractCreate',
    component: () => import('../modules/contract/views/contract/Create.vue'),
  },
  {
    path: '/contract/tariff',
    name: 'ContractTariff',
    component: () => import('../modules/contract/views/tariff/Index.vue'),
  },
  {
    path: '/contract/tariff/create',
    name: 'ContractTariffCreate',
    component: () => import('../modules/contract/views/tariff/Create.vue'),
  },
  {
    path: '/contract/tariff/update/:id',
    name: 'ContractTariffUpdate',
    component: () => import('../modules/contract/views/tariff/Update.vue'),
  },
  {
    path: '/contract/commission-put-on-market/transaction',
    name: 'ContractCommissionPutOnMarketTransactionCreate',
    component: () => import('../modules/contract/transactions/views/commissionPutOnMarket/Create.vue'),
  },
  {
    path: '/contract/commission-put-on-market/transaction/:id',
    name: 'ContractCommissionPutOnMarketTransactionShow',
    component: () => import('../modules/contract/transactions/views/commissionPutOnMarket/Show.vue'),
  },
  {
    path: '/contract/commission-return-from-market/transaction',
    name: 'ContractCommissionReturnFromMarketTransactionCreate',
    component: () => import('../modules/contract/transactions/views/commissionReturnFromMarket/Create.vue'),
  },
  {
    path: '/contract/commission-return-from-market/transaction/:id',
    name: 'ContractCommissionReturnFromMarketTransactionShow',
    component: () => import('../modules/contract/transactions/views/commissionReturnFromMarket/Show.vue'),
  },
  {
    path: '/contract/commission-sell/transaction',
    name: 'ContractCommissionSellTransactionCreate',
    component: () => import('../modules/contract/transactions/views/commissionSell/Create.vue'),
  },
  {
    path: '/contract/commission-sell/transaction/:id',
    name: 'ContractCommissionSellTransactionShow',
    component: () => import('../modules/contract/transactions/views/commissionSell/Show.vue'),
  },
  {
    path: '/contract/commission-payment-fee/transaction',
    name: 'ContractCommissionPaymentFeeTransactionCreate',
    component: () => import('../modules/contract/transactions/views/commissionPaymentFee/Create.vue'),
  },
  {
    path: '/contract/commission-payment-fee/transaction/:id',
    name: 'ContractCommissionPaymentFeeTransactionShow',
    component: () => import('../modules/contract/transactions/views/commissionPaymentFee/Show.vue'),
  },
  {
    path: '/contract/commission-buy-back/transaction',
    name: 'ContractCommissionBuyBackTransactionCreate',
    component: () => import('../modules/contract/transactions/views/commissionBuyBack/Create.vue'),
  },
  {
    path: '/contract/commission-buy-back/transaction/:id',
    name: 'ContractCommissionBuyBackTransactionShow',
    component: () => import('../modules/contract/transactions/views/commissionBuyBack/Show.vue'),
  },
  {
    path: '/contract/buy-remelt/transaction',
    name: 'ContractBuyRemeltTransactionCreate',
    component: () => import('../modules/contract/transactions/views/buyRemelt/Create.vue'),
  },
  {
    path: '/contract/buy-remelt/transaction/:id',
    name: 'ContractBuyRemeltTransactionShow',
    component: () => import('../modules/contract/transactions/views/buyRemelt/Show.vue'),
  },
  {
    path: '/contract/buy-sell/transaction',
    name: 'ContractBuySellTransactionCreate',
    component: () => import('../modules/contract/transactions/views/buySell/Create.vue'),
  },
  {
    path: '/contract/buy-sell/transaction/:id',
    name: 'ContractBuySellTransactionShow',
    component: () => import('../modules/contract/transactions/views/buySell/Show.vue'),
  },
  {
    path: '/contract/commission-dkp/transaction',
    name: 'ContractCommissionDkpTransactionCreate',
    component: () => import('../modules/contract/transactions/views/commissionDkp/Create.vue'),
  },
  {
    path: '/contract/commission-dkp/transaction/:id',
    name: 'ContractCommissionDkpTransactionShow',
    component: () => import('../modules/contract/transactions/views/commissionDkp/Show.vue'),
  },
  {
    path: '/contract/lombard-sign/transaction',
    name: 'ContractLombardSignTransactionCreate',
    component: () => import('../modules/contract/transactions/views/lombardSign/Create.vue'),
  },
  {
    path: '/contract/lombard-sign/transaction/:id',
    name: 'ContractLombardSignTransactionShow',
    component: () => import('../modules/contract/transactions/views/lombardSign/Show.vue'),
  },
  {
    path: '/contract/lombard-cancelled-operator/transaction',
    name: 'ContractLombardCancelledOperatorCreate',
    component: () => import('../modules/contract/transactions/views/lombardCanceledOperator/Create.vue'),
  },
  {
    path: '/contract/lombard-cancelled-operator/transaction/:id',
    name: 'ContractLombardCancelledOperatorShow',
    component: () => import('../modules/contract/transactions/views/lombardCanceledOperator/Show.vue'),
  },
  {
    path: '/contract/lombard-payment/transaction',
    name: 'ContractLombardPaymentCreate',
    component: () => import('../modules/contract/transactions/views/lombardPayment/Create.vue'),
  },
  {
    path: '/contract/lombard-payment/transaction/:id',
    name: 'ContractLombardPaymentShow',
    component: () => import('../modules/contract/transactions/views/lombardPayment/Show.vue'),
  },
  {
    path: '/contract/lombard-partial-payment/transaction',
    name: 'ContractLombardPartialPaymentCreate',
    component: () => import('../modules/contract/transactions/views/lombardPartialPayment/Create.vue'),
  },
  {
    path: '/contract/lombard-partial-payment/transaction/:id',
    name: 'ContractLombardPartialPaymentShow',
    component: () => import('../modules/contract/transactions/views/lombardPartialPayment/Show.vue'),
  },
  {
    path: '/contract/lombard-buyout/transaction',
    name: 'ContractLombardBuyoutCreate',
    component: () => import('../modules/contract/transactions/views/lombardBuyout/Create.vue'),
  },
  {
    path: '/contract/lombard-buyout/transaction/:id',
    name: 'ContractLombardBuyoutShow',
    component: () => import('../modules/contract/transactions/views/lombardBuyout/Show.vue'),
  },
  {
    path: '/contract/lombard-cancelled-counteragent/transaction',
    name: 'ContractLombardCancelledCounteragentCreate',
    component: () => import('../modules/contract/transactions/views/lombardCancelledCounteragent/Create.vue'),
  },
  {
    path: '/contract/lombard-cancelled-counteragent/transaction/:id',
    name: 'ContractLombardCancelledCounteragentShow',
    component: () => import('../modules/contract/transactions/views/lombardCancelledCounteragent/Show.vue'),
  },
  {
    path: '/contract/lombard-put-on-market/transaction',
    name: 'ContractLombardPutOnMarketTransactionCreate',
    component: () => import('../modules/contract/transactions/views/lombardPutOnMarket/Create.vue'),
  },
  {
    path: '/contract/lombard-put-on-market/transaction/:id',
    name: 'ContractLombardPutOnMarketTransactionShow',
    component: () => import('../modules/contract/transactions/views/lombardPutOnMarket/Show.vue'),
  },
  // deal
  {
    path: '/deal',
    name: 'Deal',
    component: () => import('../modules/deal/views/deal/Index.vue'),
  },
  {
    path: '/deal/:id',
    name: 'DealShow',
    component: () => import('../modules/deal/views/deal/Show.vue'),
  },
  {
    path: '/deal/update/:id',
    name: 'DealUpdate',
    component: () => import('../modules/deal/views/deal/Update.vue'),
  },
  {
    path: '/deal/transaction',
    name: 'DealTransactionCreate',
    component: () => import('../modules/deal/transactions/views/deal/Create.vue'),
  },
  {
    path: '/deal/transaction/:id',
    name: 'DealTransactionShow',
    component: () => import('../modules/deal/transactions/views/deal/Show.vue'),
  },
  // kkm
  {
    path: '/kkm/kkt',
    name: 'KkmKkt',
    component: () => import('../modules/kkm/views/kkt/Index.vue'),
  },
  {
    path: '/kkm/kkt/create',
    name: 'KkmKktCreate',
    component: () => import('../modules/kkm/views/kkt/Create.vue'),
  },
  {
    path: '/kkm/kkt/update/:id',
    name: 'KkmKktUpdate',
    component: () => import('../modules/kkm/views/kkt/Update.vue'),
  },
  {
    path: '/kkm/command',
    name: 'KkmCommand',
    component: () => import('../modules/kkm/views/command/index.vue'),
  },
  {
    path: '/kkm/session',
    name: 'KkmSession',
    component: () => import('../modules/kkm/views/session/Index.vue'),
  },
  {
    path: '/kkm/session/:id',
    name: 'KkmSessionShow',
    component: () => import('../modules/kkm/views/session/Show.vue'),
  },
  {
    path: '/kkm/session/printReport/:id',
    name: 'kkmPrintReportCashier',
    component: () => import('../modules/kkm/views/session/KkmCashierReport.vue'),
    meta: {
      layout: 'print',
    },
  },
  {
    path: '/kkm/depositing/transaction',
    name: 'KkmDepositingTransactionCreate',
    component: () => import('../modules/kkm/transactions/views/depositing/Create.vue'),
  },
  {
    path: '/kkm/depositing/transaction/:id',
    name: 'KkmDepositingTransactionShow',
    component: () => import('../modules/kkm/transactions/views/depositing/Show.vue'),
  },
  {
    path: '/kkm/payment/transaction',
    name: 'KkmPaymentTransactionCreate',
    component: () => import('../modules/kkm/transactions/views/payment/Create.vue'),
  },
  {
    path: '/kkm/payment/transaction/:id',
    name: 'KkmPaymentTransactionShow',
    component: () => import('../modules/kkm/transactions/views/payment/Show.vue'),
  },
  // calculator
  {
    path: '/calculator',
    name: 'Calculator',
    component: () => import('../modules/calculator/views/index/index.vue'),
  },
  // changelog
  {
    path: '/changelog',
    name: 'Changelog',
    component: () => import('../modules/changelog/views/Index.vue'),
  },
  // counteragent
  {
    path: '/counteragent',
    name: 'CounterAgent',
    component: () => import('../modules/counteragent/views/index.vue'),
  },
  // lead
  {
    path: '/lead',
    name: 'Lead',
    component: () => import('../modules/lead/views/lead/Index.vue'),
  },
  {
    path: '/lead/update/:id',
    name: 'LeadUpdate',
    component: () => import('../modules/lead/views/lead/Update.vue'),
  },
  {
    path: '/lead/:id',
    name: 'LeadShow',
    component: () => import('../modules/lead/views/lead/Show.vue'),
  },
  {
    path: '/lead/create',
    name: 'LeadCreate',
    component: () => import('../modules/lead/views/lead/Create.vue'),
  },
  // lombard temp
  {
    path: '/lombard-temp/sale',
    name: 'LombardTempSale',
    component: () => import('../modules/lombardTemp/views/sale/Index.vue'),
  },
  {
    path: '/lombard-temp/sale/update/:id',
    name: 'LombardTempSaleUpdate',
    component: () => import('../modules/lombardTemp/views/sale/Update.vue'),
  },
  {
    path: '/lombard-temp/sale/create',
    name: 'LombardTempSaleCreate',
    component: () => import('../modules/lombardTemp/views/sale/Create.vue'),
  },
  {
    path: '/lombard-temp/uin',
    name: 'LombardTempUin',
    component: () => import('../modules/lombardTemp/views/uin/Index.vue'),
  },
  {
    path: '/lombard-temp/uin/update/:id',
    name: 'LombardTempUinUpdate',
    component: () => import('../modules/lombardTemp/views/uin/Update.vue'),
  },
  {
    path: '/lombard-temp/uin/create',
    name: 'LombardTempUinCreate',
    component: () => import('../modules/lombardTemp/views/uin/Create.vue'),
  },
  // organization
  {
    path: '/organization',
    name: 'Organization',
    component: () => import('../modules/organization/views/organization/Index.vue'),
  },
  {
    path: '/organization/update/:id',
    name: 'OrganizationUpdate',
    component: () => import('../modules/organization/views/organization/Update.vue'),
  },
  {
    path: '/organization/create',
    name: 'OrganizationCreate',
    component: () => import('../modules/organization/views/organization/Create.vue'),
  },
  {
    path: '/organization/department',
    name: 'OrganizationDepartments',
    component: () => import('../modules/organization/views/departments/Index.vue'),
  },
  {
    path: '/organization/department/update/:id',
    name: 'OrganizationDepartmentUpdate',
    component: () => import('../modules/organization/views/departments/Update.vue'),
  },
  {
    path: '/organization/department/create',
    name: 'OrganizationDepartmentCreate',
    component: () => import('../modules/organization/views/departments/Create.vue'),
  },
  {
    path: '/organization/storage',
    name: 'OrganizationStorage',
    component: () => import('../modules/organization/views/storage/Index.vue'),
  },
  {
    path: '/organization/storage/update/:id',
    name: 'OrganizationStorageUpdate',
    component: () => import('../modules/organization/views/storage/Update.vue'),
  },
  {
    path: '/organization/storage/create',
    name: 'OrganizationStorageCreate',
    component: () => import('../modules/organization/views/storage/Create.vue'),
  },
  // permission
  {
    path: '/permission',
    name: 'Permission',
    component: () => import('../modules/permission/views/permission/Index.vue'),
  },
  {
    path: '/permission/update/:id',
    name: 'PermissionUpdate',
    component: () => import('../modules/permission/views/permission/Update.vue'),
  },
  // person
  {
    path: '/person',
    name: 'Person',
    component: () => import('../modules/person/views/person/Index.vue'),
  },
  {
    path: '/person/:id',
    name: 'PersonShow',
    component: () => import('../modules/person/views/person/Show.vue'),
  },
  {
    path: '/person/update/:id',
    name: 'PersonUpdate',
    component: () => import('../modules/person/views/person/Update.vue'),
  },
  // printer
  {
    path: '/printer/print-doc',
    name: 'PrinterPrintDoc',
    component: () => import('../modules/printer/views/printer/Print.vue'),
    meta: {
      layout: 'print',
    },
  },
  {
    path: '/printer',
    name: 'PrinterIndex',
    component: () => import('../modules/printer/views/printer/Index.vue'),
  },
  {
    path: '/printer/update/:id',
    name: 'PrinterUpdate',
    component: () => import('../modules/printer/views/printer/Update.vue'),
  },
  {
    path: '/printer/show/:id',
    name: 'PrinterShow',
    component: () => import('../modules/printer/views/printer/Show.vue'),
  },
  {
    path: '/printer/create',
    name: 'PrinterCreate',
    component: () => import('../modules/printer/views/printer/Create.vue'),
  },
  // product
  {
    path: '/product',
    name: 'Product',
    component: () => import('../modules/product/views/product/Index.vue'),
  },
  {
    path: '/product/store',
    name: 'ProductStore',
    component: () => import('../modules/product/views/store/Index.vue'),
  },
  {
    path: '/product/:id',
    name: 'ProductShow',
    component: () => import('../modules/product/views/product/Show.vue'),
  },
  {
    path: '/product/update/:id',
    name: 'ProductUpdate',
    component: () => import('../modules/product/views/product/Update.vue'),
  },
  {
    path: '/product/create',
    name: 'ProductCreate',
    component: () => import('../modules/product/views/product/Create.vue'),
  },
  {
    path: '/product/move',
    name: 'ProductMove',
    component: () => import('../modules/product/views/move/Index.vue'),
  },
  {
    path: '/product/move/:id',
    name: 'ProductMoveShow',
    component: () => import('../modules/product/views/move/Show.vue'),
  },
  {
    path: '/product/move2/:id',
    name: 'ProductMoveShow2',
    component: () => import('../modules/product/views/move/Show2.vue'),
  },
  {
    path: '/product/pack',
    name: 'ProductPack',
    component: () => import('../modules/product/views/pack/Index.vue'),
  },
  {
    path: '/product/pack/:id',
    name: 'ProductPackShow',
    component: () => import('../modules/product/views/pack/Show.vue'),
  },
  {
    path: '/product/transfer',
    name: 'ProductTransfer',
    component: () => import('../modules/product/views/transfer/Index.vue'),
  },
  {
    path: '/product/transfer/:id',
    name: 'ProductTransferShow',
    component: () => import('../modules/product/views/transfer/Show.vue'),
  },
  {
    path: '/product/transfer-unusing/transaction',
    name: 'ProductTransferUnusingTransactionCreate',
    component: () => import('../modules/product/transactions/views/transferUnusing/Create.vue'),
  },
  {
    path: '/product/transfer-using/transaction',
    name: 'ProductTransferUsingTransactionCreate',
    component: () => import('../modules/product/transactions/views/transferUsing/Create.vue'),
  },
  {
    path: '/product/transfer-unusing/:id',
    name: 'ProductTransferUnusingTransactionShow',
    component: () => import('../modules/product/transactions/views/transferUnusing/Show.vue'),
  },
  {
    path: '/product/transfer-using/:id',
    name: 'ProductTransferUsingTransactionShow',
    component: () => import('../modules/product/transactions/views/transferUsing/Show.vue'),
  },
  {
    path: '/product/transfer-sell/transaction',
    name: 'ProductTransferSellTransactionCreate',
    component: () => import('../modules/product/transactions/views/transferSell/Create.vue'),
  },
  {
    path: '/product/transfer-buy/transaction',
    name: 'ProductTransferBuyTransactionCreate',
    component: () => import('../modules/product/transactions/views/transferBuy/Create.vue'),
  },
  {
    path: '/product/lombard-sell/transaction',
    name: 'ProductLombardSellTransactionCreate',
    component: () => import('../modules/product/transactions/views/lombardSell/Create.vue'),
  },
  {
    path: '/product/lombard-sell/transaction/:id',
    name: 'ProductLombardSellTransactionShow',
    component: () => import('../modules/product/transactions/views/lombardSell/Show.vue'),
  },
  {
    path: '/product/move/transaction',
    name: 'ProductMoveTransactionCreate',
    component: () => import('../modules/product/transactions/views/move/Create.vue'),
  },
  {
    path: '/product/move/transaction/:id',
    name: 'ProductMoveTransactionShow',
    component: () => import('../modules/product/transactions/views/move/Show.vue'),
  },
  {
    path: '/product/pack-sell/transaction',
    name: 'ProductPackSellTransactionCreate',
    component: () => import('../modules/product/transactions/views/packSell/Create.vue'),
  },
  {
    path: '/product/pack-sell/transaction/:id',
    name: 'ProductPackSellTransactionShow',
    component: () => import('../modules/product/transactions/views/packSell/Show.vue'),
  },
  {
    path: '/product/transfer-sell/transaction/:id',
    name: 'ProductTransferSellTransactionShow',
    component: () => import('../modules/product/transactions/views/transferSell/Show.vue'),
  },
  {
    path: '/product/transfer-buy/transaction/:id',
    name: 'ProductTransferBuyTransactionShow',
    component: () => import('../modules/product/transactions/views/transferBuy/Show.vue'),
  },
  {
    path: '/product/pack-sell-remelt/transaction',
    name: 'ProductPackSellRemeltTransactionCreate',
    component: () => import('../modules/product/transactions/views/packSellRemelt/Create.vue'),
  },
  {
    path: '/product/pack-sell-remelt/transaction/:id',
    name: 'ProductPackSellRemeltTransactionShow',
    component: () => import('../modules/product/transactions/views/packSellRemelt/Show.vue'),
  },
  {
    path: '/product/sell/transaction',
    name: 'ProductSellTransactionCreate',
    component: () => import('../modules/product/transactions/views/sell/Create.vue'),
  },
  {
    path: '/product/sell/transaction/:id',
    name: 'ProductSellTransactionShow',
    component: () => import('../modules/product/transactions/views/sell/Show.vue'),
  },
  // Property
  {
    path: '/property',
    name: 'ProductPropertyIndex',
    component: () => import('../modules/property/views/Index.vue'),
  },
  // receipt
  {
    path: '/operation/cash-flow',
    name: 'OperationCashFlowIndex',
    component: () => import('../modules/operation/views/cashFlow/Index.vue'),
  },
  {
    path: '/operation/cash-flow/:id',
    name: 'OperationCashFlowShow',
    component: () => import('../modules/operation/views/cashFlow/Show.vue'),
  },
  {
    path: '/operation/cash-flow/create',
    name: 'OperationCashFlowCreate',
    component: () => import('../modules/operation/views/cashFlow/Create.vue'),
  },
  {
    path: '/operation/cash-flow/update/:id',
    name: 'OperationCashFlowUpdate',
    component: () => import('/src/modules/operation/views/cashFlow/Update.vue'),
  },
  // report
  {
    path: '/report/active',
    name: 'ReportActive',
    component: () => import('../modules/report/views/actives/Index.vue'),
  },
  {
    path: '/report/active/detail-:type',
    name: 'ReportActiveShow',
    component: () => import('../modules/report/views/actives/Show.vue'),
  },
  {
    path: '/report/active/new',
    name: 'ReportActiveNew',
    component: () => import('../modules/report/views/actives/Index2.vue'),
  },
  {
    path: '/report/active/detail',
    name: 'ReportActiveShowNew',
    component: () => import('../modules/report/views/actives/Show2.vue'),
  },
  {
    path: '/report/active/:type/:departmentId',
    name: 'ReportActiveShow2',
    component: () => import('../modules/report/views/actives/Show2.vue'),
  },
  {
    path: '/report/clients',
    name: 'ReportClients',
    component: () => import('../modules/report/views/clients/Index.vue'),
  },
  {
    path: '/report/clients/detail-:type',
    name: 'ReportClientsShow',
    component: () => import('../modules/report/views/clients/Show.vue'),
  },
  {
    path: '/report/contract',
    name: 'ReportContract',
    component: () => import('../modules/report/views/contract/Index.vue'),
  },
  {
    path: '/report/finance',
    name: 'ReportFinance',
    component: () => import('../modules/report/views/finance/Index.vue'),
  },
  {
    path: '/report/finance/new',
    name: 'ReportFinanceNew',
    component: () => import('../modules/report/views/finance/Index2.vue'),
  },
  {
    path: '/report/finance/:type/:departmentId',
    name: 'ReportFinanceDetail',
    component: () => import('../modules/report/views/finance/Show2.vue'),
  },
  {
    path: '/report/finance/detail-:type',
    name: 'ReportFinanceShow',
    component: () => import('../modules/report/views/finance/Show.vue'),
  },
  {
    path: '/report/loan',
    name: 'ReportLoan',
    component: () => import('../modules/report/views/loan/Index.vue'),
  },
  {
    path: '/report/lombard-temp-sale',
    name: 'ReportLombardTempSale',
    component: () => import('../modules/report/views/lombardTemp/sale/Index.vue'),
  },
  {
    path: '/report/lombard-temp-sale/detail',
    name: 'ReportLombardTempSaleDetail',
    component: () => import('../modules/report/views/lombardTemp/sale/Detail.vue'),
  },
  {
    path: '/report/lombard-temp-uin',
    name: 'ReportLombardTempUin',
    component: () => import('../modules/report/views/lombardTemp/uin/Index.vue'),
  },
  {
    path: '/report/lombard-temp-uin/detail',
    name: 'ReportLombardTempUinDetail',
    component: () => import('../modules/report/views/lombardTemp/uin/Detail.vue'),
  },
  {
    path: '/report/operational',
    name: 'ReportOperational',
    component: () => import('../modules/report/views/operational/Index.vue'),
  },
  {
    path: '/report/operational/detail-:type',
    name: 'ReportOperationalShow',
    component: () => import('../modules/report/views/operational/Show.vue'),
  },
  {
    path: '/report/percent',
    name: 'ReportPercent',
    component: () => import('../modules/report/views/percent/Index.vue'),
  },
  {
    path: '/report/percent-detail',
    name: 'ReportPercentDetail',
    component: () => import('../modules/report/views/percent/Detail.vue'),
  },
  {
    path: '/report/unclaimed-products',
    name: 'UnclaimedProducts',
    component: () => import('../modules/report/views/unclaimedProduct/Index.vue'),
  },
  {
    path: '/report/users',
    name: 'ReportUsers',
    component: () => import('../modules/report/views/users/Index.vue'),
  },
  {
    path: '/report/users/detail-:type',
    name: 'ReportUsersShow',
    component: () => import('../modules/report/views/users/Show.vue'),
  },
  {
    path: '/report/franchise',
    name: 'ReportFranchise',
    component: () => import('../modules/report/views/franchise/Index.vue'),
  },
  {
    path: '/report/cbr',
    name: 'ReportCbr',
    component: () => import('../modules/reportCbr/views/Index.vue'),
  },
  {
    path: '/report/ros-fin-monitor',
    name: 'ReportRosFinMonitor',
    component: () => import('../modules/report/views/rosFinMonitor/Index.vue'),
  },
  {
    path: '/report/source',
    name: 'ReportSource',
    component: () => import('../modules/report/views/source/Index.vue'),
  },
  {
    path: '/report/source-chart',
    name: 'ReportSourceChart',
    component: () => import('../modules/report/views/source/IndexChart.vue'),
  },
  // role
  {
    path: '/role',
    name: 'Role',
    component: () => import('../modules/role/views/role/Index.vue'),
  },
  {
    path: '/role/create',
    name: 'RoleCreate',
    component: () => import('../modules/role/views/role/Create.vue'),
  },
  {
    path: '/role/update/:id',
    name: 'RoleUpdate',
    component: () => import('../modules/role/views/role/Update.vue'),
  },
  {
    path: '/role/permission',
    name: 'RolePermission',
    component: () => import('../modules/role/views/role/Permission.vue'),
  },
  // shift
  {
    path: '/shift',
    name: 'Shift',
    component: () => import('../modules/shift/views/index.vue'),
  },
  // source
  {
    path: '/source',
    name: 'Source',
    component: () => import('../modules/source/views/source/Index.vue'),
  },
  {
    path: '/source/create',
    name: 'SourceCreate',
    component: () => import('../modules/source/views/source/Create.vue'),
  },
  {
    path: '/source/update/:id',
    name: 'SourceUpdate',
    component: () => import('../modules/source/views/source/Update.vue'),
  },
  // transaction
  {
    path: '/transaction',
    name: 'Transaction',
    component: () => import('../modules/transaction/views/transaction/Index.vue'),
  },
  {
    path: '/transaction/:id',
    name: 'TransactionShow',
    component: () => import('../modules/transaction/views/transaction/Show.vue'),
  },
  {
    path: '/transaction/update/:id',
    name: 'TransactionUpdate',
    component: () => import('../modules/transaction/views/transaction/Update.vue'),
  },
  // service
  {
    path: '/service',
    name: 'Service',
    component: () => import('../modules/service/views/Index.vue'),
  },
  {
    path: '/service/update/:id',
    name: 'ServiceUpdate',
    component: () => import('../modules/service/views/Update.vue'),
  },
  {
    path: '/service/create',
    name: 'ServiceCreate',
    component: () => import('../modules/service/views/Create.vue'),
  },
  {
    path: '/service/:id',
    name: 'ServiceShow',
    component: () => import('../modules/service/views/Show.vue'),
  },
  // sms
  {
    path: '/sms',
    name: 'Sms',
    component: () => import('../modules/sms/views/sms/Index.vue'),
  },
  {
    path: '/sms/create',
    name: 'SmsCreate',
    component: () => import('../modules/sms/views/sms/Create.vue'),
  },
  // user
  {
    path: '/user',
    name: 'UserIndex',
    component: () => import('../modules/user/views/user/Index.vue'),
  },
  {
    path: '/user/show/:id',
    name: 'UserShow',
    component: () => import('../modules/user/views/user/Show.vue'),
  },
  {
    path: '/user/update/:id',
    name: 'UserUpdate',
    component: () => import('../modules/user/views/user/Update.vue'),
  },
  {
    path: '/user/create',
    name: 'UserCreate',
    component: () => import('../modules/user/views/user/Create.vue'),
  },
  {
    path: '/user/personal',
    name: 'Personal',
    component: () => import('../modules/user/views/personal/Index.vue'),
  },
  // ProductInstanceCategory
  {
    path: '/product/instance-category',
    name: 'ProductInstanceCategoryIndex',
    component: () => import('../modules/product/views/instanceCategory/Index.vue'),
  },
  {
    path: '/product/instance-category/update/:id',
    name: 'ProductInstanceCategoryUpdate',
    component: () => import('../modules/product/views/instanceCategory/Update.vue'),
  },
  {
    path: '/product/instance-category/create',
    name: 'ProductInstanceCategoryCreate',
    component: () => import('../modules/product/views/instanceCategory/Create.vue'),
  },
  // ProductInstanceParam
  {
    path: '/product/instance-param',
    name: 'ProductInstanceParam',
    component: () => import('../modules/product/views/instanceParam/Index.vue'),
  },
  {
    path: '/product/instance-param/update/:id',
    name: 'ProductInstanceParamUpdate',
    component: () => import('../modules/product/views/instanceParam/Update.vue'),
  },
  {
    path: '/product/instance-param/create',
    name: 'ProductInstanceParamCreate',
    component: () => import('../modules/product/views/instanceParam/Create.vue'),
  },
  // Export Xml
  {
    path: '/export',
    name: 'Export',
    component: () => import('../modules/export/views/Index.vue'),
  },
  // Import
  {
    path: '/import/smart',
    name: 'ImportFromSmartLombardIndex',
    component: () => import('../modules/import/views/smart/Index.vue'),
  },
  // Inventory
  {
    path: '/inventory',
    name: 'Inventory',
    component: () => import('../modules/inventory/views/Index.vue'),
  },
  // Support
  {
    path: '/support/ticket',
    name: 'SupportTicketIndex',
    component: () => import('../modules/support/views/ticket/Index.vue'),
  },
  {
    path: '/support/ticket/:id',
    name: 'SupportTicketShow',
    component: () => import('../modules/support/views/ticket/Show.vue'),
  },
  {
    path: '/support/ticket/create',
    name: 'SupportTicketCreate',
    component: () => import('../modules/support/views/ticket/Create.vue'),
  },
  {
    path: '/support/ticket/update/:id',
    name: 'SupportTicketUpdate',
    component: () => import('../modules/support/views/ticket/Update.vue'),
  },
  {
    path: '/support/category',
    name: 'SupportCategoryIndex',
    component: () => import('../modules/support/views/category/Index.vue'),
  },
  {
    path: '/support/category/:id',
    name: 'SupportCategoryShow',
    component: () => import('../modules/support/views/category/Show.vue'),
  },
  {
    path: '/support/category/create',
    name: 'SupportCategoryCreate',
    component: () => import('../modules/support/views/category/Create.vue'),
  },
  {
    path: '/support/category/update/:id',
    name: 'SupportCategoryUpdate',
    component: () => import('../modules/support/views/category/Update.vue'),
  },
  {
    path: '/wiki',
    name: 'Wiki',
    component: () => import('../modules/wiki/views/wiki/Index.vue'),
  },
  {
    path: '/wiki/:id',
    name: 'WikiArticle',
    component: () => import('../modules/wiki/views/wiki/Show.vue'),
  },
  {
    path: '/wiki/category',
    name: 'WikiCategoryIndex',
    component: () => import('../modules/wiki/views/category/Index.vue'),
  },
  {
    path: '/wiki/category/create',
    name: 'WikiCategoryCreate',
    component: () => import('../modules/wiki/views/category/Create.vue'),
  },
  {
    path: '/wiki/category/update/:id',
    name: 'WikiCategoryUpdate',
    component: () => import('../modules/wiki/views/category/Update.vue'),
  },
  {
    path: '/wiki/category/:id',
    name: 'WikiCategoryShow',
    component: () => import('../modules/wiki/views/category/Show.vue'),
  },
  {
    path: '/wiki/article',
    name: 'WikiArticleIndex',
    component: () => import('../modules/wiki/views/article/Index.vue'),
  },
  {
    path: '/wiki/article/create',
    name: 'WikiArticleCreate',
    component: () => import('../modules/wiki/views/article/Create.vue'),
  },
  {
    path: '/wiki/article/update/:id',
    name: 'WikiArticleUpdate',
    component: () => import('../modules/wiki/views/article/Update.vue'),
  },
  {
    path: '/wiki/article/:id',
    name: 'WikiArticleShow',
    component: () => import('../modules/wiki/views/article/Show.vue'),
  },
  {
    path: '/wiki/tag',
    name: 'WikiTagIndex',
    component: () => import('../modules/wiki/views/tag/Index.vue'),
  },
  {
    path: '/wiki/tag/create',
    name: 'WikiTagCreate',
    component: () => import('../modules/wiki/views/tag/Create.vue'),
  },
  {
    path: '/wiki/tag/update/:id',
    name: 'WikiTagUpdate',
    component: () => import('../modules/wiki/views/tag/Update.vue'),
  },
  {
    path: '/wiki/tag/:id',
    name: 'WikiTagShow',
    component: () => import('../modules/wiki/views/tag/Show.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  to.meta.auth = to.meta.auth === undefined ? true : to.meta.auth;
  to.meta.layout = to.meta.layout === undefined ? 'main' : to.meta.layout;

  const requireAuth = to.meta.auth;

  if (requireAuth && store.getters['auth/isAuthenticated']) {
    next();
  } else if (to.fullPath === '/') {
    next('/auth');
  } else if (requireAuth && !store.getters['auth/isAuthenticated'] && to.fullPath !== '/') {
    next('/auth?message=auth');
  } else if (to.fullPath === '/auth' && store.getters['auth/isAuthenticated']) {
    next('/');
  } else {
    next();
  }
});

export default router;