import Service from '../../../services/Service';
import http from '../../../utils/http-common';

class SearchService extends Service {

    basePath = 'search';

    newObject() {
        return new SearchService();
    }

    async all() {
        return await http.get(this.getRoutes().index, { params: this.prepareRequest() }).then(/*async*/ response => {
            this.responseData = response.data;
            this.errors = {};

            if (response.data.meta) {
                this.metaTotal = response.data.meta.total;
                this.metaPerPage = response.data.meta.per_page;
            }

            return response.data.data;
        }).catch(e => this.catchResponse(e));
    }

}

export default SearchService;