<template>
  <div class="p-field">
    <label v-if="label" :for="fieldStr">{{ label }}</label>
    <small v-if="tooltip" v-tooltip.right="tooltip" class="ms-1"><i class="pi pi-info-circle"></i></small>
    <div class="p-fileupload p-fileupload-advanced p-component">
      <div class="p-fileupload-buttonbar" style="padding-left: 0">
        <FileUpload
          ref="fileUpload"
          :disabled="disabled"
          :id="fieldStr"
          mode="basic"
          v-model="serviceF"
          name="file"
          :url="url || $env.getItem('VUE_APP_API_URL') + '/' + service.basePath + '/upload'"
          @upload="$emit('upload', $event)"
          @uploader="onUploader"
          :auto="true"
          :chooseLabel="$t('Download') + ' ' + $t('file')"
          :multiple="multiple"
          :class="service.getError(fieldStr) ? 'p-invalid is-invalid' : ''"
          :accept="accept ?? 'image/*'"
          :maxFileSize="maxFileSize ?? 20000000"
          :showUploadButton="false"
          :showCancelButton="false"
          :customUpload="true"
        ></FileUpload>
      </div>

      <FileGalleria v-if="multiple" :service="service" :field="field" :edit="true" />
    </div>
    <div>
      <p class="helpText">{{ helpText }}</p>
    </div>
    <div v-if="service.getError(fieldStr)" class="invalid-feedback">
      {{ service.getError(fieldStr) }}
    </div>
  </div>
</template>

<script>
import FileUpload from 'primevue/fileupload';
import FileGalleria from '../FileGalleria.vue';
import http from '../../../utils/http-common';

export default {
  components: { FileUpload, FileGalleria },
  props: {
    service: Object,
    field: String,
    label: String,
    multiple: Boolean,
    url: String,
    helpText: String,
    tooltip: String,
    accept: String,
    disabled: Boolean,
    maxFileSize: Number,
  },
  emits: ['upload'],
  data() {
    return {
      id: this.$str.uniqid(),
    };
  },
  computed: {
    fieldStr() {
      if (typeof this.field === 'object') {
        return this.field.join('.');
      }

      return this.field;
    },
    serviceF: {
      get() {
        if (typeof this.field === 'object') {
          let serviceF = this.service;

          for (let f of this.field) {
            if (serviceF[f] === undefined) {
              return null;
            }
            serviceF = serviceF[f];
          }

          return serviceF;
        }

        return this.service[this.field];
      },
      set(v) {
        if (typeof this.field === 'object') {
          // Перебором присвоение не сработало, поэтому сравниваем по кол-ву элементов
          if (this.service[this.field[0]] === undefined) {
            this.service[this.field[0]] = {};
          }
          if (this.field.length === 2) {
            this.service[this.field[0]][this.field[1]] = v;
          } else if (this.field.length === 3) {
            if (this.service[this.field[0]][this.field[1]] === undefined) {
              this.service[this.field[0]][this.field[1]] = {};
            }
            this.service[this.field[0]][this.field[1]][this.field[2]] = v;
          }
        } else {
          this.service[this.field] = v;
        }
      },
    },
  },
  methods: {
    async onUploader(data) {
      let files = data.files;
      let success = true;

      this.service.errors = {};
      this.service.tempId = this.id;
      if (data.files[0]) {
        this.service.fileName = data.files[0].name;
      }
      for (let file of files) {
        let formData = new FormData();
        formData.set('file', file, file.name);
        formData.append('attribute', this.fieldStr);
        if (this.service.id) {
          formData.append('objectId', this.service.id);
        } else {
          formData.append('tempObjectId', this.service.tempId);
          if (this.service.type) {
            formData.append('type', this.service.type);
          }
        }

        await http
          .post('/' + this.service.basePath + '/upload', formData)
          .then((response) => {
            if (this.multiple) {
              this.serviceF = this.serviceF || [];
              this.serviceF.push(response.data.data);
            } else {
              this.serviceF = response.data.data;
            }
            this.service.fileData = response.data.data;

            this.$emit('upload', { response: response, file: file });
          })
          .catch((e) => {
            success = false;

            this.service.catchResponse(e);

            if (this.service.getError()) {
              this.$toast.error({ detail: file.name + ': ' + this.service.getError() });
            }
          });
      }

      if (success) {
        this.$toast.info({
          summary: 'Успешно',
          detail: 'Файл загружен',
        });
      }

      this.$refs.fileUpload.clear();
    },
  },
};
</script>
