<template>
  <li v-if="show" class="dropdown notification-list">
    <a
      class="nav-link color-grey dropdown-toggle arrow-none d-flex justify-content-center align-items-center"
      data-bs-toggle="dropdown"
      href="#"
      role="button"
      aria-haspopup="false"
      aria-expanded="false"
      v-on:click="hideAllSms"
    >
      <i class="dripicons-bell noti-icon"></i>
      <span v-show="this.unreadSmsCounter > 0" class="noti-icon-badge">{{ this.unreadSmsCounter }}</span>
    </a>
    <div class="dropdown-menu dropdown-menu-end dropdown-menu-animated dropdown-lg" style="">
      <!-- item-->
      <div class="dropdown-item noti-title">
        <h5 class="m-0">
          {{ this.$t('Sms list') }}
        </h5>
      </div>

      <div style="max-height: 230px; min-height: 230px" data-simplebar="init">
        <div class="simplebar-wrapper" style="margin: 0px">
          <div class="simplebar-height-auto-observer-wrapper">
            <div class="simplebar-height-auto-observer"></div>
          </div>
          <div class="simplebar-mask">
            <div class="simplebar-offset" style="right: 0px; bottom: 0px">
              <div class="simplebar-content-wrapper" style="height: auto; overflow: hidden">
                <div class="simplebar-content" style="padding: 0px">
                  <!-- item-->
                  <a v-for="sms in lastSms" :key="sms.id" href="javascript:void(0);" class="dropdown-item notify-item">
                    <p v-if="sms.type === 'wikiArticle'" class="ms-0 notify-details">
                      <router-link :to="{ name: 'WikiArticle', params: { id: JSON.parse(sms.data).id } }">
                        {{ sms.message }}
                      </router-link>
                      <small class="text-muted">
                        {{ $str.date(sms.dtCreate) }}
                      </small>
                    </p>
                    <p v-else class="ms-0 notify-details">
                      {{ sms.message }}
                      <small class="text-muted">
                        {{ $str.date(sms.dtCreate) }}
                      </small>
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="simplebar-placeholder" style="width: 0px; height: 0px"></div>
        </div>
        <div class="simplebar-track simplebar-horizontal" style="visibility: hidden">
          <div class="simplebar-scrollbar" style="width: 0px; display: none"></div>
        </div>
        <div class="simplebar-track simplebar-vertical" style="visibility: hidden">
          <div
            class="simplebar-scrollbar"
            style="height: 0px; display: none; transform: translate3d(0px, 0px, 0px)"
          ></div>
        </div>
      </div>

      <!-- All-->
      <router-link class="dropdown-item text-center text-primary notify-item notify-all" :to="{ name: 'Sms' }">
        {{ this.$t('See all') }}
      </router-link>
    </div>
  </li>
</template>

<script>
import SmsService from '../../modules/sms/services/SmsService';

export default {
  props: {
    show: Boolean,
  },
  name: 'SmsList',
  data() {
    return {
      userId: localStorage.getItem('userId'),
      unreadSmsCounter: 0,
      lastSms: [],
      service: new SmsService(),
    };
  },
  methods: {
    hideAllSms() {
      // Hide all Sms
      this.$toast.removeGroup('bl');
      // Send a request to api and read all users sms
      this.service.readAllSms(this.userId);
      // Set unread Sms counter to 0
      this.unreadSmsCounter = 0;
    },
  },
  async created() {
    await this.service.getUnreadSmsByUserId(this.userId).then((data) => (this.unreadSmsCounter = data.data.length));
    await this.service.getLastThreeUnreadSmsByUserId(this.userId).then((data) => (this.lastSms = data.data));
  },
};
</script>

<style scoped>
.dropdown {
  margin-left: 10px;
}

.noti-icon-badge {
  width: 20px;
  height: 20px;
  right: -5px;
  top: 18px;
  color: white;
}
</style>